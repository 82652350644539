class Config {

    ACCOUNT_ID: string = "dptex4mb3r";

    SESSION_TOKEN: string = "sessionToken";
    
    LOGIN_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/auth/token";
    FORGOTPASSWORD_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/auth/forgotpassword";
    VALIDATE_AUTHTOKEN_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/auth/validateauthtoken";
	RESETPASSWORD_URL: string = process.env.REACT_APP_BASE_URL_SYSTEM + "/auth/resetpassword";
    
    MEMBER_REGISTRATION_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/16/member/registration";

    FEEDBACK_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/feedback";
    ACCESS_TOKEN: string = "accessToken";
    EXPIRATION: string = "expiration";

    MEMBER_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/1/member";
    MEMBERIDENTITY_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/identity";
    NEXTOFKIN_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/nextofkin";
    BENEFICIARY_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/beneficiaries";
    GUARDIAN_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/beneficiary/guardian";
    PERSONALPHONE_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/contacts/personalphones";
    EMAIL_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/contacts/email";
    ADDRESS_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/contacts/address";
    REGISTRATIONSTATUS_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/registrationstatus";
    MEMBERSTATUS_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/status";
    MEMBER_BRANCH_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/2/member/branch";
    MONTHLY_DEDUCTION_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/member/deduction";

    PUBLIC_SESSION_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/session";
    PUBLIC_EMPLOYERS_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/employers/" + this.ACCOUNT_ID;
    PUBLIC_BRANCHES_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/branches";

    LOAN_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/loan";
    LOANS_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/2/loan";
    LOAN_CATEGORIES_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/categories";
    MEMBER_LOAN_GUARANTORS_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/guarantors";

    LOAN_GUARANTORS_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/6/guarantorship";
    LOAN_AMORTIZATION_URL: string = process.env.REACT_APP_BASE_URL_LOANS + "/11/amortization/calculator";

    BANK_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/22/bank";
    PUBLIC_BANK_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/22/bank";
    BANKBRANCHACCOUNT_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/5/bank/branch/account"
    BANKBRANCHACCOUNTS_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/3/bank/branches/account";

    MOBILE_MONEY_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/mobilemoney";
    PUBLIC_MONEY_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/mobilemoney/";

    CONTENT_PUBLIC_HEADER_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/content/header/" + this.ACCOUNT_ID;
    CONTENT_PUBLIC_BODY_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/content/body";
    CONTENT_PUBLIC_ACTION_URL: string = process.env.REACT_APP_BASE_URL_PUBLIC + "/content/action";

    CONTENT_PRIVATE_HEADER_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/content/header/" + this.ACCOUNT_ID;
    CONTENT_PRIVATE_BODY_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/content/body";
    CONTENT_PRIVATE_ACTION_URL: string = process.env.REACT_APP_BASE_URL_MEMBERS + "/content/action";
    
    defaultHeaders(): any {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            app: "MemberWeb",
            accountId: this.ACCOUNT_ID,
        };
    }
  
    headersWithAuthorization(): any {
        return {
            ...this.defaultHeaders(),
            Authorization: localStorage.getItem(this.ACCESS_TOKEN),
        };
    }

    baseHeaderOnToken(token: any): any {
        if (token) {
            return this.headersWithAuthorization();
        } else {
            return this.defaultHeaders();
        }
    }

    multipartAuthorization(): any {
        return {
            Authorization: localStorage.getItem(this.ACCESS_TOKEN),
        };
    }
  
    tokenExpired() {
        const expDate = Number(localStorage.getItem(this.EXPIRATION));
        if (expDate > Date.now()) {
            return false;
        }
        return true;
    }
  
    storeAccessToken(token: any) {
        localStorage.setItem(this.ACCESS_TOKEN, `Bearer ${token}`);
        localStorage.setItem(this.EXPIRATION, this.getExpiration(token).toString());
    }
  
    getExpiration(token: any): number {
        let encodedPayload = token ? token.split(".")[1] : null;
        if (encodedPayload) {
            encodedPayload = encodedPayload.replace(/-/g, "+").replace(/_/g, "/");
            const payload = JSON.parse(window.atob(encodedPayload));
            return payload?.exp ? payload?.exp * 1000 : 0;
        }
        return 0;
    }

    storeSessionToken(token: any) {
        localStorage.setItem(this.SESSION_TOKEN, `Bearer ${token}`);
    }

  }
  
  export default Config;
  