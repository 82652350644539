import React, { FC } from 'react';
import AuthProps from '../common/AuthProps';
import reportImage from "../../assets/reportimage.jpeg";
import Select from '../common/Select';

const ReportPage: FC<AuthProps> = (authProps) => {

    const reports = [
        {'id':'http://localhost:8725/public/members/reports/rl005','name':'Loan Application Form'},
        {'id':'http://localhost:8725/public/members/reports/rm020','name':'Membership Withdrawal Form'},
        {'id':'http://localhost:8725/public/members/reports/rm050','name':'Statement of Account'},
        {'id':'http://localhost:8725/public/members/reports/rm055','name':'Mini-statement of Account | Balances'},
        {'id':'http://localhost:8725/public/members/reports/rm010','name':'Personal Details | Next-of-Kin | Nominees/Beneficiaries'},
        {'id':'http://localhost:8725/public/members/reports/rl010','name':'Potential Loans Available to Apply'},
        {'id':'http://localhost:8725/public/members/reports/ra005','name':'AGM / EGM Meeting Notice'},
        {'id':'http://localhost:8725/public/members/reports/rl015','name':'Loan Products Offered | Terms & Conditions'},
        {'id':'http://localhost:8725/public/members/reports/ra025','name':'Sasco Sacco Bank Account Details'},
        {'id':'http://localhost:8725/public/members/reports/rf010','name':'Abridged Financial Statements'},
        {'id':'http://localhost:8725/public/members/reports/rp010','name':'Sasco Sacco Projects'},
        {'id':'http://localhost:8725/public/members/reports/ra030','name':'Management Committee | Officers | Contacts'},
    ];

    return (
        <div className="flex items-center min-h-screen bg-gray-50">
            <div className="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl">

                <div className="flex flex-col md:flex-row">
                    <div className="h-32 md:h-auto md:w-1/2">
                        <img
                            className="object-cover w-full h-full"
                            src={reportImage}
                            alt="reports"
                        />
                    </div>

                    <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                        <div className="w-full">
                            <h3 className="mb-4 text-xl font-bold text-blue-600">
                                Reports
                            </h3>
                            <div>
                                <Select
                                    id="sectionId"
                                    placeHolder="Select Report"
                                    className="shadow-sm border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    selectList={
                                        reports.map(report => {
                                            return {id: report.id, name: report.name}
                                        })}
                                    required={true}
                                    onChange={e => {
                                        window.open(e.target.value, 
                                            '_blank', 
                                            'noreferrer');
                                    }} />
                            </div>

                                
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    );
};

export default ReportPage;