import React, { FC } from 'react'
import AuthProps from '../common/AuthProps';
import AddressPage from './AddressPage';
import EmailPage from './EmailPage';
import PersonalPhonePage from './PersonalPhonePage';

const ContactPage: FC<AuthProps> = (authProps) => {

    return (
        <div className="z-50 block w-full space-y-1">
            <div>
                <PersonalPhonePage auth={authProps.auth} config={authProps.config} />
            </div>
            <div>
                <EmailPage auth={authProps.auth} config={authProps.config} />
            </div>
            <div>
                <AddressPage auth={authProps.auth} config={authProps.config} />
            </div>
        </div>
    );
}

export default ContactPage;