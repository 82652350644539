import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthProps from '../../common/AuthProps';
import applicationImage from "../../../assets/loan_application.jpeg";
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { enabledWizardClass, grayedOutWizardClass } from '../../../shared/Utils';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { clearGuarantorList, selectGuarantors } from '../../../store/loanGuarantorSlice';
import { fetchMemberById, selectMember } from '../../../store/memberSlice';
import { LoanParams, application } from '../../../store/loanApplicationSlice';
import { Loan } from '../../../store/loanSlice';
import useToast from '../../../hooks/useToast';
import validator from 'validator';
import { clearCalculator, selectSchedule } from '../../../store/amortizationCalculatorSlice';

const ApplicationSteps: FC<AuthProps> = (authProps) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const toast = useToast(5000);

    const member = useAppSelector(selectMember);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchMemberById(authProps));
        dispatch(clearGuarantorList(null))
        dispatch(clearCalculator(null));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const [openTab, setOpenTab] = useState(1);

    // Step 1: amount
    const [amount, setAmount] = useState<string>("");

    const [amountError, setAmountError] = useState<string>("");

    // Step 2: rules
    const [boundByRules, setBoundByRules] = useState<boolean>(false);

    const [boundByRulesError, setBoundByRulesError] = useState<string>("");

    // Step 3: category / type
    const [type, setType] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [explanation, setExplanation] = useState<string>("");
    const [repaymentPeriod, setRepaymentPeriod] = useState<number>(0);

    const [typeError, setTypeError] = useState<string>("");
    const [categoryError, setCategoryError] = useState<string>("");
    const [explanationError, setExplanationError] = useState<string>("");
    const [repaymentPeriodError, setRepaymentPeriodError] = useState<string>("");

    // Step 4: amortization schedule
    const [calculatorId, setCalculatorId] = useState<string>("");
    const [firstPaymentDate, setFirstPaymentDate]  = useState<string>("");
    const amortizationSchedule = useAppSelector(selectSchedule);

    const [firstPaymentDateError, setFirstPaymentDateError] = useState<string>("");
    const [amortizationScheduleError, setAmortizationScheduleError] = useState<string>("");

    // Step 5: guarantors
    const guarantors = useAppSelector(selectGuarantors);

    const [guarantorsError, setGuarantorsError] = useState<string>("");

    // Step 6: confirmation
    const [certifyApplication, setCertifyApplication] = useState<boolean>(false);
    const [deductionFromSalary, setDeductionFromSalary] = useState<boolean>(false);

    const cancel = () => {
        const l = navigate.length;
        l > 2 ? navigate(-1) : navigate("/");
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (guarantors.length === 0) {
            toast('error', 'Cannot submit application without gurantors');
            return;
        }

        const loanApplication: Loan = {
            id: "",
            memberId: authProps.auth.token.userId,
            category: category,
            type: type,
            explanation: explanation,
            amount: Number(amount),
            repaymentPeriod: repaymentPeriod,
            calculatorId: calculatorId,
            guarantors: guarantors,
        }

        const params: LoanParams = {
            authProps: authProps,
            loan: loanApplication,
        }
        
        const result = await dispatch(application(params));
        const errorCondition = !!JSON.stringify(result).includes("message");
        
        if (!errorCondition) {
            toast('success', 'Application submitted successfully');
            // lr002
            navigate("/");
        }

    }

    const validateStep = (step: number) => {
        switch (step) {
            case 1:
                if (amount.length === 0) {
                    setAmountError("loan amount can't be empty");
                    return;
                }
                if (Number(amount) < 1000 || Number(amount) > 2000000) {
                    setAmountError("amount must be >= 1,000 and <= 2,000,000");
                    return;
                }
                break;
            case 2:
                if (!boundByRules) {
                    setBoundByRulesError("you must accept the rules to proceed");
                    return;
                }
                break;
            case 3:
                if (type.length === 0) {
                    setTypeError("type can't be empty");
                    return;
                }
                if (category.length === 0) {
                    setCategoryError("category can't be empty");
                    return;
                }
                if (category === 'Other' && explanation.length === 0) {
                    setExplanationError("explanation can't be empty");
                    return;
                }
                if (category === 'Other' 
                    && (explanation.length < 3 
                        || explanation.length > 100)) {
                    setExplanationError("explanation must be at least 3 characters and less than 100");
                    return;
                }
                if (repaymentPeriod === 0) {
                    setRepaymentPeriodError("repayment period (months) must be >= 1");
                    return;
                }
                break;
            case 4:
                if (amount.length === 0) {
                    setAmountError("loan amount can't be empty");
                    return;
                }
                if (Number(amount) < 1000 || Number(amount) > 2000000) {
                    setAmountError("amount must be >= 1,000 and <= 2,000,000");
                    return;
                }
                if (!validator.isDate(firstPaymentDate)) {
                    setFirstPaymentDateError("invalid first payment date");
                    return;
                }
                if (!amortizationSchedule) {
                    setAmortizationScheduleError("can't proceed without amortization schedule");
                    return;
                }
                break;
            case 5:
                if (guarantors.length === 0) {
                    setGuarantorsError("can't submit application without guarantor(s)");
                    return;
                }
                break;
        }
        return true;
    }

    const invalidStep2 =
        boundByRules === false;

    const invalidStep3 =
        category === ""
        || type === ""
        || (category === 'Other' && explanation === "")
        || Number(repaymentPeriod) === 0
        || (type === 'Emergency' && repaymentPeriod > 3)
        || (type === 'Development' && repaymentPeriod > 36)

    const invalidStep4 =
        !validator.isNumeric(amount)
        || Number(amount) === 0
        || Number(amount) < 1000
        || Number(amount) > 2000000
        || Number(repaymentPeriod) === 0
        || (type === 'Emergency' && repaymentPeriod > 3)
        || (type === 'Development' && repaymentPeriod > 36)
        || calculatorId === ""
        || firstPaymentDate === ""
            || !validator.isDate(firstPaymentDate)
        || !amortizationSchedule
            || (amortizationSchedule && amortizationSchedule.length) === 0;

    const invalidStep5 =
        guarantors.length === 0;

    const invalidStep6 =
        certifyApplication === false
        || deductionFromSalary === false;

    return (
        <div className="flex items-center min-h-screen bg-gray-50">
            <div className="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl">
                <div className="flex flex-col md:flex-row">

                    <div className="h-32 md:h-auto md:w-1/2">
                        <img
                            className="object-cover w-full h-full"
                            src={applicationImage}
                            alt="application"
                        />
                    </div>

                    <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                        <div className="w-full">

                            <h3 className="mb-4 text-xl font-bold text-blue-600">
                                Member Loan Application
                            </h3>
                            <div>
                                <div className="flex flex-row items-center space-x-1">
                                    <a className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal rounded-l-full " +
                                        (openTab === 1
                                            ? "text-white bg-gray-400"
                                            : "text-gray-400 bg-white border-2")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(1);
                                        }}
                                        data-toggle="tab"
                                        href="#link1"
                                        role="tablist"
                                    >
                                        1
                                    </a>
     
                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 2 || !invalidStep2
                                            ? "text-white bg-gray-400"
                                            : openTab > 2 ? "text-gray-400 bg-white border-2" :  "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(2);
                                        }}
                                        data-toggle="tab"
                                        href="#link2"
                                        role="tablist"
                                    >
                                        2
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 3 || !invalidStep3
                                            ? "text-white bg-gray-400"
                                            : openTab > 3 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(3);
                                        }}
                                        data-toggle="tab"
                                        href="#link3"
                                        role="tablist"
                                    >
                                        3
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 4 || !invalidStep4
                                            ? "text-white bg-gray-400"
                                            : openTab > 4 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(4);
                                        }}
                                        data-toggle="tab"
                                        href="#link4"
                                        role="tablist"
                                    >
                                        4
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 5 || !invalidStep5
                                            ? "text-white bg-gray-400"
                                            : openTab > 5 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(5);
                                        }}
                                        data-toggle="tab"
                                        href="#link5"
                                        role="tablist"
                                    >
                                        5
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal rounded-r-full " +
                                        (openTab === 6 || !invalidStep6
                                            ? "text-white bg-gray-400"
                                            : "text-gray-400 bg-white border-2 hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(6);
                                        }}
                                        data-toggle="tab"
                                        href="#link6"
                                        role="tablist"
                                    >
                                        Confirm
                                    </a>

                                </div>
                            </div>

                            <div className="mt-4 mb-4">

                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <h4 className="text-sm font-extralight pb-2">
                                        AMOUNT
                                    </h4>
                                    <Step1
                                        setAmount={setAmount}
                                        amount={amount}
                                        setAmountError={setAmountError}
                                        amountError={amountError}
                                    />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <h4 className="text-sm font-extralight pb-2">
                                        RULES APPLICABLE TO LOANS
                                    </h4>
                                    {member && 
                                        <Step2
                                            setBoundByRules={setBoundByRules}
                                            boundByRules={boundByRules}
                                            setBoundByRulesError={setBoundByRulesError}
                                            boundByRulesError={boundByRulesError}
                                            member={member.member}
                                        />}
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <h4 className="text-sm font-extralight pb-2">
                                        TYPE / CATEGORY / REPAYMENT
                                    </h4>
                                    <Step3
                                        authProps={authProps}
                                        setType={setType}
                                        setTypeError={setTypeError}
                                        typeError={typeError}
                                        setCategory={setCategory}
                                        setCategoryError={setCategoryError}
                                        categoryError={categoryError}
                                        setExplanation={setExplanation}
                                        explanation={explanation}
                                        setExplanationError={setExplanationError}
                                        explanationError={explanationError}
                                        setRepaymentPeriod={setRepaymentPeriod}
                                        repaymentPeriod={repaymentPeriod}
                                        setRepaymentPeriodError={setRepaymentPeriodError}
                                        repaymentPeriodError={repaymentPeriodError}
                                        category={category}
                                        type={type}
                                    />
                                </div>
                                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                                    <h4 className="text-sm font-extralight pb-2">
                                        LOAN AMORTIZATION SCHEDULE
                                    </h4>
                                    <Step4
                                        authProps={authProps}
                                        setAmount={setAmount}
                                        amount={amount}
                                        setAmountError={setAmountError}
                                        amountError={amountError}
                                        setRepaymentPeriod={setRepaymentPeriod}
                                        repaymentPeriod={repaymentPeriod}
                                        setRepaymentPeriodError={setRepaymentPeriodError}
                                        repaymentPeriodError={repaymentPeriodError}
                                        type={type}
                                        setCalculatorId={setCalculatorId}
                                        calculatorId={calculatorId}
                                        setFirstPaymentDate={setFirstPaymentDate}
                                        firstPaymentDate={firstPaymentDate}
                                        setFirstPaymentDateError={setFirstPaymentDateError}
                                        firstPaymentDateError={firstPaymentDateError}
                                        amortizationSchedule={amortizationSchedule}
                                        setAmortizationScheduleError={setAmortizationScheduleError}
                                        amortizationScheduleError={amortizationScheduleError}
                                    />
                                </div>
                                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                                    <h4 className="text-sm font-extralight pb-2">
                                        GUARANTORS
                                    </h4>
                                    <Step5
                                        authProps={authProps}
                                        guarantors={guarantors}
                                        setGuarantorsError={setGuarantorsError}
                                        guarantorsError={guarantorsError}
                                        loanAmount={amount}
                                    />
                                </div>
                                <div className={openTab === 6 ? "block" : "hidden"} id="link6">
                                    <h4 className="text-sm font-extralight pb-2">
                                        SUMMARY
                                    </h4>
                                    <Step6
                                        amount={Number(amount)}
                                        setCertifyApplication={setCertifyApplication}
                                        certifyApplication={certifyApplication}
                                        setDeductionFromSalary={setDeductionFromSalary}
                                        deductionFromSalary={deductionFromSalary}
                                        type={type}
                                        category={category}
                                        explanation={explanation}
                                        repaymentPeriod={repaymentPeriod}
                                        guarantors={guarantors}
                                        member={member.member}
                                    />
                                </div>
                            </div>

                            <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                <div className="flex items-center relative w-full m-auto">
                                    <div>

                                        <div className="absolute left-5 text-sm">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="transition-colors hover:text-gray-400 font-medium duration-200">
                                                Cancel
                                            </button>
                                        </div>

                                        {openTab > 1 &&
                                            <div className="absolute left-20">
                                                <button
                                                    type="button"
                                                    className={enabledWizardClass}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenTab(openTab - 1);
                                                    }}>
                                                    Previous
                                                </button>
                                            </div>
                                        }

                                        <div className="absolute right-5">
                                            <button
                                                type={openTab === 6 ? "submit" : "button"}
                                                disabled={openTab === 6 ? invalidStep6 : false}
                                                className={openTab === 6 ? invalidStep6 ? grayedOutWizardClass
                                                    : enabledWizardClass : enabledWizardClass}
                                                onClick={(e) => {
                                                    if (openTab !== 6) {
                                                        e.preventDefault();
                                                        if (validateStep(openTab)) {
                                                            setOpenTab(openTab + 1);
                                                        }
                                                    }
                                                }}>
                                                {openTab === 6 ? 'Submit' : 'Next'}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ApplicationSteps;