import React, { FC, useEffect, useState, useMemo } from 'react';
import { selectAddressStatus, selectAddressError,
    selectAddresses, fetchAddressesById,
    clearError, AddressParams, Address,
    deleteAddress} from '../../store/addressSlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { WrappedTable } from "../common/Table/WrappedTable";
import { Column } from 'react-table';
import AddEditAddress from './AddAddress';

const AddressPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectAddressStatus);
    const addresses = useAppSelector(selectAddresses);
    const addressError = useAppSelector(selectAddressError);

    const [address, setAddress] = useState<Address>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchAddressesById(authProps))
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setAddress(undefined);
        }
    };

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delAddress: Address) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setAddress(address);
    	const params: AddressParams = {
        	authProps: authProps,
        	address: delAddress,
      	}
    	dispatch(deleteAddress(params))
 	};

     const columns: Column<Address>[] = useMemo(() => [
        {
            Header: "Address",
            accessor: "address"
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('92egk66385') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[addresses])

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {addressError &&
                    <span
                        className="text-red-500"
                        style={{ display: addressError ? "block" : "none" }}>
                        {addressError}
                    </span>}
                    <div>
                        {addresses &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<Address>
                                        name="Address"
                                        columns={columns}
                                        data={addresses}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('69v29s5vr4')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<Address>
                                        name="Address"
                                        columns={columns}
                                        data={addresses}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('69v29s5vr4')}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && <AddEditAddress 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit} />}
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default AddressPage;