import React, { FC, SetStateAction } from 'react';
import Select from './Select';

interface Props {
    setRelationship: React.Dispatch<SetStateAction<string>>;
    relationship: string;
}

const Relationship: FC<Props> = ({ setRelationship, relationship }) => {

    const relationships = [
        {'id':'SPOUSE','name':'SPOUSE'},
        {'id':'CHILD','name':'CHILD'},
        {'id':'PARENT','name':'PARENT'},
        {'id':'GRANDPARENT','name':'GRANDPARENT'},
        {'id':'SIBLING','name':'SIBLING'},
        {'id':'OTHER','name':'OTHER'},
    ];

    return (
        <React.Fragment>
            <div className="grid grid-cols-3 w-full">
                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="relationship">
                    Relationship:
                </label>
                
                <div className="col-span-2">
                    <Select
                        id="relationship"
                        value={relationship}
                        placeHolder="Select relationship"
                        className="flex-grow appearance-none rounded-none w-full relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        selectList={relationships.map(relationship => {
                            return { id: relationship.id, name: relationship.name }})}
                        required={true}
                        onChange={(e) => {
                            setRelationship(e.target.value)
                        }} />
                </div>
            </div>
        </React.Fragment>
    );

};

export default Relationship;