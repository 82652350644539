import React, { FC, useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import AuthProps from '../../common/AuthProps';
import { fetchMemberMobileMoney, selectMemberMobileMoney, selectMemberMobileMoneyError, 
    MemberMobileMoneyParams, selectMemberMobileMoneyStatus, deleteMemberMobileMoney,
    clearError, MemberMobileMoney } from '../../../store/memberMobileMoneySlice';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditMobileMoneyAccount from './AddEditMobileMoneyAccount';

const MobileMoneyAccount: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const memberMobileMoneyAccounts = useAppSelector(selectMemberMobileMoney);
    const memberMobileMoneyError = useAppSelector(selectMemberMobileMoneyError);
    const status = useAppSelector(selectMemberMobileMoneyStatus);

    const [memberMobileMoney, setMemberMobileMoney] = useState<MemberMobileMoney>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('1b4cs7r39h')) {
            dispatch(fetchMemberMobileMoney(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setMemberMobileMoney(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, memberMobileMoney: MemberMobileMoney) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setMemberMobileMoney(memberMobileMoney);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, memberMobileMoney: MemberMobileMoney) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setMemberMobileMoney(memberMobileMoney);
    	const params: MemberMobileMoneyParams = {
        	authProps: authProps,
        	memberMobileMoney: memberMobileMoney,
      	}
    	dispatch(deleteMemberMobileMoney(params))
 	};

    const columns: Column<MemberMobileMoney>[] = useMemo(() => [
        {
          Header: "Provider",
          accessor: "mobileMoneyProvider"
        },
        {
            Header: "Phone Number",
            accessor: phone => { return (phone.areaCode.concat(' ').concat(phone.phoneNumber))}
        },
        {
            Header: () => (
                <div style={{ textAlign:"center" }}>
                </div>
            ),
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('dr95ucejqf') &&
                            <button
                                onClick={e => inLineEdit(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>}
                    </div>
                );
            }
  
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('1gtp30qj6c') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberMobileMoneyAccounts]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {memberMobileMoneyError &&
                <span
                    className="text-red-500"
                    style={{ display: memberMobileMoneyError ? "block" : "none" }}>
                    {memberMobileMoneyError}
                </span>}
                <div>
                    {memberMobileMoneyAccounts &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<MemberMobileMoney>
                                    name="Mobile Money"
                                    columns={columns}
                                    data={memberMobileMoneyAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('o3p1hijfvi')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<MemberMobileMoney>
                                    name="Mobile Money"
                                    columns={columns}
                                    data={memberMobileMoneyAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('o3p1hijfvi')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditMobileMoneyAccount 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            memberMobileMoney={memberMobileMoney}
                        />}
                </div>
            </React.Fragment>
    );
}

export default MobileMoneyAccount;