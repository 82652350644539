import React, { FC, useEffect, useState } from 'react';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { selectMemberStatus, MemberParams, deleteMember,
         selectMemberError, clearError, selectMember,
        fetchMemberById, Member, editMember } from '../../store/memberSlice';
import ConfirmDialog from '../../shared/confirm/Confirm'
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';
import { fetchMemberRegistrationStatus, selectMemberRegistrationStatus } from '../../store/memberRegistrationStatusSlice';
import { fetchMemberStatus, 
    selectMemberCurrentStatus } from '../../store/memberStatusSlice';
import useToast from "../../hooks/useToast";
import { fetchMemberBranchById, selectMemberBranch } from '../../store/memberBranchSlice';

const MemberPage: FC<AuthProps> = (authProps) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const status = useAppSelector(selectMemberStatus);
    const member = useAppSelector(selectMember);
    const memberError = useAppSelector(selectMemberError);

    const memberRegistrationStatus = useAppSelector(selectMemberRegistrationStatus);
    const memberCurrentStatus = useAppSelector(selectMemberCurrentStatus);

    const memberBranch = useAppSelector(selectMemberBranch);

    const [firstName, setFirstName] = useState<string>("");
    const [middleName, setMiddleName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [maritalStatus, setMaritalStatus] = useState<string>("");
    const [memberType, setMemberType] = useState<string>("");

    const [registrationStatus, setRegistrationStatus] = useState<string>("");
    const [memberStatus, setMemberStatus] = useState<string>("");

    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchMemberBranchById(authProps));
        dispatch(fetchMemberById(authProps));
        dispatch(fetchMemberRegistrationStatus(authProps));
        dispatch(fetchMemberStatus(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        setFirstName(member.member?.firstName);
        setMiddleName(member.member?.middleName);
        setSurname(member.member?.surname);
        setDateOfBirth(member.member?.dateOfBirth);
        setGender(member.member?.gender);
        setMaritalStatus(member.member?.maritalStatus);
        setMemberType(member.member?.memberType);
        setRegistrationStatus(memberRegistrationStatus?.registrationStatus);
        setMemberStatus(memberCurrentStatus?.status);
    },[member,memberRegistrationStatus,memberCurrentStatus]);

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!editMode) {
            setEditMode(true);
            return;
        }

        const editedMember: Member = {
            id: member.member.id,
            saccoId: member.member.saccoId,
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            dateOfBirth: dateOfBirth,
            memberType: member.member.memberType,
            gender: member.member.gender,
            maritalStatus: member.member.maritalStatus,
            role: member.member.role,
            accountId: authProps.config.ACCOUNT_ID,
        }

        const params: MemberParams = {
            authProps: authProps,
            member: editedMember,
        }

        const result = await dispatch(editMember(params));
        let errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', 'Member updated successfully');
            setEditMode(false);
        }

    }

    const cancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setEditMode(false);
    };

    const isInvalid =
        firstName === ""
        || surname === ""
        || dateOfBirth === ""
        || gender === "";

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
			<React.Fragment>
                {memberError &&
                    <span
                        className="text-red-500"
                        style={{ display: memberError ? "block" : "none" }}>
                        {memberError}
                    </span>}
				<div className="inline-block align-bottom">
                    {memberBranch && memberBranch.memberBranch &&
                    <div className="p-1 bg-gray-50 inset border">
                        <h4 className="text-sm font-normal">
                            {`Employer: ${memberBranch.memberBranch.branch.employer}`}
                            <span className="font-extralight">
                                {` Branch: ${memberBranch.memberBranch.branch.name} (${memberBranch.memberBranch.branch.town})`}
                            </span>
                        </h4>
                    </div>}
                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                        <div className="rounded-md shadow-sm -space-y-px">

                            <div className="md:grid grid-cols-3 w-full gap-2">

                                <div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="firstName">
                                            First Name
                                        </label>
                                        <input
                                            disabled={!editMode}
                                            id="firstName"
                                            type="text"
                                            name="firstName"
                                            value={firstName}
                                            maxLength={20}
                                            required
                                            className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            onChange={(e) => {
                                                dispatch(clearError(null));
                                                setFirstName(e.target.value);
                                            }}
                                            autoFocus
                                        />
                                    </div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="middleName">
                                            Middle Name
                                        </label>
                                        <input
                                            disabled={!editMode}
                                            id="middleName"
                                            type="text"
                                            name="middleName"
                                            value={middleName}
                                            maxLength={20}
                                            className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            onChange={(e) => {
                                                dispatch(clearError(null));
                                                setMiddleName(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="surname">
                                            Surname
                                        </label>
                                        <input
                                            disabled={!editMode}
                                            id="surname"
                                            type="text"
                                            name="surname"
                                            value={surname}
                                            maxLength={20}
                                            required
                                            className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            onChange={(e) => {
                                                dispatch(clearError(null));
                                                setSurname(e.target.value);
                                            }}
                                        />
                                    </div>

                                </div>

                                <div>


                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="dateOfBirth">
                                            Date of Birth
                                        </label>
                                        {editMode ?
                                            <input
                                                id="dateOfBirth"
                                                name="dateOfBirth"
                                                type="date"
                                                value={dateOfBirth}
                                                maxLength={10}
                                                required
                                                placeholder="DoB yyyy-mm-dd"
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                onChange={(e) => setDateOfBirth(e.target.value)}
                                            /> :
                                            <h2 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                                                {dateOfBirth}
                                            </h2>}
                                    </div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="gender">
                                            Gender
                                        </label>

                                        <fieldset 
                                            id="gender"
                                            className="flex items-center justify-center p-2 border w-full">
                                            <div className="inline-flex text-sm font-normal">

                                                <div className="mx-3">
                                                    <label
                                                        htmlFor="female">
                                                        Female
                                                    </label>
                                                    <input
                                                        disabled={!editMode}
                                                        className="mx-2" 
                                                        type="radio"
                                                        id="female"
                                                        value="FEMALE"
                                                        name="gender"
                                                        onChange={(e) => setGender('FEMALE')}
                                                        checked={gender === "FEMALE"} />
                                                </div>
                                                
                                                <div className="mx-3">
                                                    <label
                                                        htmlFor="male">
                                                        Male
                                                    </label>
                                                    <input 
                                                        disabled={!editMode}
                                                        className="mx-2" 
                                                        type="radio"
                                                        id="MALE" 
                                                        value="MALE"
                                                        name="gender"
                                                        onChange={(e) => setGender('MALE')}
                                                        checked={gender === "MALE"} />
                                                </div>
                                                
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="maritalStatus">
                                            Marital Status
                                        </label>
                                        <fieldset 
                                            id="maritalStatus"
                                            className="flex items-center justify-center p-2 border w-full">
                                            <div className="inline-flex text-sm font-normal">

                                                <div className="mx-1">

                                                    <label
                                                        htmlFor="single">
                                                        Single
                                                    </label>
                                                    <input 
                                                        disabled={!editMode}
                                                        className="mx-1"
                                                        type="radio"
                                                        id="single"
                                                        value="SINGLE"
                                                        name="maritalStatus"
                                                        onChange={(e) => setMaritalStatus('SINGLE')}
                                                        checked={maritalStatus === "SINGLE"} />
                                                </div>

                                                <div className="mx-1">
                                                    
                                                    <label
                                                        htmlFor="married">
                                                        Married
                                                    </label>
                                                    <input 
                                                        disabled={!editMode}
                                                        className="mx-1" 
                                                        type="radio"
                                                        id="married" 
                                                        value="MARRIED"
                                                        name="maritalStatus"
                                                        onChange={(e) => setMaritalStatus('MARRIED')}
                                                        checked={maritalStatus === "MARRIED"} />
                                                </div>

                                                <div className="mx-1">

                                                    <label
                                                        htmlFor="divorced">
                                                        Divorced
                                                    </label>
                                                    <input
                                                        disabled={!editMode}
                                                        className="mx-1" 
                                                        type="radio"
                                                        id="divorced" 
                                                        value="DIVORCED"
                                                        name="maritalStatus"
                                                        onChange={(e) => setMaritalStatus('DIVORCED')}
                                                        checked={maritalStatus === "DIVORCED"} />
                                                </div>
                                                
                                            </div>
                                        </fieldset>
                                    </div>

                                </div>

                                <div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36"
                                            htmlFor="memberType">
                                            Terms of Service
                                        </label>
                                        <h2 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                                            {memberType}
                                        </h2>
                                    </div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="Registration Status">
                                            Registration Status
                                        </label>
                                        <h2 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                                            {registrationStatus}
                                        </h2>
                                    </div>

                                    <div className="flex flex-row relative">
                                        <label className="block text-gray-700 text-sm font-bold w-36 mt-2"
                                            htmlFor="Registration Status">
                                            Status
                                        </label>
                                        <h2 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
                                            {memberStatus}
                                        </h2>
                                    </div>

                                </div>

                            </div>

				        </div>

                        <div className="flex items-center justify-center space-x-6">
                            {authProps.auth.token.features?.map(a => a.id).includes('sfuerbtal1') &&
                                <React.Fragment>
                                    <div>
                                        <button
                                            type="submit"
                                            disabled={isInvalid}
                                            className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                </svg>
                                            </span>
                                            <span className="flex items-center pl-6">
                                                {editMode ? 'Save' : 'Edit'}
                                            </span>
                                        </button>
                                    </div>

                                    <div className="text-sm">
                                        <button
                                            type="submit"
                                            onClick={cancel}
                                            className="transition-colors hover:text-gray-900 font-medium duration-200">
                                            Cancel
                                        </button>
                                    </div>
                                </React.Fragment>}
                        </div>

                    </form>
				</div>
                <div className="text-sm">
                    <ConfirmDialog
                        title={`Are you sure you want to delete member ${member.member?.firstName} ${member.member?.surname} ?`}
                        open={confirmOpen}
                        onClose={() => setConfirmOpen(false)}
                        onConfirm={() => {
                            const params: MemberParams = {
                                authProps: authProps,
                                member: null,
                            };
                            dispatch(deleteMember(params));
                        }}>
                        {`WARNING: ALL member records will be deleted`}
                    </ConfirmDialog>
                </div>
			</React.Fragment>
        );
}

export default MemberPage;