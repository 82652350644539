import React from "react";

import { classNames } from "../../shared/Utils";

interface SelectProps {
    id: string;
    value?: string;
    className?: any;
    placeHolder: string;
    selectList: {id: string, name: string }[] | [];
    required: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Select: React.FC<SelectProps> = ({ 
    id,
    value,
    className,
    placeHolder, 
    selectList, 
    required,
    disabled,
    autoFocus,
    onChange }) => {

    return (
        <div className="flex justify-center text-sm">
            <select
                disabled={disabled}
                id={id}
                value={value}
                className={classNames("w-full h-auto rounded form-select appearance-none border border-solid border-gray-300 transition ease-in-out ",
                    className)}
                required={required}
                autoFocus
                onChange={onChange}>
                <option disabled selected>{placeHolder}</option>
                {selectList?.map((item:any) => {
                    return (
                        <option key={item.id}
                            value={item.id}>
                            {item.name}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

export default Select;