import React, { FC, SetStateAction } from 'react';
import Amount from '../../common/Amount';

interface Props {
    setAmount: React.Dispatch<SetStateAction<string>>;
    amount: string;
    setAmountError: React.Dispatch<SetStateAction<string>>;
    amountError: string;
};

const Step1: FC<Props>= ({ setAmount, amount, setAmountError, amountError }) => {

    return (
        <div>

            <Amount
                setAmount={setAmount}
                amount={amount}
                setAmountError={setAmountError}
                amountError={amountError}
                placeholderText='>= 1K and <= 2M'
                maxLengthValue={7} />

        </div>
    );
};

export default Step1;