/*
    courtesy: Andrew Bliss: https://javascript.plainenglish.io/creating-a-confirm-dialog-in-react-and-tailwind-css-3b99f6e56fab
*/

interface Props {
    children: React.ReactNode;
    type?: 'submit' | 'button' | 'reset';
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
}

export default function Button(props: Props) {
    const { type = 'button', children, onClick, className = '' } = props;
    return (
        <button
            className={`bg-primary hover:bg-primary-light text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${className}`}
            type={type}
            onClick={onClick}
        >
            {children}
        </button>
    );
}