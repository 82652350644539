import React, { FC, SetStateAction } from 'react';
import { Member } from '../../../store/memberSlice';

interface Props {
    setBoundByRules: React.Dispatch<SetStateAction<boolean>>;
    boundByRules: boolean;
    setBoundByRulesError: React.Dispatch<SetStateAction<string>>;
    boundByRulesError: string;
    member: Member;
}

const Step2: FC<Props> = ({ setBoundByRules, boundByRules, member, setBoundByRulesError,
    boundByRulesError }) => {

    return (
        <>
            {member && 
                <React.Fragment>
                    
                    <div className="text-xs font-extralight inset bg-gray-100 p-2 w-full">
                        <div className="grid grid-cols-12 pt-1 pb-1 w-full">

                            <h5>
                                {`1.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`The interest charged on loan is 1% p.m. reducing balance
                                    for up to a maximum of 36 months.`}
                            </h5>

                            <h5>
                                {`2.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`The total loan granted by the society will not exceed three times
                                    shares held by me.`}
                            </h5>

                            <h5>
                                {`3.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`The salary must be channeled through Prudential to qualify`}
                            </h5>

                            <h5>
                                {`4.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`Loans are given based on 1/3 (one-thirds) rule of the net salary of the
                                    loan applicant.`}
                            </h5>

                            <h5>
                                {`5.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`The loannee must be guaranteed by at least four eligible guarantors whose
                                    salary must be channeled through Prudential. The number of guarantors
                                    may increase depending on the loan amount applied or at the discretion
                                    of the credit officer.`}
                            </h5>

                            <h5>
                                {`6.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`No guarantor will be allowed to withdraw from the society unless He or She
                                    finds another member to take HIs/Her place as a guarantor.`}
                            </h5>

                            <h5>
                                {`7.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`Salary MUST be channeled through Prudential for the entire period of the
                                    loan. Changing of pay point will lead to attachment of the loanee's
                                    shares and the balance recovered from guarantors.`}
                            </h5>

                            <h5>
                                {`8.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`SASCO loans will issued on a first come first served basis.`}
                            </h5>

                            <h5>
                                {`9.`}
                            </h5>
                            <h5 className="col-span-11">
                                {`In the event that legal or other action is necessary, in relation to your
                                    account or receovery of the deposit, it is agreed that legal costs and
                                    all reasonable charges will be on your account.`}
                            </h5>

                        </div>
                    </div>

                    <label className="inline-flex items-center p-2">
                        <input 
                            type="checkbox" 
                            checked={boundByRules}
                            className="w-4 h-4 rounded"
                            onChange={e => {
                                setBoundByRulesError("");
                                setBoundByRules(e.target.checked);
                            }} />
                            <span className="ml-2 text-sm font-normal">
                                {`I ${member.firstName} ${member.middleName} ${member.surname} 
                                    hereby confirm that I have read the rules applicable to the
                                    Sacco loans and I hereby agree to be bound by them.`}
                            </span>
                    </label>
                    <span
                        className="text-xs text-red-700"
                        style={{ display: boundByRulesError ? "block" : "none" }}>
                        {boundByRulesError}
                    </span>
                </React.Fragment>
            }
        </>
    );
}

export default Step2;