import React, { FC, SetStateAction } from 'react';
import Date from './Date';
import validator from 'validator';

interface Props {
    setFirstName: React.Dispatch<SetStateAction<string>>;
    firstName: string;
    setFirstNameError: React.Dispatch<SetStateAction<string>>;
    firstNameError: string;
    setMiddleName: React.Dispatch<SetStateAction<string>>;
    middleName: string;
    setMiddleNameError: React.Dispatch<SetStateAction<string>>;
    middleNameError: string;
    setSurname: React.Dispatch<SetStateAction<string>>;
    surname: string;
    setSurnameError: React.Dispatch<SetStateAction<string>>;
    surnameError: string;
    setDateOfBirth: React.Dispatch<SetStateAction<string>>;
    dateOfBirth: string;
    setDateOfBirthError: React.Dispatch<SetStateAction<string>>;
    dateOfBirthError: string;
    setGender: React.Dispatch<SetStateAction<string>>;
    gender: string;
    setGenderError: React.Dispatch<SetStateAction<string>>;
    genderError: string;
    setMaritalStatus: React.Dispatch<SetStateAction<string>>;
    maritalStatus: string;
    setMaritalStatusError: React.Dispatch<SetStateAction<string>>;
    maritalStatusError: string;
}

const Names: FC<Props> = ({ setFirstName, firstName, firstNameError, setFirstNameError,
    setMiddleName, middleName, setMiddleNameError, middleNameError, setSurname, surname, 
    setSurnameError, surnameError, setDateOfBirth, dateOfBirth, setDateOfBirthError,
    dateOfBirthError, setGender, gender, setGenderError, genderError, setMaritalStatus, 
    maritalStatus, setMaritalStatusError, maritalStatusError }) => {

    return (
        <React.Fragment>

            <div className="grid grid-cols-3 w-full">
                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="firstName">
                    First Name:
                </label>
                <div className="col-span-2">
                    <input
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        type="text"
                        maxLength={20}
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="max 20"
                        onChange={e => {
                            setFirstNameError("");
                            if (validator.isAlpha(e.target.value)) {
                                setFirstName(e.target.value.substring(0,1).toUpperCase()
                                    .concat(e.target.value.substring(1).toLowerCase()));
                            } else {
                                setFirstName("");
                            }
                        }}
                        autoFocus
                    />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: firstNameError ? "block" : "none" }}>
                        {firstNameError}
                    </span>
                </div>
            </div>

            <div className="grid grid-cols-3 w-full">
                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="middleName">
                    Middle Name:
                </label>
                <div className="col-span-2">
                    <input
                        id="middleName"
                        name="middleName"
                        value={middleName}
                        type="text"
                        maxLength={20}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="max 20 (optional)"
                        onChange={e => {
                            setMiddleNameError("");
                            if (validator.isAlpha(e.target.value)) {
                                setMiddleName(e.target.value.substring(0,1).toUpperCase()
                                    .concat(e.target.value.substring(1).toLowerCase()));
                            } else {
                                setMiddleName("");
                            }
                        }}
                    />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: middleNameError ? "block" : "none" }}>
                        {middleNameError}
                    </span>
                </div>
            </div>

            <div className="grid grid-cols-3 w-full">
                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="surname">
                    Surname:
                </label>

                <div className="col-span-2">
                    <input
                        id="surname"
                        name="surname"
                        value={surname}
                        type="text"
                        maxLength={20}
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="max 20"
                        onChange={e => {
                            setSurnameError("");
                            if (validator.isAlpha(e.target.value)) {
                                setSurname(e.target.value.substring(0,1).toUpperCase()
                                    .concat(e.target.value.substring(1).toLowerCase()));
                            } else {
                                setSurname("");
                            }
                        }}
                    />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: surnameError ? "block" : "none" }}>
                        {surnameError}
                    </span>
                </div>
            </div>

            <Date
                setDate={setDateOfBirth}
                date={dateOfBirth}
                setDateError={setDateOfBirthError}
                dateError={dateOfBirthError}
                label="Date of Birth" />

            <fieldset 
                id="gender"
                className="flex items-center justify-center p-2 border">
                <div className="inline-flex text-sm font-normal">

                    <div className="mx-4">
                        <label
                            htmlFor="female">
                            Female
                        </label>
                        <input
                            className="mx-3" 
                            type="radio"
                            id="female"
                            value="FEMALE"
                            checked={gender === 'FEMALE'}
                            name="gender"
                            onChange={(e) => {
                                setGenderError("");
                                setGender('FEMALE')
                            }} />
                    </div>
                    
                    <div className="mx-6">
                        <label
                            htmlFor="male">
                            Male
                        </label>
                        <input 
                            className="mx-3" 
                            type="radio"
                            id="MALE" 
                            value="MALE"
                            checked={gender === 'MALE'}
                            name="gender"
                            onChange={(e) => {
                                setGenderError("");
                                setGender('MALE')
                            }}/>
                    </div>
                    
                </div>
                <span
                    className="text-xs text-red-700"
                    style={{ display: genderError ? "block" : "none" }}>
                    {genderError}
                </span>
            </fieldset>

            <fieldset 
                id="maritalStatus"
                className="flex items-center justify-center p-2 border">
                <div className="inline-flex text-sm font-normal">

                    <div className="mx-4">

                        <label
                            htmlFor="single">
                            Single
                        </label>
                        <input 
                            className="mx-2"
                            type="radio"
                            id="single"
                            value="SINGLE"
                            checked={maritalStatus === 'SINGLE'}
                            name="maritalStatus"
                            onChange={(e) => {
                                setMaritalStatusError("");
                                setMaritalStatus('SINGLE')
                            }} />
                    </div>

                    <div className="mx-4">
                        
                        <label
                            htmlFor="married">
                            Married
                        </label>
                        <input 
                            className="mx-2" 
                            type="radio"
                            id="married" 
                            value="MARRIED"
                            checked={maritalStatus === 'MARRIED'}
                            name="maritalStatus"
                            onChange={(e) => {
                                setMaritalStatusError("");
                                setMaritalStatus('MARRIED')
                            }}/>
                    </div>

                    <div className="mx-4">

                        <label
                            htmlFor="divorced">
                            Divorced
                        </label>
                        <input 
                            className="mx-2" 
                            type="radio"
                            id="divorced" 
                            value="DIVORCED"
                            checked={maritalStatus === 'DIVORCED'}
                            name="maritalStatus"
                            onChange={(e) => {
                                setMaritalStatusError("");
                                setMaritalStatus('DIVORCED')
                            }}/>
                    </div>
                    
                </div>
                <span
                    className="text-xs text-red-700"
                    style={{ display: maritalStatusError ? "block" : "none" }}>
                    {maritalStatusError}
                </span>
            </fieldset>
        </React.Fragment>
    );
};

export default Names;