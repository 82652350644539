import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type FeedbackParams = {
    authProps: AuthProps;
    feedback: Feedback;
}

export type Feedback = {
    info: string;
    ack?: string;
}

interface FeedbackState {
    status: "idle" | "adding";
    error: string | null;
}

interface FetchErrors {
    message: string;
}

const initialState: FeedbackState = {
    error: null,
    status: "idle",
}

export const addFeedback =
    createAsyncThunk<Feedback, FeedbackParams, { rejectValue: FetchErrors }>(
    "feedback/add",
    async (params: FeedbackParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.FEEDBACK_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.feedback),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const feedbackSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(addFeedback.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(addFeedback.fulfilled, (state, { payload }) => {
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(addFeedback.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectFeedbackStatus = (state: RootState) => state.feedback.status;
export const selectFeedbackError = (state: RootState) => state.feedback.error;
export const { clearError } = feedbackSlice.actions;
export default feedbackSlice.reducer;