import React, { FC, useState, useEffect, SetStateAction } from 'react';
import { addMemberMobileMoney, editMemberMobileMoney, MemberMobileMoney, 
    selectMemberMobileMoneyError, MemberMobileMoneyParams, clearError } from '../../../store/memberMobileMoneySlice';
import { fetchMobileMoneyProviders, selectMobileMoneyProviders } from '../../../store/mobileMoneyProviderSlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import useToast from '../../../hooks/useToast';
import Select from '../../common/Select';
import { grayedOutButtonClass, enabledButtonClass } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    memberMobileMoney: MemberMobileMoney;
}

const AddEditMobileMoneyAccount: FC<Props> = ({ authProps, setShowAddEdit,
    memberMobileMoney }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!memberMobileMoney);
    const [mobileMoneyProvider, setMobileMoneyProvider] = useState<string>(editMode ? memberMobileMoney.mobileMoneyProvider : "");
    const [areaCode, setAreaCode] = useState<string>(editMode ? memberMobileMoney.areaCode : "+254");
    const [phoneNumber, setPhoneNumber] = useState<string>(editMode ? memberMobileMoney.phoneNumber : "");

    const memberMobileMoneyError = useAppSelector(selectMemberMobileMoneyError);

    const mobileMoneyProviders = useAppSelector(selectMobileMoneyProviders);
    const areaCodes = [{'id':'KE', 'name':'+254'}];

    const selectProvider = [{'id':'select','name':'Select Provider'}];


    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('k71oe4nt62')) {
            dispatch(fetchMobileMoneyProviders(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedMemberMobileMoney: MemberMobileMoney = {
            id: editMode ? memberMobileMoney.id : "",
            memberId: authProps.auth.token.userId,
            mobileMoneyProvider: mobileMoneyProvider,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
        }
        const params: MemberMobileMoneyParams = {
            authProps: authProps,
            memberMobileMoney: updatedMemberMobileMoney,
        }
        
        const result = await dispatch(editMode ? editMemberMobileMoney(params) : addMemberMobileMoney(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `Mobile money phone number edited successfully` : `Mobile money phone number added successfully`);

            setShowAddEdit(false);
        }

        if (editMode && !errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid =
        mobileMoneyProvider === ""
        || areaCode === ""
        || phoneNumber === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: a/c ${memberMobileMoney.phoneNumber}` : `Create a new mobile money account`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: memberMobileMoneyError ? "block" : "none" }}>
                                                {memberMobileMoneyError}
                                            </span>

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="mobileMoneyProvider">
                                                Mobile Provider
                                            </label>
                                            {authProps.auth.token.features?.map(a => a.id).includes('k71oe4nt62') &&
                                                <Select
                                                    id="mobileMoneyProvider"
                                                    placeHolder="Select Mobile Provider"
                                                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={
                                                        selectProvider.map(select => {
                                                            return {id: select.id, name: select.name}
                                                        }).concat(
                                                            mobileMoneyProviders.map(provider => {
                                                            return { id: provider.mobileMoneyProvider, name: provider.mobileMoneyProvider }
                                                        }))}
                                                    required={true}
                                                    value={mobileMoneyProvider}
                                                    onChange={e => {
                                                        dispatch(clearError(null));
                                                        setMobileMoneyProvider(e.target.value);
                                                    }}
                                                    autoFocus 
                                                />}

                                            <div className="flex flex-row relative">
                                                <Select
                                                    id="areaCode"
                                                    placeHolder="+254"
                                                    className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={areaCodes.map(areaCode => {
                                                        return { id: areaCode.id, name: areaCode.name }})}
                                                    required={true}
                                                    value={areaCode}
                                                    onChange={(e) => 
                                                        setAreaCode(e.target.value)}
                                                />

                                                <label htmlFor="phoneNumber" className="sr-only">
                                                    PhoneNumber
                                                </label>
                                                <input
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    maxLength={10}
                                                    type="text"
                                                    required
                                                    value={phoneNumber}
                                                    className="flex-grow appearance-none rounded-none relative block px-3 py-2 border w-0 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Phone Number"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setPhoneNumber(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Create"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditMobileMoneyAccount;