import React, { FC, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../common/hooks";
import AuthProps from "../../common/AuthProps";
import { grayedOutButtonClass, enabledButtonClass } from "../../../shared/Utils";
import useToast from "../../../hooks/useToast";
import { Member } from "../../../store/memberSlice";
import { NextOfKin, addVirtual, editVirtual } from "../../../store/nextOfKinSlice";
import Names from "../../common/Names";
import Relationship from "../../common/Relationship";
import PhoneNumber from "../../common/PhoneNumber";
import validator from 'validator';
import NationalId from "../../common/NationalId";
import Address from "../../common/Address";
import { getRandomId } from "../../../shared/Utils";

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    nextOfKin?: NextOfKin | undefined;
}

const AddEditVirtualNextOfKin: FC<Props> = ({ authProps, 
    setShowAddEdit, nextOfKin }) => {

    const toast = useToast();

    const [editMode] = useState<boolean>(!!nextOfKin);

    const [memberId, setMemberId] = useState(nextOfKin ? nextOfKin.memberId: getRandomId(10));
    const [nextOfKinId, setNextOfKinId] = useState(nextOfKin ? nextOfKin.nextOfKinId : getRandomId(10));

    const [firstName, setFirstName] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.firstName : "");
    const [firstNameError, setFirstNameError] = useState<string>("");

    const [middleName, setMiddleName] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.middleName : "");
    const [middleNameError, setMiddleNameError] = useState<string>("");

    const [surname, setSurname] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.surname: "");
    const [surnameError, setSurnameError] = useState<string>("");

    const [gender, setGender] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.gender : "");
    const [genderError, setGenderError] = useState<string>("");

    const [dateOfBirth, setDateOfBirth] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.dateOfBirth : "");
    const [dateOfBirthError, setDateOfBirthError] = useState<string>("");

    const [maritalStatus, setMaritalStatus] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.maritalStatus : "");
    const [maritalStatusError, setMaritalStatusError] = useState<string>("");

    const [relationship, setRelationship] = useState<string>(nextOfKin ? nextOfKin.relationship : "SPOUSE");

    const [areaCode, setAreaCode] = useState<string>(nextOfKin ? nextOfKin.areaCode : "+254");
    const [phoneNumber, setPhoneNumber] = useState<string>(nextOfKin ? nextOfKin.phoneNumber : "");
    const [phoneNumberError, setPhoneNumberError] = useState<string>("");

    const [nationalId, setNationalId] = useState<string>(nextOfKin ? nextOfKin.nationalId : "");
    const [nationalIdError, setNationalIdError] = useState<string>("");

    const [address, setAddress] = useState<string>(nextOfKin ? nextOfKin.address : "");
    const [addressError, setAddressError] = useState<string>("");

    const dispatch = useAppDispatch();

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const nextOfKinMember: Member = {
            id: memberId,
            saccoId: nextOfKin ? nextOfKin.nextOfKin.saccoId : "000000",
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            dateOfBirth: dateOfBirth,
            memberType: "NEXT_OF_KIN",
            gender: gender,
            maritalStatus: maritalStatus,
            role: "nextofkin",
            authenticated: false,
            accountId: authProps.config.ACCOUNT_ID,
        }

        const nextOfKinUpdate: NextOfKin = {
            memberId: memberId,
            nextOfKinId: nextOfKinId,
            nextOfKin: nextOfKinMember,
            relationship: relationship,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            nationalId: nationalId,
            address: address,
        } 
 
        dispatch(editMode ? editVirtual(nextOfKinUpdate) : addVirtual(nextOfKinUpdate));
        toast('success', editMode ? 'Member next-of-kin edited' : 'Member next-of-kin created');

        setShowAddEdit(false);
        
    }

    const isInvalid = firstName === ""
            || firstName.includes(" ")
        || (Number(middleName.length) !== 0 ? middleName.includes(" ") : false)
        || surname === ""
            || surname.includes(" ")
        || gender === ""
        || dateOfBirth === ""
            || !validator.isDate(dateOfBirth)
        || maritalStatus === ""
        || relationship === ""
        || areaCode === ""
        || phoneNumber === ""
        || nationalId === ""
        || address === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${nextOfKin?.nextOfKin.firstName} ${nextOfKin?.nextOfKin.surname}` 
                                            : "Create new next-of-kin"}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <Names 
                                                setFirstName={setFirstName}
                                                firstName={firstName}
                                                setFirstNameError={setFirstNameError}
                                                firstNameError={firstNameError}
                                                setMiddleName={setMiddleName}
                                                middleName={middleName}
                                                setMiddleNameError={setMiddleNameError}
                                                middleNameError={middleNameError}
                                                setSurname={setSurname}
                                                surname={surname}
                                                setSurnameError={setSurnameError}
                                                surnameError={surnameError}
                                                setDateOfBirth={setDateOfBirth}
                                                dateOfBirth={dateOfBirth}
                                                setDateOfBirthError={setDateOfBirthError}
                                                dateOfBirthError={dateOfBirthError}
                                                setGender={setGender}
                                                setGenderError={setGenderError}
                                                genderError={genderError}
                                                gender={gender}
                                                setMaritalStatus={setMaritalStatus}
                                                maritalStatus={maritalStatus}
                                                setMaritalStatusError={setMaritalStatusError}
                                                maritalStatusError={maritalStatusError} />

                                            <Relationship
                                                setRelationship={setRelationship}
                                                relationship={relationship} />

                                            <PhoneNumber
                                                setAreaCode={setAreaCode}
                                                areaCode={areaCode}
                                                setPhoneNumber={setPhoneNumber}
                                                phoneNumber={phoneNumber}
                                                setPhoneNumberError={setPhoneNumberError}
                                                phoneNumberError={phoneNumberError} />

                                            <NationalId
                                                setNationalId={setNationalId}
                                                nationalId={nationalId}
                                                setNationalIdError={setNationalIdError}
                                                nationalIdError={nationalIdError} />

                                            <Address
                                                setAddress={setAddress}
                                                address={address}
                                                setAddressError={setAddressError}
                                                addressError={addressError} />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Add"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddEditVirtualNextOfKin
