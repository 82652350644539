import React, { MouseEventHandler } from "react";
import { Table } from "./Table";
import THead from "./THead";
import TBody from "./TBody";
import Pagination from "./Pagination";
import {
    usePagination,
    useTable,
    TableInstance,
    Hooks,
    useColumnOrder,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useResizeColumns,
    useRowSelect,
    TableOptions,
    Row
} from "react-table";

import { TableContext } from "./types";

interface TableProps<T extends Record<string, unknown>>
    extends TableOptions<T> {
    name: string;
    onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
    onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
    onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
    onClick?: (row: Row<T>) => void;
    updateData?: (props: any) => void;
    skipPageReset?: boolean;
    adminView?: boolean;
    addonHooks: ((hooks: Hooks<any>) => void)[] | undefined;
    showAddDialog?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    allowAdd?: boolean;
    includeFooter?: boolean;
    renderRowSubComponent?: any;
}

export function WrappedTable<T extends Record<string, unknown>>(
    props: React.PropsWithChildren<TableProps<T>>
) {
    const { columns, data, addonHooks, name, showAddDialog, allowAdd,
        includeFooter, renderRowSubComponent } = props;

    // Array containing all the hooks we want the table to use
    const hooks: ((hooks: Hooks<any>) => void)[] = [
        useColumnOrder,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        useResizeColumns,
        usePagination,
        useRowSelect,
    ];

    const allHooks = addonHooks !== undefined ? hooks.concat(addonHooks) : hooks;

    const tableInstance: TableInstance<T> = useTable<T>(
        { columns, data, useSortBy, useExpanded, 
            initialState: { pageSize: 10 }, autoResetPage: false },
            ...allHooks
        );

    const { 
        getTableProps, 
        page, 
        prepareRow, 
        headerGroups,
        footerGroups,
    } = tableInstance;

  return (
    <TableContext.Provider value={tableInstance}>
    {/* Conditionally add Toolbar */}
    {/* <Toolbar />  */}
    <div className="flex flex-col">
        {showAddDialog
            && allowAdd &&
            <div className="flex-grow rounded-sm bg-gray-300 p-2">
                <div className="flex items-center justify-between h-6">
                    <h1 className="text-xl font-semibold text-blue-900">{name}</h1>
                    
                    {showAddDialog &&
                        <div className="ml-4 flex items-center md:ml-6">
                            <div className="ml-3 relative">
                                <button
                                    type="button"
                                    aria-haspopup
                                    onClick={showAddDialog}
                                    className="hover:bg-blue-300 hover:text-blue-800 bg-blue-800 text-blue-300 focus:outline-none
                                        group flex items-center rounded-sm font-medium text-sm px-3 py-1">
                                    Add
                                </button>
                            </div>
                        </div>}
                    
                </div>
            </div>}
        <div className="flex flex-row bg-gray-100 px-1">
            <div className="justify-start">
                <Table {...getTableProps()}>
                    <THead>
                        {headerGroups?.map((headerGroup, index) => {
                        return (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={`header-group-${index}`}
                                >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        scope="col"
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={
                                            column.isSorted ?
                                                column.isSortedDesc ? 
                                                    "text-sm font-bold text-gray-900 px-2 py-2 text-left border-b-4" : 
                                                    "text-sm font-bold text-gray-900 px-2 py-2 text-left border-t-4"
                                                : "text-sm font-bold text-gray-900 px-2 py-2 text-left"
                                        }>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        );
                        })}
                    </THead>
                <TBody>
                    {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <React.Fragment key={i}>
                            <tr
                                className="bg-white border-b" 
                                    {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                        return (
                                            <td
                                                className="text-sm text-gray-900 font-normal px-2 py-1" 
                                                {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                })}
                            </tr>
                            {row.isExpanded ? (
                                <tr>
                                    <td></td>
                                    <td colSpan={10}>
                                        <span>
                                            {renderRowSubComponent({ row })}
                                        </span>
                                    </td>
                                </tr>
                            ) : null}
                        </React.Fragment>
                    );
                    })}
                </TBody>
                {includeFooter && 
                    <tfoot>
                        {footerGroups?.map(group => (
                            <tr {...group.getFooterGroupProps()}>
                                {group.headers.map(column => (
                                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                                ))}
                            </tr>
                        ))}
                    </tfoot>
                }
                </Table>
            </div>
        </div>
        <div className="flex-grow pl-2">
            <Pagination />
        </div>
    </div>
    </TableContext.Provider>
  );
}
