import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type CategoryParams = {
    authProps: AuthProps;
    type: string;
}

export type Category = {
    loanType: string;
    name: string;
}

interface CategoryState {
    status: "loading" | "idle";
    error: string | null;
    list: Category[];
}

interface FetchErrors {
    message: string;
}

const initialState: CategoryState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchCategoriesByType = 
    createAsyncThunk<Category[], CategoryParams, { rejectValue: FetchErrors }>(
    "categories/fetchByType",
    async (params: CategoryParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response = 
            await fetch(params.authProps.config.LOAN_CATEGORIES_URL +
                "/" + params.type, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch loan categories",
            });
        }
        return response.json();
    }
)

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchCategoriesByType.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchCategoriesByType.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchCategoriesByType.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectCategoryStatus = (state: RootState) => state.categories.status;
export const selectCategories = (state: RootState) => state.categories.list;
export const selectCategoryError = (state: RootState) => state.categories.error;
export const { clearError } = categorySlice.actions;
export default categorySlice.reducer;