import { configureStore } from '@reduxjs/toolkit';
import feedbackSlice from './feedbackSlice';
import memberSlice from './memberSlice';
import memberIdentitySlice from './memberIdentitySlice';
import nextOfKinSlice from './nextOfKinSlice';
import beneficiarySlice from './beneficiarySlice';
import guardianSlice from './guardianSlice';
import personalPhoneSlice from './personalPhoneSlice';
import emailSlice from './emailSlice';
import addressSlice from './addressSlice';
import memberRegistrationSlice from './memberRegistrationStatusSlice';
import memberStatusSlice from './memberStatusSlice';
import monthlyDeductionSlice from './monthlyDeductionSlice';
import employerSlice from './employerSlice';
import branchSlice from './branchSlice';
import memberBranchSlice from './memberBranchSlice';
import loanApplicationSlice from './loanApplicationSlice';
import loanCategorySlice from './loanCategorySlice';
import loanGuarantorSlice from './loanGuarantorSlice';
import issuedGuaranteeSlice from './issuedGuaranteeSlice';
import memberGuarantorSlice from './memberGuarantorSlice';
import loanSlice from './loanSlice';
import bankSlice from './bankSlice';
import bankBranchSlice from './bankBranchSlice';
import memberBankAccountSlice from './memberBankAccountSlice';
import mobileMoneyProviderSlice from './mobileMoneyProviderSlice';
import memberMobileMoneySlice from './memberMobileMoneySlice';
import amortizationCalculatorSlice from './amortizationCalculatorSlice';

export const store = configureStore({
    reducer: {
        feedback: feedbackSlice,
        member: memberSlice,
        memberIdentities: memberIdentitySlice,
        nextOfKin: nextOfKinSlice,
        beneficiaries: beneficiarySlice,
        guardians: guardianSlice,
        personalPhones: personalPhoneSlice,
        emails: emailSlice,
        address: addressSlice,
        memberRegistrationStatus: memberRegistrationSlice,
        memberStatus: memberStatusSlice,
        monthlyDeduction: monthlyDeductionSlice,
        employers: employerSlice,
        branches: branchSlice,
        memberBranch: memberBranchSlice,
        loanApplication: loanApplicationSlice,
        categories: loanCategorySlice,
        guarantors: loanGuarantorSlice,
        issuedGuarantees: issuedGuaranteeSlice,
        guaranteeship: memberGuarantorSlice,
        loans: loanSlice,
        banks: bankSlice,
        bankBranches: bankBranchSlice,
        memberBankAccounts: memberBankAccountSlice,
        mobileMoneyProviders: mobileMoneyProviderSlice,
        memberMobileMoney: memberMobileMoneySlice,
        calculator: amortizationCalculatorSlice,
    }
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;