import { MonthlyDeduction } from './monthlyDeductionSlice';
import { MemberBranch } from './memberBranchSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { MemberIdentity } from './memberIdentitySlice';
import { PersonalPhone } from './personalPhoneSlice';
import { Email } from './emailSlice';
import { Address } from './addressSlice';
import { NextOfKin } from './nextOfKinSlice';
import { Beneficiary } from './beneficiarySlice';
import { MemberBankAccount } from './memberBankAccountSlice';
import { MemberMobileMoney } from './memberMobileMoneySlice';

export type MemberParams = {
    authProps: AuthProps;
    member?: Member;
    memberRegistration?: MemberRegistration;
}

export type Member = {
    id: string;
    saccoId: string;
    firstName: string;
    middleName?: string;
    surname: string;
    dateOfBirth: string;
    memberType: string;
    gender: string;
    maritalStatus: string;
    role: string;
    accountId: string;
    authenticated?: boolean;
    password?: string;
    creationDate?: string;
    memberInfo?: MemberInfo;
    edit?: void;
    delete?: void;
}

export type MemberRegistration = {
    personal: Member;
    identities: MemberIdentity[];
    phone: PersonalPhone;
    email: Email;
    address: Address;
    employment: MemberBranch;
    bankAccount: MemberBankAccount;
    mobileMoneyAccount: MemberMobileMoney;
    nextOfKin: NextOfKin[];
    beneficiaries: Beneficiary[];
    registrationFeeAcceptance: boolean;
    monthlyDeduction: MonthlyDeduction;
}

export type MemberInfo = {
    id: string;
    joiningDate: string;
    periodEnrolled: string;
    cumulativeShares: number;
    loansOutstanding: number;
    valueOfLoans: number;
    potentialLoansAvailable: number;
    loanEligibilityWait: string
}

interface MemberState {
    status: "loading" | "idle" | "registering" | "editing" | "deleting";
    error: string | null;
    member: Member;
}

interface FetchErrors {
    message: string;
}

const initialState: MemberState = {
    member: null,
    error: null,
    status: "idle",
}

export const fetchMemberById = 
    createAsyncThunk<Member, AuthProps, { rejectValue: FetchErrors }>(
    "member/fetchById",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBER_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member",
            });
        }
        return response.json();
    }
);

export const fetchMemberWithInfoById = 
    createAsyncThunk<Member, AuthProps, { rejectValue: FetchErrors }>(
    "member/fetchWithInfoById",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBER_URL +
                "/withinfo/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member",
            });
        }
        return response.json();
    }
);

export const registration =
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/registration",
    async (params: MemberParams, thunkApi) => {
        const response =
            await fetch(params.authProps.config.MEMBER_REGISTRATION_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.defaultHeaders(),
                },
                body: JSON.stringify(params.memberRegistration),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editMember =
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/edit",
    async (params: MemberParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBER_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.member),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMember =
    createAsyncThunk<Member, MemberParams, { rejectValue: FetchErrors }>(
    "member/delete",
    async (params: MemberParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBER_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.member),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberById.pending, (state) => {
            state.member = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchMemberWithInfoById.pending, (state) => {
            state.member = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(registration.pending, (state) => {
            state.status = "registering";
            state.error = null;
        });
        builder.addCase(editMember.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMember.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberById.fulfilled, (state, { payload }) => {
            state.member = payload;
            state.status = "idle";
        });
        builder.addCase(fetchMemberWithInfoById.fulfilled, (state, { payload }) => {
            state.member = payload;
            state.status = "idle";
        });
        builder.addCase(registration.fulfilled, (state, { payload }) => {
            state.member = payload;
            state.status = "idle";
        });
        builder.addCase(editMember.fulfilled, (state, { payload }) => {
            state.member = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMember.fulfilled, (state, { payload }) => {
            state.member = null;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchMemberWithInfoById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(registration.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMember.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMember.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberStatus = (state: RootState) => state.member.status;
export const selectMember = (state: RootState) => state.member;
export const selectMemberError = (state: RootState) => state.member.error;
export const { clearError } = memberSlice.actions;
export default memberSlice.reducer;