import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sascoLogo from "../assets/logo192.png";
import sascoTitle from "../assets/sasco_title.png";
import Button from "./Button";

import { enabledButtonClass } from '../shared/Utils';

interface HeaderProps {
  userInfo: any;
  auth: any;
  config: any;
}

const Header: FC<HeaderProps> = ({ userInfo, auth, config }) => {

    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const handleClick = (e: any) => {
        const target = document.querySelector("#headerUserMenu");
        const withinBoundaries = e.composedPath().includes(target);
        if (withinBoundaries) {
            if (open) setOpen(false);
        }
    };

    const handleLogout = async (e: any) => {
        await auth.logoutMember({ refreshToken: userInfo.refreshToken });
        if (open) setOpen(false);
        // Clear any content
        navigate("/");
    };

    useEffect(() => {
        setLoggedIn(userInfo ? true : false);
    }, [userInfo]);

    useEffect(() => {
        window.addEventListener("click", handleClick);
        // cleanup this component
        return () => {
        window.removeEventListener("click", handleClick);
        };
    });

    return (
        <>
            <nav className="bg-gradient-to-r from-[#03905a] to-[#a7ce3c] w-full border sticky top-0 z-50">
                <div className="max-w-7xl mx-full">
                    <div className="flex items-center justify-between h-11">
                        <div className="flex items-center justify-center">
                            <div className="inset h-10">
                                <img className="h-full pr-2 ml-1 mt-1" src={sascoLogo} alt="logo" />
                            </div>
                            <div>
                                <img className="h-5 w-auto" src={sascoTitle} alt="logo" />
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                                <div className="ml-3 relative">
                                    <div>
                                        <Button
                                            id="user-menu"
                                            hasPopup={true}
                                            onClick={
                                                loggedIn
                                                ? () => setOpen(!open)
                                                : () => navigate("/login")
                                            }
                                            color={loggedIn ? "green" : "red"}
                                            text={
                                                loggedIn ? (
                                                    <div className="flex items-center justify-center">
                                                        <div className="flex-shrink-0 pr-1">
                                                            <div className="bg-gray-800 rounded-full px-2 group-hover:bg-green-700 group-hover:text-gray-200">
                                                                <span className="font-bold align-text-bottom">
                                                                {userInfo?.name.toUpperCase().charAt(0)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <span className="capitalize">
                                                            {userInfo?.name} ▼
                                                        </span>
                                                    </div>
                                                ) : (
                                                <div className="text-[#A7CE3C]">
                                                        Login
                                                    </div>
                                                )
                                            }>
                                            <span className="sr-only">Open user menu</span>
                                        </Button>
                                    </div>
                                    <div
                                        id="headerUserMenu"
                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-200 ring-1 ring-black ring-opacity-5"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="user-menu"
                                        style={{ display: open && loggedIn ? "block" : "none" }}>

                                        <button
                                            type="button"
                                            onClick={handleLogout}
                                            className="w-full text-left block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100"
                                            role="menuitem">
                                            Sign out
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden px-4">
                            {/* Mobile menu button */}
                            <button
                                className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                onClick={() => setOpen(!open)}>
                                <span className="sr-only">Open main menu</span>
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                                <svg
                                className="hidden h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`${open ? "block md:hidden" : "hidden md:hidden"}`}>
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">

                        <button
                        type="button"
                        onClick={() => navigate("/login")}
                        style={{ display: !(open && loggedIn) ? "block" : "none" }}
                        className="text-[#1F255A] hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                        Login
                        </button>
                    </div>
                    <div
                        className="pt-4 pb-3 border-t border-gray-700"
                        style={{ display: open && loggedIn ? "block" : "none" }}>
                        <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                                <div className="bg-gray-100 rounded-full px-3 py-1">
                                    <span className="text-gray-400 font-bold">
                                        {loggedIn
                                        ? userInfo?.name.toUpperCase().charAt(0)
                                        : "Login"}
                                    </span>
                                </div>
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium leading-none text-[#1F255A]">
                                    {loggedIn ? userInfo?.name : "Login"}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">

                            {auth.token &&
                                auth.token.userMenu &&
                                auth.token.userMenu.map((menu, index) => {
                                const { id, url, feature } = menu;
                                return (
                                    <button
                                        key={id}
                                        type="button"
                                        onClick={() => {
                                            navigate(url);
                                            setOpen(!open);
                                        }}
                                        style={{ display: open && loggedIn ? "block" : "none" }}
                                        className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-[#1F255A] hover:text-white hover:bg-gray-700">
                                        {feature}
                                    </button>
                                )
                            })}

                            <button
                                type="button"
                                onClick={handleLogout}
                                style={{ display: open && loggedIn ? "block" : "none" }}
                                className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-[#1F255A] hover:text-white hover:bg-gray-700">
                                Sign out
                            </button>

                            <div className="text-xs font-light text-center text-[#1F255A] pt-3">
                                <h2>
                                    &copy; Sasco Sacco 2014-2023
                                </h2>
                            </div>

                            {Notification.permission !== "granted" && 
                                <div className="flex items-center justify-center p-2">
                                    <button className={enabledButtonClass}
                                        onClick={() => {
                                            Notification.requestPermission();
                                        }}>
                                        Show notifications
                                    </button>
                                </div>}

                            </div>
                        </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
