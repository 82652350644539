import React, { FC, SetStateAction } from 'react';

interface Props {
    setAddress: React.Dispatch<SetStateAction<string>>;
    address: string;
    setAddressError: React.Dispatch<SetStateAction<string>>;
    addressError: string;
}

const Address: FC<Props> = ({ setAddress, address, setAddressError, addressError }) => {

    return (
        <div className="grid grid-cols-3 w-full">

            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="address">
                Address:
            </label>

            <div className="col-span-2">
                <textarea
                    id="address"
                    name="address"
                    value={address}
                    maxLength={100}
                    required
                    className="appearance-none rounded-none w-full mb-1 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="e.g. P.O. Box XXXXX 00200 City Square, Nairobi"
                    onChange={(e) => {
                        setAddress(e.target.value)
                }} />
                <span
                    className="text-xs text-red-700"
                    style={{ display: addressError ? "block" : "none" }}>
                    {addressError}
                </span>
            </div>
        </div>
    );

};

export default Address;