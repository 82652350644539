import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { MemberGuarantor } from './memberGuarantorSlice';
import { Loan } from './loanSlice';

export type Guarantor = {
    loanId: string;
    guarantorId: string;
    loan?: Loan;
    guarantor?: MemberGuarantor;
    amount: number;
    guarantorStatus?: string;
    reason?: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
    loanAmount?: void;
}

interface GuarantorState {
    status: "loading" | "idle";
    error: any;
    list: Guarantor[];
}

interface FetchErrors {
    message: string;
}

const initialState: GuarantorState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMyIssuedGuarantees =
    createAsyncThunk<Guarantor[], AuthProps, { rejectValue: FetchErrors }>(
    "guarantors/myIssuedGuarantees",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOAN_GUARANTORS_URL +
                "/issued/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member issued guarantees",
            });
        }
        return response.json();
    }
)

export const issuedGuaranteeSlice = createSlice({
    name: 'guarantor',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMyIssuedGuarantees.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMyIssuedGuarantees.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMyIssuedGuarantees.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectGuarantorStatus = (state: RootState) => state.issuedGuarantees.status;
export const selectGuarantors = (state: RootState) => state.issuedGuarantees.list;
export const selectGuarantorError = (state: RootState) => state.issuedGuarantees.error;
export const { clearError } = issuedGuaranteeSlice.actions;
export default issuedGuaranteeSlice.reducer;