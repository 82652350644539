import { MemberGuarantor } from './memberGuarantorSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Guarantor } from './loanGuarantorSlice';

export type Schedule = {
    id: string;
    calculatorId: string;
    dueDate: string;
    payment: number;
    interest: number;
    principal: number;
    balance: number;
}

export type Calculator = {
    id: string;
    memberId: string;
    amount: number;
    repaymentPeriod: number;
    firstPaymentDate: string;
    paymentFrequency: string;
    creationDate: string;
    schedule: Schedule[];
    accountId: string;
}

export type Loan = {
    id: string;
    memberId: string;
    member?: MemberGuarantor;
    category: string;
    type: string;
    explanation?: string;
    amount: number;
    repaymentPeriod: number;
    loanStatus?: string;
    reason?: string;
    calculatorId: string;
    guarantors?: Guarantor[];
    schedule?: Schedule[];
    creationDate?: string;
    modifiedDate?: string;
}

interface LoanState {
    status: "loading" | "idle";
    error: string | null;
    list: Loan[];
}

interface FetchErrors {
    message: string;
}

const initialState: LoanState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberLoans = 
    createAsyncThunk<Loan[], AuthProps, { rejectValue: FetchErrors }>(
    "loans/fetchByMember",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOANS_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member loan(s)",
            });
        }
        return response.json();
    }
);

export const loanSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberLoans.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberLoans.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberLoans.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanStatus = (state: RootState) => state.loans.status;
export const selectLoans = (state: RootState) => state.loans.list;
export const selectLoanError = (state: RootState) => state.loans.error;
export const { clearError } = loanSlice.actions;
export default loanSlice.reducer;