import React, { FC, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../common/hooks";
import { addMemberIdentity, editMemberIdentity, MemberIdentity, MemberIdentityParams, 
    selectMemberIdentityError, clearError } from "../../store/memberIdentitySlice";
import AuthProps from "../common/AuthProps";
import Select from "../common/Select";
import { grayedOutButtonClass, enabledButtonClass } from "../../shared/Utils";
import useToast from "../../hooks/useToast";

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    memberIdentity?: MemberIdentity | undefined;
}

const AddEditMemberIdentity: FC<Props> = ({ authProps, 
    setShowAddEdit, memberIdentity }) => {

    const toast = useToast();

    const [editMode] = useState<boolean>(!!memberIdentity);
    const [identityType, setIdentityType] = useState<string>(memberIdentity ? memberIdentity.identityType : "NATIONAL_ID");
    const [value, setValue] = useState<string>(memberIdentity ? memberIdentity.value : "");

    const identityTypes = [
        {'id':'NATIONAL_ID','name':'NATIONAL ID'},
        {'id':'PASSPORT_NUMBER','name':'PASSPORT NUMBER'},
        {'id':'KRA_PIN_NUMBER','name':'KRA PIN NUMBER'},
        {'id':'PAYROLL_NUMBER','name':'PAYROLL NUMBER'},
        {'id':'AGENT_NUMBER','name':'AGENT NUMBER'},
    ];

    const dispatch = useAppDispatch();
    const memberIdentityError = useAppSelector(selectMemberIdentityError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentMemberIdentity: MemberIdentity = {
            memberId: authProps.auth.token.userId,
            identityType: identityType,
            value: value,
        }
        const params: MemberIdentityParams = {
            authProps: authProps,
            memberIdentity: currentMemberIdentity,
        }

        const result = await dispatch(editMode ? editMemberIdentity(params) : addMemberIdentity(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? 'Member identity edited successfully' : 'Member identity created successfully');
            // clear fields
            setIdentityType("");
            setValue("");
        }

        if (editMode && !errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid = identityType === ""
        || value === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${memberIdentity?.identityType}` : "Create a new member identity"}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: memberIdentityError ? "block" : "none" }}>
                                                {memberIdentityError}
                                            </span>

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="memberType">
                                                Type
                                            </label>
                                            <Select
                                                id="identityType"
                                                value={identityType}
                                                placeHolder="Select identity type"
                                                className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                selectList={identityTypes.map(identityType => {
                                                    return { id: identityType.id, name: identityType.name }})}
                                                required={true}
                                                autoFocus
                                                onChange={(e) => 
                                                    setIdentityType(e.target.value)} />

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="value">
                                                Value
                                            </label>
                                            <input
                                                id="value"
                                                name="value"
                                                type="text"
                                                value={value}
                                                maxLength={20}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Enter value"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setValue(e.target.value);
                                                }}
                                            />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Create"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddEditMemberIdentity
