import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Container from "./components/Container";
import Sidebar from "./components/Sidebar/Sidebar";
import Login from "./components/Login";
import { ToastProvider } from './shared/toast/ToastContext';
import ToastContainer from './shared/toast/ToastContainer'

import useToken from "./hooks/useToken";
import Config from "./api/Config";
import Auth from "./api/Auth";

// import {
   // StompSessionProvider,
//} from "react-stomp-hooks";
//import WebSocketSubscription from './shared/WebSocketSubscription';
import LandingPage from './components/Landing/LandingPage';
import MultiStep from './components/Member/Registration/MultiStep';
import useSessionToken from './hooks/useSessionToken';

const App: React.FC = () => {

    const navigate = useNavigate();

    const { sessionToken, setSessionToken } = useSessionToken();
	const { token, setToken } = useToken();
  	const auth = new Auth(sessionToken, setSessionToken, token, setToken);
  	const config = new Config();

    useEffect(() => {
        if (token) {
            if (config.tokenExpired()) {
                applyRefreshToken();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const applyRefreshToken = async () => {
        await auth.refreshToken();
    }

    const server = {
        suppa: process.env.REACT_APP_BASE_URL_SOCKET,
    }

    const landingPage = () => (
        <LandingPage auth={auth} config={config} />
    );

    const memberRegistration = () => (
        <MultiStep auth={auth} config={config} />
    );

    const socketHeaders = {
        login: token && token.userId,
        passcode: "the-quick-brown"
    }

    return (
        <>
            <React.Fragment>
               
                <ToastProvider>
                    <div className="h-full">
                        <Header userInfo={token} auth={auth} config={config} />
                        <div className="flex-grow flex-shrink-0 p-2">
                            {token ? (
                                <div>
                                    <div className="hidden md:block">
                                        <aside className="flex overflow-y-auto flex-col bg-white w-1/8 px-1 border-b fixed left-0" 
                                            aria-label="Sidebar">
                                            <Sidebar auth={auth} config={config} />
                                        </aside>
                                    </div>
                                    <Container auth={auth} config={config} />
                                </div>)
                                : (	<Routes>
                                        <Route 
                                            path="/" 
                                            element={landingPage()} />
                                        <Route
                                            path="/login"
                                            element={<Login uri="/" auth={auth} />} />
                                        <Route
                                            path="/member-registration"
                                            element={memberRegistration()} />
                                        <Route 
                                            path="*" 
                                            element={landingPage()} />
                                    </Routes>)
                            }
                        </div>
                        
                    </div>
                    <ToastContainer />
                </ToastProvider>
            </React.Fragment>
        </>
    );
}

export default App;

