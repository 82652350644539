import React, { FC, useState, useMemo, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch } from '../../common/hooks';
import { NextOfKin, deleteVirtual } from '../../../store/nextOfKinSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditVirtualNextOfKin from './AddEditVirtualNextOfKin';

interface Props {
    authProps: AuthProps;
    nextOfKins: NextOfKin[];
    setNextOfKinError: React.Dispatch<SetStateAction<string>>;
    nextOfKinError: string;
}

const Step5: FC<Props> = ({ authProps, nextOfKins,
    setNextOfKinError, nextOfKinError }) => {

    const dispatch = useAppDispatch();

    const [nextOfKin, setNextOfKin] = useState<NextOfKin>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        setNextOfKinError("");
        setShowAddEdit(true);
        if (!showAddEdit) {
            setNextOfKin(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, editNextOfKin: NextOfKin) => {
    	e.preventDefault();
    	setNextOfKin(editNextOfKin);
    	setShowAddEdit(true);
  	};

    const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delNextOfKin: NextOfKin) => {
		e.preventDefault();
    	setNextOfKin(delNextOfKin);
        dispatch(deleteVirtual(delNextOfKin));
 	};

     const columns: Column<NextOfKin>[] = useMemo(() => [
        {
            Header: "Names",
            accessor: names => {return `${names.nextOfKin.firstName} ${names.nextOfKin?.middleName} ${names.nextOfKin.surname}`}
        },
        {
            Header: "Relationship",
            accessor: "relationship"
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                     </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[nextOfKin]);

    const columnsSm: Column<NextOfKin>[] = useMemo(() => [
        {
            Header: "Next-of-Kin",
            accessor: "nextOfKin",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {`${row.original.nextOfKin.firstName} ${row.original.nextOfKin.middleName} ${row.original.nextOfKin.surname}
                                    ${row.original.nextOfKin.gender} ${row.original.nextOfKin.dateOfBirth} ${row.original.nextOfKin.maritalStatus}`}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            disabled
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                    </div>
                );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[nextOfKin]);

    return (
        <React.Fragment>
            <div className="w-full">
                {nextOfKins &&
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<NextOfKin>
                                name=""
                                columns={columns}
                                data={nextOfKins}
                                addonHooks={[]}
                                showAddDialog={showAddEditDialog}
                                allowAdd={true}
                            /> 
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<NextOfKin>
                                name=""
                                columns={columnsSm}
                                data={nextOfKins}
                                addonHooks={[]}
                                showAddDialog={showAddEditDialog}
                                allowAdd={true}
                            /> 
                        </div>
                    </React.Fragment>}
            </div>
            <div>
                {showAddEdit
                    && <AddEditVirtualNextOfKin 
                        authProps={authProps} 
                        setShowAddEdit={setShowAddEdit}
                        nextOfKin={nextOfKin} />}
            </div>
            <span
                className="text-xs text-red-700"
                style={{ display: nextOfKinError ? "block" : "none" }}>
                {nextOfKinError}
            </span>
        </React.Fragment>
    );
};

export default Step5;