import { createContext, useReducer, useContext, PropsWithChildren } from 'react';

export type ToastType = {
  type: any;
  message: any;
  id: number;
}

interface ToastState {
  toasts: ToastType[];
}

const initialContext: ToastState = {
  toasts: [],
}

const ToastStateContext = createContext<ToastState>(initialContext);
const ToastDispatchContext = createContext<any>(null);

function ToastReducer(state:any, action:any) {
    switch (action.type) {
      case 'ADD_TOAST': {
        return {
          ...state,
          toasts: [...state.toasts, action.toast],
        };
      }
      case 'DELETE_TOAST': {
        const updatedToasts = state.toasts.filter((e:any) => e.id !== action.id);
        return {
          ...state,
          toasts: updatedToasts,
        };
      }
      default: {
        throw new Error('unhandled action');
      }
    }
  }

export const ToastProvider = ({children}: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(ToastReducer, {
      toasts: [],
    });
  
    return (
      <ToastStateContext.Provider value={state}>
        <ToastDispatchContext.Provider value={dispatch}>
          {children}
        </ToastDispatchContext.Provider>
      </ToastStateContext.Provider>
    );
  }

export const useToastStateContext = () => useContext(ToastStateContext);
export const useToastDispatchContext = () => useContext(ToastDispatchContext);