import React, { FC, useEffect, useMemo, useCallback } from 'react'
import { fetchMemberLoans, Loan, selectLoanError, selectLoans, selectLoanStatus,
    Schedule } from '../../store/loanSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import { ccyFormat } from '../../shared/Utils';
import { Guarantor } from '../../store/loanGuarantorSlice';
import { fetchMemberGuarantorsExcludingUser, selectMemberGuarantors } from '../../store/memberGuarantorSlice';

const LoanPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const loans = useAppSelector(selectLoans);
    const loanError = useAppSelector(selectLoanError);
    const status = useAppSelector(selectLoanStatus);

    const memberGurantors = useAppSelector(selectMemberGuarantors);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('6euckr3ur8')) {
            dispatch(fetchMemberLoans(authProps));
        }
        if (authProps.auth.token.features?.map(a => a.id).includes('pus8q405vl')) {
            dispatch(fetchMemberGuarantorsExcludingUser(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const totalAmount: any = (items: Loan[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const totalChargeAmount: any = (items: Guarantor[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const totalPayment: any = (items: Schedule[]) => {
        return items.map( ({ payment }) => Number(payment))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const totalInterest: any = (items: Schedule[]) => {
        return items.map( ({ interest }) => Number(interest))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<Loan>[] = useMemo(() => [
        {
            Header: "ID",
            accessor: loanId => { return ("L".concat(loanId.id.toUpperCase())) },
        },
        {
            Header: "Applied",
            accessor: "creationDate",
        },
        {
            Header: () => null,
            accessor: "id",
            width: 10,
            Cell: ({ row }) => (
                row.original?.guarantors &&
                    row.original.guarantors.length > 0 ? (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            className="flex flex-row">   
                            {row.isExpanded ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                                </svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>}
                        </span>) : (<div></div>)
            ),
        },
        {
            Header: "Type",
            accessor: "type"
        },
        {
            Header: "Category",
            accessor: category => { return (`${category.category} ${category.explanation ? category.explanation : ""}`)}
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.amount))}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalAmount(columnProps.data))}
                </div>
            ),
        },
        {
            Header: "Months",
            accessor: "repaymentPeriod"
        },
        {
            Header: "Status",
            accessor: "loanStatus"
        },
        {
            Header: "Reason",
            accessor: "reason"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[loans]);

    const columnsSm: Column<Loan>[] = useMemo(() => [
        {
            Header: "Applied",
            accessor: "creationDate"
        },
        {
            Header: () => null,
            accessor: "id",
            width: 30,
            Cell: ({ row }) => (
                row.original?.guarantors &&
                    row.original.guarantors.length > 0 ? (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            className="flex flex-row">   
                            {row.isExpanded ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                                </svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>}
                        </span>) : (<div></div>)
            ),
        },
        {
            Header: "Details",
            accessor: "repaymentPeriod",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {`${row.original.type}:${row.original.category} ${row.original.explanation ? row.original.explanation : ""} months: ${row.original.repaymentPeriod}
                                    ${row.original.loanStatus}:${row.original.reason}`}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: "Amount",
            accessor: "type",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {ccyFormat(row.original.amount)}
                            </div>
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalAmount(columnProps.data))}
                </div>
            ),
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[loans]);

    const guarantors: Column<Guarantor>[] = useMemo(() => [
        {
            Header: "Added",
            accessor: "creationDate"
        },
        {
            Header: "Guarantor",
            accessor: guar => { return (`${guar.guarantor.firstName} ${guar.guarantor.middleName} ${guar.guarantor.surname}`) }
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {ccyFormat(row.original.amount)}
                            </div>
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalChargeAmount(columnProps.data))}
                </div>
            ),
        },
        {
            Header: "Status",
            accessor: "guarantorStatus"
        },
        {
            Header: "Reason",
            accessor: "reason"
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberGurantors]);

    const schedule: Column<Schedule>[] = useMemo(() => [
        {
            Header: "Due",
            accessor: "dueDate"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Payment
                </div>
            ),
            accessor: "payment",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        {ccyFormat(row.original.payment)}
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalPayment(columnProps.data))}
                </div>
            ),
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Interest
                </div>
            ),
            accessor: "interest",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                            {ccyFormat(row.original.interest)}
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalInterest(columnProps.data))}
                </div>
            ),
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Principal
                </div>
            ),
            accessor: "principal",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                            {ccyFormat(row.original.principal)}
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Balance
                </div>
            ),
            accessor: "balance",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                            {ccyFormat(row.original.balance)}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    const subTable: any = useCallback(
        ({ row }) =>
            row.original.guarantors.length > 0 ?
                (
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<Guarantor>
                                name=""
                                columns={guarantors}
                                data={row.original.guarantors}
                                addonHooks={[]}
                                includeFooter />
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<Guarantor>
                                name=""
                                columns={guarantors}
                                data={row.original.guarantors}
                                addonHooks={[]}
                                includeFooter />
                        </div>
                        <div className="hidden md:block">
                            <WrappedTable<Schedule>
                                name=""
                                columns={schedule}
                                data={row.original.schedule}
                                addonHooks={[]}
                                includeFooter />
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<Schedule>
                                name=""
                                columns={schedule}
                                data={row.original.schedule}
                                addonHooks={[]}
                                includeFooter />
                        </div>
                    </React.Fragment>
                ) : (<div></div>),
            [guarantors,schedule]
    );

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
			<React.Fragment>
                {loanError &&
                <span
                    className="text-red-500"
                    style={{ display: loanError ? "block" : "none" }}>
                    {loanError}
                </span>}
				<div>
                    {loans &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<Loan>
                                    name="Loans"
                                    columns={columns}
                                    data={loans}
                                    addonHooks={[]}
                                    includeFooter
                                    renderRowSubComponent={subTable} 
                                />
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<Loan>
                                    name="Loans"
                                    columns={columnsSm}
                                    data={loans}
                                    addonHooks={[]}
                                    includeFooter
                                    renderRowSubComponent={subTable} 
                                />
                            </div>
                        </React.Fragment>}
				</div>
			</React.Fragment>
        );
};

export default LoanPage;