import React, { FC, useState, useMemo, useCallback, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch } from '../../common/hooks';
import { Beneficiary, deleteVirtualBeneficiary } from '../../../store/beneficiarySlice';
import { Guardian, deleteVirtualGuardian } from '../../../store/guardianSlice';
import { enabledButtonClass } from '../../../shared/Utils';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditVirtualBeneficiary from './AddEditVirtualBeneficiary';
import AddEditVirtualGuardian from './AddEditVirtualGuardian';
import { ccyFormatZero } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    beneficiaries: Beneficiary[];
    setTotalSharesError: React.Dispatch<SetStateAction<string>>;
    totalSharesError: string;
}

const Step6: FC<Props> = ({ authProps, beneficiaries,
    setTotalSharesError, totalSharesError }) => {

    const dispatch = useAppDispatch();

    const [beneficiary, setBeneficiary] = useState<Beneficiary>();
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [showAddEditGuardian, setShowAddEditGuardian] = useState<boolean>(false);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        setTotalSharesError("");
        setShowAddEdit(true);
        if (!showAddEdit) {
            setBeneficiary(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, editBeneficiary: Beneficiary) => {
    	e.preventDefault();
    	setBeneficiary(editBeneficiary);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delBeneficiary: Beneficiary) => {
		e.preventDefault();
    	setBeneficiary(delBeneficiary);
    	dispatch(deleteVirtualBeneficiary(delBeneficiary));
 	};

     const columns: Column<Beneficiary>[] = useMemo(() => [
        {
            Header: () => null,
            accessor: "beneficiaryId",
            width: 30,
            Cell: ({ row }) => (
                row.original.guardians?.length > 0 ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                            </svg> : 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>}
                    </span>) : (<div></div>)
            )
        },
        {
            Header: "Names",
            accessor: names => {return `${names.beneficiary.firstName} ${names.beneficiary?.middleName} ${names.beneficiary.surname}`}
        },
        {
            Header: "Relationship",
            accessor: "relationship"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    %*
                </div>
            ),
            accessor: "percentShare",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        {ccyFormatZero(row.original.percentShare)}
                    </div>
                );
            }
        },
        {
            Header: "",
            accessor: "memberId",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            className={enabledButtonClass}
                            onClick={(e) => {
                                setBeneficiary(row.original);
                                setShowAddEditGuardian(true);
                            }}>
                            Add Guardian
                        </button>
                    </div>
                );
            }
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                    </div>
                );
            }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[beneficiary]);

    const columnsSm: Column<Beneficiary>[] = useMemo(() => [
        {
            Header: () => null,
            accessor: "beneficiaryId",
            width: 30,
            Cell: ({ row }) => (
                row.original.guardians?.length > 0 ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                            </svg> : 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>}
                    </span>) : (<div></div>)
            )
        },
        {
            Header: "Beneficiary",
            accessor: "beneficiary",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {`${row.original.beneficiary.firstName} ${row.original.beneficiary.middleName} ${row.original.beneficiary.surname}
                                    ${row.original.beneficiary.gender} ${row.original.beneficiary.dateOfBirth} ${row.original.beneficiary.maritalStatus}
                                     ${row.original.relationship} ${ccyFormatZero(row.original.percentShare)}`}
                            </div>
                            <button
                                className={enabledButtonClass}
                                onClick={(e) => {
                                    setBeneficiary(row.original);
                                    setShowAddEditGuardian(true);
                                }}>
                                Add Guardian
                            </button>
                        </div>
                    </div>
                );
            }
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            disabled
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                    </div>
                );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[beneficiaries]);
    
    const guardians: Column<Guardian>[] = useMemo(() => [
        {
            Header: "Guardian",
            accessor: "memberId",
            Cell: ({ row }) => {
                return (
                    <div>
                        {`${row.original.guardian?.firstName} ${row.original.guardian?.middleName} ${row.original.guardian?.surname}`}
                    </div>
                );
            },
        },
        {
            Header: "Relationship",
            accessor: "beneficiaryId",
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.relationship}
                    </div>
                );
            },
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            disabled
                            onClick={e => {
                                e.preventDefault();
                                setTotalSharesError("");
                                setBeneficiary(row.original.beneficiary);
                                setShowAddEditGuardian(true);
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                    </div>
                );
          }
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        <button
                            onClick={e => {
                                e.preventDefault();
                                setTotalSharesError("");
                                setBeneficiary(row.original.beneficiary);
                                dispatch(deleteVirtualGuardian(row.original));
                            }}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                            </svg>
                        </button>
                    </div>
                  );
              },
            },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[beneficiaries]);

    const subTable: any = useCallback(
        ({ row }) =>
            row.original.guardians.length > 0 ?
                (<WrappedTable<Guardian>
                    name=""
                    columns={guardians}
                    data={row.original.guardians}
                    allowAdd={true}
                    addonHooks={[]} 
                    />) :
                (<div></div>),
            [guardians]
    );

    return (
        <React.Fragment>
            <div className="w-full">
                {beneficiaries &&
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<Beneficiary>
                                name=""
                                columns={columns}
                                data={beneficiaries}
                                addonHooks={[]}
                                showAddDialog={showAddEditDialog}
                                allowAdd={true}
                                renderRowSubComponent={subTable}
                            /> 
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<Beneficiary>
                                name=""
                                columns={columnsSm}
                                data={beneficiaries}
                                addonHooks={[]}
                                showAddDialog={showAddEditDialog}
                                allowAdd={true}
                                renderRowSubComponent={subTable}
                            /> 
                        </div>
                        <h4 className="font-bold text-xs">
                            *ensure total beneficiary shares = 100%
                        </h4>
                    </React.Fragment>}
            </div>
            <div>
                {showAddEdit
                    && <AddEditVirtualBeneficiary 
                        authProps={authProps} 
                        setShowAddEdit={setShowAddEdit}
                        beneficiary={beneficiary} />}
            </div>
            <div>
                {showAddEditGuardian
                    && beneficiary
                    && <AddEditVirtualGuardian 
                        authProps={authProps} 
                        setShowAddEdit={setShowAddEditGuardian}
                        beneficiary={beneficiary}
                />}
            </div>
            <span
                className="text-xs text-red-700"
                style={{ display: totalSharesError ? "block" : "none" }}>
                {totalSharesError}
            </span>
        </React.Fragment>
    );

};

export default Step6;