import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { BankBranch } from './bankBranchSlice';

export type MemberBankAccountParams = {
    authProps: AuthProps;
    memberBankAccount?: MemberBankAccount;
    bankId?: string;
}

export type MemberBankAccount = {
    memberId: string;
    bankBranchId: string;
    bankBranch?: BankBranch;
    accountNumber: string;
    edit?: void;
    delete?: void;
}

interface MemberBankAccountState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: MemberBankAccount[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberBankAccountState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberBankAccounts = 
    createAsyncThunk<MemberBankAccount[], AuthProps, { rejectValue: FetchErrors }>(
    "memberBankAccounts/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.BANKBRANCHACCOUNTS_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch bank accounts",
            });
        }
        return response.json();
    }
);

export const addMemberBankAccount =
    createAsyncThunk<MemberBankAccount, MemberBankAccountParams, { rejectValue: FetchErrors }>(
    "memberBankAccount/add",
    async (params: MemberBankAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKBRANCHACCOUNT_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberBankAccount),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editMemberBankAccount =
    createAsyncThunk<MemberBankAccount, MemberBankAccountParams, { rejectValue: FetchErrors }>(
    "memberBankAccount/edit",
    async (params: MemberBankAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKBRANCHACCOUNT_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberBankAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMemberBankAccount =
    createAsyncThunk<MemberBankAccount, MemberBankAccountParams, { rejectValue: FetchErrors }>(
    "memberBankAccount/delete",
    async (params: MemberBankAccountParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.BANKBRANCHACCOUNT_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberBankAccount),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberBankAccountSlice = createSlice({
    name: 'memberBankAccount',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberBankAccounts.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMemberBankAccount.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editMemberBankAccount.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMemberBankAccount.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberBankAccounts.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addMemberBankAccount.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editMemberBankAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, bankBranchId, accountNumber }) => 
                memberId === payload.memberId && bankBranchId === payload.bankBranchId
                && accountNumber === payload.accountNumber);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMemberBankAccount.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, bankBranchId, accountNumber }) => 
                memberId === payload.memberId && bankBranchId === payload.bankBranchId
                && accountNumber === payload.accountNumber);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberBankAccounts.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMemberBankAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMemberBankAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMemberBankAccount.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberBankAccountStatus = (state: RootState) => state.memberBankAccounts.status;
export const selectMemberBankAccounts = (state: RootState) => state.memberBankAccounts.list;
export const selectMemberBankAccountError = (state: RootState) => state.memberBankAccounts.error;
export const { clearList, clearError } = memberBankAccountSlice.actions;
export default memberBankAccountSlice.reducer;