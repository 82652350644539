import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import registerImage from "../../../assets/register.jpeg";
import { Member, MemberParams, MemberRegistration, 
    registration } from '../../../store/memberSlice';
import { MemberIdentity } from '../../../store/memberIdentitySlice';
import { PersonalPhone } from '../../../store/personalPhoneSlice';
import { Email } from '../../../store/emailSlice';
import { Address } from '../../../store/addressSlice';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import { enabledWizardClass, grayedOutWizardClass } from '../../../shared/Utils';
import useToast from "../../../hooks/useToast";
import { MemberBranch } from '../../../store/memberBranchSlice';
import { clearNextOfKinList, selectNextOfKin } from '../../../store/nextOfKinSlice';
import { clearBeneficiaryList, selectBeneficiaries } from '../../../store/beneficiarySlice';
import { MonthlyDeduction } from '../../../store/monthlyDeductionSlice';
import { Beneficiary } from '../../../store/beneficiarySlice';
import validator from 'validator';
import { clearGuardianList } from '../../../store/guardianSlice';
import { MemberBankAccount } from '../../../store/memberBankAccountSlice';
import { MemberMobileMoney } from '../../../store/memberMobileMoneySlice';

const MultiStep: FC<AuthProps> = (authProps) => {

    const toast = useToast(10000);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Step 1:
    const [firstName, setFirstName] = useState<string>("");
    const [middleName, setMiddleName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [gender, setGender] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<string>("");
    const [maritalStatus, setMaritalStatus] = useState<string>("");

    const [firstNameError, setFirstNameError] = useState<string>("");
    const [middleNameError, setMiddleNameError] = useState<string>("");
    const [surnameError, setSurnameError] = useState<string>("");
    const [dateOfBirthError, setDateOfBirthError] = useState<string>("");
    const [genderError, setGenderError] = useState<string>("");
    const [maritalStatusError, setMaritalStatusError] = useState<string>("");

    // Step 2:
    const [nationalId, setNationalId] = useState<string>("");
    const [passportNumber, setPassportNumber] = useState<string>("");
    const [kraPIN, setKraPIN] = useState<string>("");
    const [employer, setEmployer] = useState<string>("");
    const [memberType, setMemberType] = useState<string>("");
    const [branchId, setBranchId] = useState<string>("");
    const [payrollNumber, setPayrollNumber] = useState<string>("");
    const [agentNumber, setAgentNumber] = useState<string>("");

    const [nationalIdError, setNationalIdError] = useState<string>("");
    const [passportNumberError, setPassportNumberError] = useState<string>("");
    const [kraPINError, setKraPINError] = useState<string>("");
    const [employerError, setEmployerError] = useState<string>("");
    const [memberTypeError, setMemberTypeError] = useState<string>("");
    const [branchIdError, setBranchIdError] = useState<string>("");
    const [payrollNumberError, setPayrollNumberError] = useState<string>("");
    const [agentNumberError, setAgentNumberError] = useState<string>("");

    // Step 3:
    const [areaCode, setAreaCode] = useState<string>("+254");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [address, setAddress] = useState<string>("");

    const [phoneNumberError, setPhoneNumberError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [addressError, setAddressError] = useState<string>("");

    // step 4: bank/mobile accounts
    const [bankBranchId, setBankBranchId] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<string>("");

    const [mobileMoneyProvider, setMobileMoneyProvider] = useState<string>("");
    const [mobileAreaCode, setMobileAreaCode] = useState<string>("+254");
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>("");

    const [bankBranchIdError, setBankBranchIdError] = useState<string>("");
    const [accountNumberError, setAccountNumberError] = useState<string>("");

    const [mobileMoneyProviderError, setMobileMoneyProviderError] =useState<string>("");
    const [mobileAreaCodeError, setMobileAreaCodeError] = useState<string>("");
    const [mobilePhoneNumberError, setMobilePhoneNumberError] = useState<string>("");

    // Step 5: next-of-kin
    const nextOfKins = useAppSelector(selectNextOfKin);

    const [nextOfKinError, setNextOfKinError] = useState<string>("");

    // Step 6: beneficiaries
    const beneficiaries = useAppSelector(selectBeneficiaries);
    
    const [totalSharesError, setTotalSharesError] = useState<string>("");

    // Step 7: registration & monthly deduction
    const [monthlyDeduction, setMonthlyDeduction] = useState<string>("");
    const [commencementDate, setCommencementDate] = useState<string>("");
    const [registrationFeeAcceptance, setRegistrationFeeAcceptance] = useState<boolean>(false);

    const [monthlyDeductionError, setMonthlyDeductionError] = useState<string>("");
    const [commencementDateError, setCommencementDateError] = useState<string>("");
    const [registrationFeeError, setRegistrationFeeError] = useState<string>("");

    // Step 8: confirmation
    const [trueAndCorrectInfo, setTrueAndCorrectInfo] = useState<boolean>(false);

    const [trueAndCorrectError, setTrueAndCorrectError] = useState<string>("");

    const [openTab, setOpenTab] = useState(1);

    const ROLE = "applicant";

    useEffect(() => {
        setFirstName("");
        setMiddleName("");
        setSurname("");
        setDateOfBirth("");
        setMaritalStatus("");
        setNationalId("");
        setPassportNumber("");
        setKraPIN("");
        setPayrollNumber("");
        setAgentNumber("");
        setPhoneNumber("");
        setEmail("");
        setAddress("");
        setBankBranchId("");
        setAccountNumber("");
        setMonthlyDeduction("");
        setCommencementDate("");
        dispatch(clearNextOfKinList(null));
        dispatch(clearBeneficiaryList(null));
        dispatch(clearGuardianList(null));
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const cancel = () => {
        const l = navigate.length;
        l > 2 ? navigate(-1) : navigate("/");
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const regMember: Member = {
            id: "",
            saccoId: "",
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            gender: gender,
            dateOfBirth: dateOfBirth,
            memberType: memberType,
            maritalStatus: maritalStatus,
            role: ROLE,
            authenticated: false,
            accountId: authProps.config.ACCOUNT_ID,
        }

        const regNationalId: MemberIdentity = {
            memberId: "",
            identityType: 'NATIONAL ID',
            value: nationalId,
        }

        const regPassportNumber: MemberIdentity = {
            memberId: "",
            identityType: 'PASSPORT NUMBER',
            value: passportNumber,
        }

        const regKraPIN: MemberIdentity = {
            memberId: "",
            identityType: 'KRA PIN NUMBER',
            value: kraPIN,
        }

        const regPayrollNumber: MemberIdentity = {
            memberId: "",
            identityType: 'PAYROLL NUMBER',
            value: payrollNumber,
        }

        const regAgentNumber: MemberIdentity = {
            memberId: "",
            identityType: 'AGENT NUMBER',
            value: agentNumber,
        }

        const regIdentities: MemberIdentity[] = [
            regNationalId,
            regPassportNumber,
            regKraPIN,
            regPayrollNumber,
            regAgentNumber];

        const regPhone: PersonalPhone = {
            memberId: "",
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            role: ROLE,
        }

        const regEmail: Email = {
            memberId: "",
            email: email,
        }

        const regAddress: Address = {
            memberId: "",
            address: address,
        }

        const regEmployment: MemberBranch = {
            id: "",
            branchId: branchId,
        }

        const regMonthlyDeduction: MonthlyDeduction = {
            memberId: "",
            deduction: Number(monthlyDeduction),
            commencementDate: commencementDate,
        }

        const regBankAccount: MemberBankAccount = {
            memberId: "",
            bankBranchId: bankBranchId,
            accountNumber: accountNumber,
        }

        const regMobileMoneyAccount: MemberMobileMoney = {
            id: "",
            memberId: "",
            mobileMoneyProvider: mobileMoneyProvider,
            areaCode: mobileAreaCode,
            phoneNumber: mobilePhoneNumber,
        }

        const memberReg: MemberRegistration = {
            personal: regMember,
            identities: regIdentities,
            phone: regPhone,
            email: regEmail,
            address: regAddress,
            employment: regEmployment,
            bankAccount: regBankAccount,
            mobileMoneyAccount: regMobileMoneyAccount,
            nextOfKin: nextOfKins,
            beneficiaries: beneficiaries,
            registrationFeeAcceptance: registrationFeeAcceptance,
            monthlyDeduction: regMonthlyDeduction,
        }

        const params: MemberParams = {
            authProps: authProps,
            memberRegistration: memberReg,
        }

        // console.log(`..memReg ${JSON.stringify(params.memberRegistration)}`);
       
        const result = await dispatch(registration(params));
        const errorCondition = !!JSON.stringify(result).includes("message");
        
        if (!errorCondition) {
            toast('success', 'Application submitted successfully. Once your application is approved, you will be able to sign in.');
            navigate("/");
            // rm003
        }
    }

    const totalShares: any = (items: Beneficiary[]) => {
        return items.map( ({ percentShare }) => Number(percentShare))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const validateStep = (step: number) => {
        switch (step) {
            case 1:
                if (firstName === "") {
                    setFirstNameError("first name can't be empty");
                    return;
                }
                if (firstName.length < 3
                    || firstName.length > 20) {
                    setFirstNameError("name must be at least 3 characters and not more than 20");
                    return;
                }
                if (middleName !== "" && middleName === " ") {
                    setMiddleNameError("middle name can't contain spaces");
                    return;
                }
                if (middleName.length !== 0 && (middleName.length < 3
                    || middleName.length > 20)) {
                    setMiddleNameError("middle name must be at least 3 characters and not more than 20");
                    return;
                }
                if (surname === "") {
                    setSurnameError("surname can't be empty");
                    return;
                }
                if (surname.length < 3
                    || surname.length > 20) {
                    setSurnameError("surname must be at least 3 characters and not more than 20");
                    return;
                }
                if (!validator.isDate(dateOfBirth)) {
                    setDateOfBirthError("invalid date of birth");
                    return;
                }
                if (gender === "") {
                    setGenderError("gender not selected");
                    return;
                }
                if (maritalStatus === "") {
                    setMaritalStatusError("marital status not selected");
                    return;
                }
                break;
            case 2:
                if (nationalId === "" && passportNumber === "") {
                    setNationalIdError("national id or passport number can't be empty");
                    return;
                }
                if (nationalId.length !== 0 && nationalId.length < 7) {
                    setNationalIdError("national id must be at least 7 digits");
                    return
                }
                if (passportNumber.length !== 0 && passportNumber.length < 9) {
                    setPassportNumberError("passport number must be at least 9 alphanumerics");
                    return;
                }
                if (kraPIN.length !== 0 && kraPIN.length !== 11) {
                    setKraPINError("KRA PIN must be at 11 alphanumerics");
                    return;
                }
                if (employer.length === 0) {
                    setEmployerError("employer not selected");
                    return;
                }
                if (branchId.length === 0) {
                    setBranchIdError("branch not selected");
                    return;
                }
                if (memberType.length === 0) {
                    setMemberTypeError("terms of service not selected");
                    return;
                }
                if ((memberType === 'PERMANENT_EMPLOYEE'
                    || memberType === 'CONTRACT_EMPLOYEE')
                    && payrollNumber.length === 0) {
                    setPayrollNumberError("payroll number can't be empty");
                    return;
                }
                if ((memberType === 'PERMANENT_EMPLOYEE'
                    || memberType === 'CONTRACT_EMPLOYEE')
                    && !payrollNumber.toLowerCase().startsWith('plak/')) {
                    setPayrollNumberError("payroll number must start with: 'PLAK/'");
                    return;
                }
                if ((memberType === 'PERMANENT_EMPLOYEE'
                    || memberType === 'CONTRACT_EMPLOYEE')
                    && payrollNumber.length !== 10) {
                    setPayrollNumberError("payroll number must be 10 alphanumerics incuding prefix 'PLAK/'");
                    return;
                }
                if ((memberType === 'PERMANENT_EMPLOYEE'
                    || memberType === 'CONTRACT_EMPLOYEE')
                    && !validator.isInt(payrollNumber.substring(5))) {
                    setPayrollNumberError("payroll number proper must be 5 digits'");
                    return;
                }
                if (memberType === 'AGENT_OF_PRUDENTIAL'
                    && agentNumber.length === 0) {
                    setAgentNumberError("agent number can't be empty");
                    return;
                }
                if (memberType === 'AGENT_OF_PRUDENTIAL'
                    && !agentNumber.toLowerCase().startsWith('plak/')) {
                    setAgentNumberError("agent number must start with: 'PLAK/'");
                    return;
                }
                if (memberType === 'AGENT_OF_PRUDENTIAL'
                    && agentNumber.length !== 10) {
                    setAgentNumberError("agent number must be 10 alphanumerics incuding prefix 'PLAK/'");
                    return;
                }
                if (memberType === 'AGENT_OF_PRUDENTIAL'
                    && !validator.isInt(agentNumber.substring(5))) {
                    setAgentNumberError("agent number proper must be 5 digits'");
                    return;
                }
                break;
            case 3:
                if (phoneNumber.length === 0) {
                    setPhoneNumberError("phone number can't be empty");
                    return;
                }
                if (email.length === 0) {
                    setEmailError("email can't be empty");
                    return;
                }
                if (email.length < 8) {
                    setEmailError("email must be greater than 8 alphanumerics");
                    return;
                }
                if (!validator.isEmail(email)) {
                    setEmailError("invalid email address");
                    return;
                }
                if (address.length === 0) {
                    setAddressError("address can't be empty");
                    return;
                }
                if (address.length < 3) {
                    setAddressError("address can't be less than 3 alphanumerics");
                    return;
                }
                break;
            case 4:
                if (bankBranchId.length === 0) {
                    setBankBranchIdError("bank branch can't be empty");
                    return;
                }
                if (accountNumber.length === 0) {
                    setAccountNumberError("account number can't be empty");
                    return;
                }
                if (mobileMoneyProvider.length === 0) {
                    setMobileMoneyProviderError("mobile money provider can't be empty");
                    return;
                }
                if (mobileAreaCode.length === 0) {
                    setMobileAreaCodeError("mobile area code can't be empty");
                    return;
                }
                if (mobilePhoneNumber.length === 0) {
                    setMobilePhoneNumberError("mobile phone number can't be empty");
                    return;
                }
                break;
            case 5:
                if (nextOfKins.length === 0) {
                    setNextOfKinError("at least one next-of-kin is required");
                    return;
                }
                break;
            case 6:
                if (Number(totalShares(beneficiaries)) !== 100) {
                    setTotalSharesError("total shares must equal 100%");
                    return;
                }
                break;
            case 7:
                if (monthlyDeduction.length === 0) {
                    setMonthlyDeductionError("monthly deduction can't be empty");
                    return;
                }
                if (Number(monthlyDeduction) < 1000
                    || Number(monthlyDeduction) > 999999) {
                    setMonthlyDeductionError("monthly deduction must be between 1,000 and 999,999");
                    return;
                }
                if (commencementDate.length === 0) {
                    setCommencementDateError("commencement can't be empty");
                    return;
                }
                if (!registrationFeeAcceptance) {
                    setRegistrationFeeError("can't proceed without acceptance of registration fee");
                    return;
                }
                break;
            case 8:
                if (!trueAndCorrectInfo) {
                    setTrueAndCorrectError("can't submit without affirmation that information is true & correct");
                    return;
                }
                break;
        }
        return true;
    }

    return (
        <div className="flex items-center min-h-screen bg-gray-50">
            <div className="flex-1 h-full max-w-4xl mx-auto bg-white rounded-lg shadow-xl">

                <div className="flex flex-col md:flex-row">
                    <div className="h-32 md:h-auto md:w-1/2">
                        <img
                            className="object-cover w-full h-full"
                            src={registerImage}
                            alt="register"
                        />
                    </div>

                    <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                        <div className="w-full">
                            <h3 className="mb-4 text-xl font-bold text-blue-600">
                                Member Registration
                            </h3>
                            <div>

                                <div className="flex flex-row items-center space-x-1">
                                    <a className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal rounded-l-full " +
                                        (openTab === 1
                                            ? "text-white bg-gray-400"
                                            : "text-gray-400 bg-white border-2")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(1);
                                        }}
                                        data-toggle="tab"
                                        href="#link1"
                                        role="tablist"
                                    >
                                        1
                                    </a>
     
                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 2
                                            ? "text-white bg-gray-400"
                                            : openTab > 2 ? "text-gray-400 bg-white border-2" :  "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(2);
                                        }}
                                        data-toggle="tab"
                                        href="#link2"
                                        role="tablist"
                                    >
                                        2
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 3
                                            ? "text-white bg-gray-400"
                                            : openTab > 3 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(3);
                                        }}
                                        data-toggle="tab"
                                        href="#link3"
                                        role="tablist"
                                    >
                                        3
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 4
                                            ? "text-white bg-gray-400"
                                            : openTab > 4 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(4);
                                        }}
                                        data-toggle="tab"
                                        href="#link4"
                                        role="tablist"
                                    >
                                        4
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 5
                                            ? "text-white bg-gray-400"
                                            : openTab > 5 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(5);
                                        }}
                                        data-toggle="tab"
                                        href="#link5"
                                        role="tablist"
                                    >
                                        5
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 6
                                            ? "text-white bg-gray-400"
                                            : openTab > 6 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(6);
                                        }}
                                        data-toggle="tab"
                                        href="#link6"
                                        role="tablist"
                                    >
                                        6
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal " +
                                        (openTab === 7
                                            ? "text-white bg-gray-400"
                                            : openTab > 7 ? "text-gray-400 bg-white border-2" : "hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(7);
                                        }}
                                        data-toggle="tab"
                                        href="#link7"
                                        role="tablist"
                                    >
                                        6
                                    </a>

                                    <a
                                        className={
                                        "text-xs font-bold uppercase px-2 py-1 block leading-normal rounded-r-full " +
                                        (openTab === 8
                                            ? "text-white bg-gray-400"
                                            : "text-gray-400 bg-white border-2 hidden")
                                        }
                                        onClick={e => {
                                            e.preventDefault();
                                            setOpenTab(7);
                                        }}
                                        data-toggle="tab"
                                        href="#link8"
                                        role="tablist"
                                    >
                                        Confirm
                                    </a>

                                </div>
                            </div>

                            <div className="mt-4 mb-4">
                                
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <h4 className="text-sm font-extralight pb-2">
                                        PERSONAL DETAILS
                                    </h4>
                                    <Step1
                                        setFirstName={setFirstName}
                                        firstName={firstName}
                                        setFirstNameError={setFirstNameError}
                                        firstNameError={firstNameError}
                                        setMiddleName={setMiddleName}
                                        middleName={middleName}
                                        setMiddleNameError={setMiddleNameError}
                                        middleNameError={middleNameError}
                                        setSurname={setSurname}
                                        surname={surname}
                                        setSurnameError={setSurnameError}
                                        surnameError={surnameError}
                                        setDateOfBirth={setDateOfBirth}
                                        dateOfBirth={dateOfBirth}
                                        setDateOfBirthError={setDateOfBirthError}
                                        dateOfBirthError={dateOfBirthError}
                                        setGender={setGender}
                                        gender={gender}
                                        setGenderError={setGenderError}
                                        genderError={genderError}
                                        setMaritalStatus={setMaritalStatus}
                                        maritalStatus={maritalStatus}
                                        setMaritalStatusError={setMaritalStatusError}
                                        maritalStatusError={maritalStatusError}
                                    />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <h4 className="text-sm font-extralight pb-2">
                                        IDENTITY
                                    </h4>
                                    <Step2
                                        authProps={authProps}
                                        setNationalId={setNationalId}
                                        nationalId={nationalId}
                                        setNationalIdError={setNationalIdError}
                                        nationalIdError={nationalIdError}
                                        setPassportNumber={setPassportNumber}
                                        passportNumber={passportNumber}
                                        setPassportNumberError={setPassportNumberError}
                                        passportNumberError={passportNumberError}
                                        setKraPIN={setKraPIN}
                                        kraPIN={kraPIN}
                                        setKraPINError={setKraPINError}
                                        kraPINError={kraPINError}
                                        setEmployer={setEmployer}
                                        employer={employer}
                                        setEmployerError={setEmployerError}
                                        employerError={employerError}
                                        setBranchId={setBranchId}
                                        setBranchIdError={setBranchIdError}
                                        branchIdError={branchIdError}
                                        setMemberType={setMemberType}
                                        memberType={memberType}
                                        setMemberTypeError={setMemberTypeError}
                                        memberTypeError={memberTypeError}
                                        setPayrollNumber={setPayrollNumber}
                                        payrollNumber={payrollNumber}
                                        setPayrollNumberError={setPayrollNumberError}
                                        payrollNumberError={payrollNumberError}
                                        setAgentNumber={setAgentNumber}
                                        agentNumber={agentNumber}
                                        setAgentNumberError={setAgentNumberError}
                                        agentNumberError={agentNumberError}
                                    />
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <h4 className="text-sm font-extralight pb-2">
                                        CONTACTS
                                    </h4>
                                    <Step3
                                        setAreaCode={setAreaCode}
                                        areaCode={areaCode}
                                        setPhoneNumber={setPhoneNumber}
                                        phoneNumber={phoneNumber}
                                        setPhoneNumberError={setPhoneNumberError}
                                        phoneNumberError={phoneNumberError}
                                        setEmail={setEmail}
                                        setEmailError={setEmailError}
                                        emailError={emailError}
                                        setAddress={setAddress}
                                        address={address}
                                        setAddressError={setAddressError}
                                        addressError={addressError}
                                    />
                                </div>

                                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                                    <h4 className="text-sm font-extralight pb-2">
                                        BANK ACCOUNT / MOBILE MONEY
                                    </h4>
                                    <Step4
                                        authProps={authProps}
                                        setBankBranchId={setBankBranchId}
                                        bankBranchId={bankBranchId}
                                        setBankBranchIdError={setBankBranchIdError}
                                        bankBranchIdError={bankBranchIdError}
                                        setAccountNumber={setAccountNumber}
                                        accountNumber={accountNumber}
                                        setAccountNumberError={setAccountNumberError}
                                        accountNumberError={accountNumberError}
                                        setMobileMoneyProvider={setMobileMoneyProvider}
                                        mobileMoneyProvider={mobileMoneyProvider}
                                        setMobileMoneyProviderError={setMobileMoneyProviderError}
                                        mobileMoneyProviderError={mobileMoneyProviderError}
                                        setMobileAreaCode={setMobileAreaCode}
                                        mobileAreaCode={mobileAreaCode}
                                        setMobileAreaCodeError={setMobileAreaCodeError}
                                        mobileAreaCodeError={mobileAreaCodeError}
                                        setMobilePhoneNumber={setMobilePhoneNumber}
                                        mobilePhoneNumber={mobilePhoneNumber}
                                        setMobilePhoneNumberError={setMobilePhoneNumberError}
                                        mobilePhoneNumberError={mobilePhoneNumberError}
                                    />
                                </div>

                                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                                    <h4 className="text-sm font-extralight pb-2">
                                        NEXT-OF-KIN
                                    </h4>
                                    <h5 className="text-xs font-extralight p-1 mb-1 inset border">
                                        {`These are details of your next-of-kin who is to be contacted in the event of
                                            an emergency or the Society's need to engage a person responsible for your
                                            well-being.`}
                                    </h5>
                                    <Step5
                                        authProps={authProps}
                                        nextOfKins={nextOfKins}
                                        setNextOfKinError={setNextOfKinError}
                                        nextOfKinError={nextOfKinError}
                                    />
                                </div>
                                <div className={openTab === 6 ? "block" : "hidden"} id="link6">
                                    <h4 className="text-sm font-extralight pb-2">
                                        NOMINEES/BENEFICIARIES
                                    </h4>
                                    <h5 className="text-xs font-extralight p-1 mb-1 inset border">
                                        {`I, the undersigned, in the event of my demise, instruct SASCO SACCO to pay all amounts
                                            due to me less any debts to the SACCO to the person(s) named below and in the
                                            percentages listed against each person. I understand I can change the name(s)
                                            of the nominated beneficiaries at any time.`}
                                    </h5>
                                    <Step6
                                        authProps={authProps}
                                        beneficiaries={beneficiaries}
                                        setTotalSharesError={setTotalSharesError}
                                        totalSharesError={totalSharesError}
                                    />
                                </div>
                                <div className={openTab === 7 ? "block" : "hidden"} id="link7">
                                    <h4 className="text-sm font-extralight pb-2">
                                        REGISTRATION FEE & MONTHLY DEDUCTION
                                    </h4>
                                    <h5 className="text-xs font-extralight p-1 mb-1 inset border">
                                        {``}
                                    </h5>
                                    <Step7
                                        authProps={authProps}
                                        setMonthlyDeduction={setMonthlyDeduction}
                                        monthlyDeduction={monthlyDeduction}
                                        setMonthlyDeductionError={setMonthlyDeductionError}
                                        monthlyDeductionError={monthlyDeductionError}
                                        setCommencementDate={setCommencementDate}
                                        commencementDate={commencementDate}
                                        setCommencementDateError={setCommencementDateError}
                                        commencementDateError={commencementDateError}
                                        registrationFeeAcceptance={registrationFeeAcceptance}
                                        setRegistrationFeeAcceptance={setRegistrationFeeAcceptance}
                                        setRegistrationFeeError={setRegistrationFeeError}
                                        registrationFeeError={registrationFeeError}
                                    />
                                </div>
                                <div className={openTab === 8 ? "block" : "hidden"} id="link8">
                                    <h4 className="text-sm font-extralight pb-2">
                                        SUMMARY
                                    </h4>
                                    <Step8
                                        authProps={authProps}
                                        firstName={firstName}
                                        middleName={middleName}
                                        surname={surname}
                                        gender={gender}
                                        dateOfBirth={dateOfBirth}
                                        maritalStatus={maritalStatus}
                                        nationalId={nationalId}
                                        passportNumber={passportNumber}
                                        kraPIN={kraPIN}
                                        employer={employer}
                                        memberType={memberType}
                                        branchId={branchId}
                                        payrollNumber={payrollNumber}
                                        agentNumber={agentNumber}
                                        areaCode={areaCode}
                                        phoneNumber={phoneNumber}
                                        email={email}
                                        address={address}
                                        nextOfKins={nextOfKins}
                                        beneficiaries={beneficiaries}
                                        monthlyDeduction={monthlyDeduction}
                                        trueAndCorrectInfo={trueAndCorrectInfo}
                                        setTrueAndCorrectInfo={setTrueAndCorrectInfo}
                                        setTrueAndCorrectError={setTrueAndCorrectError}
                                        trueAndCorrectError={trueAndCorrectError}
                                    />
                                </div>
                                
                            </div>

                            <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>

                                <div className="flex items-center relative w-full m-auto">
                                    <div>

                                        <div className="absolute left-5 text-sm">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="transition-colors hover:text-gray-400 font-medium duration-200">
                                                Cancel
                                            </button>
                                        </div>

                                        {openTab > 1 &&
                                            <div className="absolute left-20">
                                                <button
                                                    type="button"
                                                    className={enabledWizardClass}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOpenTab(openTab - 1);
                                                    }}>
                                                    Previous
                                                </button>
                                            </div>
                                        }

                                        <div className="absolute right-5">
                                            <button
                                                disabled={openTab === 8 ? !trueAndCorrectInfo : false}
                                                type={openTab === 8 ? "submit" : "button"}
                                                className={openTab === 8 && !trueAndCorrectInfo ? grayedOutWizardClass
                                                    : enabledWizardClass}
                                                onClick={(e) => {
                                                    if (openTab !== 8) {
                                                        e.preventDefault();
                                                        if (validateStep(openTab)) {
                                                            setOpenTab(openTab + 1);
                                                        }
                                                    }
                                                }}>
                                                {openTab === 8 ? 'Submit' : 'Next'}
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default MultiStep;