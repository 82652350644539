import React, { FC, SetStateAction } from 'react';
import Names from '../../common/Names';

interface Props {
    setFirstName: React.Dispatch<SetStateAction<string>>;
    firstName: string;
    setFirstNameError: React.Dispatch<SetStateAction<string>>;
    firstNameError: string;
    setMiddleName: React.Dispatch<SetStateAction<string>>;
    middleName: string;
    setMiddleNameError: React.Dispatch<SetStateAction<string>>;
    middleNameError: string;
    setSurname: React.Dispatch<SetStateAction<string>>;
    surname: string;
    setSurnameError: React.Dispatch<SetStateAction<string>>;
    surnameError: string;
    setDateOfBirth: React.Dispatch<SetStateAction<string>>;
    dateOfBirth: string;
    setDateOfBirthError: React.Dispatch<SetStateAction<string>>;
    dateOfBirthError: string;
    setGender: React.Dispatch<SetStateAction<string>>;
    gender: string;
    setGenderError: React.Dispatch<SetStateAction<string>>;
    genderError: string;
    setMaritalStatus: React.Dispatch<SetStateAction<string>>;
    maritalStatus: string;
    setMaritalStatusError: React.Dispatch<SetStateAction<string>>;
    maritalStatusError: string;
}

const Step1: FC<Props> = ({ setFirstName, firstName, firstNameError, setFirstNameError,
    setMiddleName, middleName, setMiddleNameError, middleNameError, setSurname, surname, 
    setSurnameError, surnameError, setDateOfBirth, dateOfBirth, setDateOfBirthError,
    dateOfBirthError, setGender, gender, setGenderError, genderError, setMaritalStatus, 
    maritalStatus, setMaritalStatusError, maritalStatusError }) => {

    return (
        <div>
            <Names 
                setFirstName={setFirstName}
                firstName={firstName}
                setFirstNameError={setFirstNameError}
                firstNameError={firstNameError}
                setMiddleName={setMiddleName}
                middleName={middleName}
                setMiddleNameError={setMiddleNameError}
                middleNameError={middleNameError}
                setSurname={setSurname}
                surname={surname}
                setSurnameError={setSurnameError}
                surnameError={surnameError}
                setDateOfBirth={setDateOfBirth}
                dateOfBirth={dateOfBirth}
                setDateOfBirthError={setDateOfBirthError}
                dateOfBirthError={dateOfBirthError}
                setGender={setGender}
                setGenderError={setGenderError}
                genderError={genderError}
                gender={gender}
                setMaritalStatus={setMaritalStatus}
                maritalStatus={maritalStatus}
                setMaritalStatusError={setMaritalStatusError}
                maritalStatusError={maritalStatusError} />
        </div>
    );
};

export default Step1;