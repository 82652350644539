import React, { FC, SetStateAction, useState } from "react";
import { useAppDispatch } from "../../common/hooks";
import { addVirtualGuardian, editVirtualGuardian,
    Guardian } from "../../../store/guardianSlice";
import AuthProps from "../../common/AuthProps";
import { grayedOutButtonClass, enabledButtonClass } from "../../../shared/Utils";
import useToast from "../../../hooks/useToast";
import { Member } from "../../../store/memberSlice";
import { Beneficiary, editVirtualBeneficiary } from "../../../store/beneficiarySlice";
import validator from 'validator';
import Names from "../../common/Names";
import Relationship from "../../common/Relationship";

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    beneficiary: Beneficiary;
    guardian?: Guardian | undefined;
}

const AddEditVirtualGuardian: FC<Props> = ({ authProps, 
    setShowAddEdit, beneficiary, guardian }) => {

    const toast = useToast();

    const [editMode] = useState<boolean>(!!guardian);

    const [firstName, setFirstName] = useState<string>(guardian ? guardian.guardian.firstName : "");
    const [firstNameError, setFirstNameError] = useState<string>("");

    const [middleName, setMiddleName] = useState<string>(guardian ? guardian.guardian.middleName : "");
    const [middleNameError, setMiddleNameError] = useState<string>("");

    const [surname, setSurname] = useState<string>(guardian ? guardian.guardian.surname: "");
    const [surnameError, setSurnameError] = useState<string>("");

    const [gender, setGender] = useState<string>(guardian ? guardian.guardian.gender : "");
    const [genderError, setGenderError] = useState<string>("");

    const [dateOfBirth, setDateOfBirth] = useState<string>(guardian ? guardian.guardian.dateOfBirth : "");
    const [dateOfBirthError, setDateOfBirthError] = useState<string>("");

    const [maritalStatus, setMaritalStatus] = useState<string>(guardian ? guardian.guardian.maritalStatus : "");
    const [maritalStatusError, setMaritalStatusError] = useState<string>("");

    const [relationship, setRelationship] = useState<string>(guardian ? guardian.relationship : "SPOUSE");

    const dispatch = useAppDispatch();

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const guardianMember: Member = {
            id: guardian ? guardian.guardianId : "",
            saccoId: guardian ? guardian.guardian.saccoId : "000000",
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            dateOfBirth: dateOfBirth,
            memberType: "GUARDIAN",
            gender: gender,
            maritalStatus: maritalStatus,
            role: "guardian",
            authenticated: false,
            accountId: authProps.config.ACCOUNT_ID,
        }

        const guardianUpdate: Guardian = {
            memberId: "virtualmem",
            beneficiaryId: beneficiary.beneficiaryId,
            beneficiary: beneficiary,
            guardianId: guardian ? guardian.guardian.id : "",
            guardian: guardianMember,
            relationship: relationship,
        }

        const guardians: Guardian[] = beneficiary.guardians ?
            [...beneficiary.guardians, guardianUpdate] :
                [guardianUpdate];

        const beneficiaryUpdate: Beneficiary = {
            memberId: beneficiary.memberId,
            beneficiaryId: beneficiary.beneficiaryId,
            beneficiary: beneficiary.beneficiary,
            relationship: beneficiary.relationship,
            percentShare: beneficiary.percentShare,
            guardians: guardians,
        }
        
        dispatch(editMode ? editVirtualGuardian(guardianUpdate) : addVirtualGuardian(guardianUpdate));
        dispatch(editVirtualBeneficiary(beneficiaryUpdate));

        toast('success', editMode ? 'Member guardian edited' : 'Member guardian added');

        setShowAddEdit(false);
    
    }

    const isInvalid = firstName === ""
        || firstName.includes(" ")
        || (Number(middleName.length) !== 0 ? middleName.includes(" ") : false)
        || surname === ""
            || surname.includes(" ")
        || gender === ""
        || dateOfBirth === ""
            || !validator.isDate(dateOfBirth)
        || maritalStatus === ""
        || relationship === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${guardian?.guardian.firstName} ${guardian?.guardian.surname}` 
                                            : `Add guardian for ${beneficiary.beneficiary.firstName}`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <Names 
                                                setFirstName={setFirstName}
                                                firstName={firstName}
                                                setFirstNameError={setFirstNameError}
                                                firstNameError={firstNameError}
                                                setMiddleName={setMiddleName}
                                                middleName={middleName}
                                                setMiddleNameError={setMiddleNameError}
                                                middleNameError={middleNameError}
                                                setSurname={setSurname}
                                                surname={surname}
                                                setSurnameError={setSurnameError}
                                                surnameError={surnameError}
                                                setDateOfBirth={setDateOfBirth}
                                                dateOfBirth={dateOfBirth}
                                                setDateOfBirthError={setDateOfBirthError}
                                                dateOfBirthError={dateOfBirthError}
                                                setGender={setGender}
                                                setGenderError={setGenderError}
                                                genderError={genderError}
                                                gender={gender}
                                                setMaritalStatus={setMaritalStatus}
                                                maritalStatus={maritalStatus}
                                                setMaritalStatusError={setMaritalStatusError}
                                                maritalStatusError={maritalStatusError} />

                                            <Relationship
                                                setRelationship={setRelationship}
                                                relationship={relationship} />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Add"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddEditVirtualGuardian
