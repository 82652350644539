import React, { FC, useEffect, useState, useMemo } from 'react';
import { selectMemberIdentityStatus, selectMemberIdentityError,
    selectMemberIdentities, fetchMemberIdentitiesById,
    clearError, MemberIdentityParams, MemberIdentity,
    deleteMemberIdentity} from '../../store/memberIdentitySlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { WrappedTable } from "../common/Table/WrappedTable";
import { Column } from 'react-table';
import AddEditMemberIdentity from './AddEditMemberIdentity';

const MemberIdentityPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectMemberIdentityStatus);
    const memberIdentities = useAppSelector(selectMemberIdentities);
    const memberIdentityError = useAppSelector(selectMemberIdentityError);

    const [memberIdentity, setMemberIdentity] = useState<MemberIdentity>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchMemberIdentitiesById(authProps))
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setMemberIdentity(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, editMemberIdentity: MemberIdentity) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setMemberIdentity(editMemberIdentity);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delMemberIdentity: MemberIdentity) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setMemberIdentity(memberIdentity);
    	const params: MemberIdentityParams = {
        	authProps: authProps,
        	memberIdentity: delMemberIdentity,
      	}
    	dispatch(deleteMemberIdentity(params))
 	};

     const columns: Column<MemberIdentity>[] = useMemo(() => [
        {
            Header: "Type",
            accessor: "identityType"
        },
        {
            Header: "Value",
            accessor: "value"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('3a7jn9nfak') &&
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                    {authProps.auth.token.features?.map(a => a.id).includes('d1a061ceq5') &&
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberIdentities])

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {memberIdentityError &&
                    <span
                        className="text-red-500"
                        style={{ display: memberIdentityError ? "block" : "none" }}>
                        {memberIdentityError}
                    </span>}
                    <div>
                        {memberIdentities &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<MemberIdentity>
                                        name=""
                                        columns={columns}
                                        data={memberIdentities}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('7fsj0jhuk9')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<MemberIdentity>
                                        name=""
                                        columns={columns}
                                        data={memberIdentities}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('7fsj0jhuk9')}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && <AddEditMemberIdentity 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit}
                                memberIdentity={memberIdentity} />}
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default MemberIdentityPage;