import React, { FC, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import validator from 'validator';

interface Props {
    authProps: AuthProps;
    setMonthlyDeduction: React.Dispatch<SetStateAction<string>>;
    monthlyDeduction: string;
    setMonthlyDeductionError: React.Dispatch<SetStateAction<string>>;
    monthlyDeductionError: string;
    setCommencementDate: React.Dispatch<SetStateAction<string>>;
    commencementDate: string;
    setCommencementDateError: React.Dispatch<SetStateAction<string>>;
    commencementDateError: string;
    registrationFeeAcceptance: boolean;
    setRegistrationFeeAcceptance: React.Dispatch<SetStateAction<boolean>>;
    setRegistrationFeeError: React.Dispatch<SetStateAction<string>>;
    registrationFeeError: string;
}

const Step7: FC<Props> = ({ authProps, setMonthlyDeduction, monthlyDeduction, 
     setMonthlyDeductionError, monthlyDeductionError,  setCommencementDate, 
     commencementDate, setCommencementDateError, commencementDateError,
     registrationFeeAcceptance, setRegistrationFeeAcceptance,
    setRegistrationFeeError, registrationFeeError }) => {

    return (
        <div>
            <h5 className="text-xs font-bold p-1">
                The monthly MINIMUM DEDUCTION is Ksh 1,000
            </h5>

            <div className="grid grid-cols-3 w-full">

                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="monthyDeduction">
                    Deduction:
                </label>

                <div className="col-span-2">
                    <input
                        id="monthlyDeduction"
                        name="monthlyDeduction"
                        value={monthlyDeduction}
                        type="text"
                        maxLength={5}
                        required
                        className="appearance-none rounded-none relative block px-3 py-2 border w-full border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Monthly Deduction"
                        onChange={e => {
                            setMonthlyDeductionError("");
                            if (validator.isNumeric(e.target.value)) {
                                setMonthlyDeduction(e.target.value);
                            } else {
                                setMonthlyDeduction("");
                            }
                        }}
                        autoFocus
                    />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: monthlyDeductionError ? "block" : "none" }}>
                        {monthlyDeductionError}
                    </span>
                </div>
            </div>

            <div className="grid grid-cols-3 w-full">
                
                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="commencementDate">
                    Commencing:
                </label>

                <div className="col-span-2">
                    <input
                        id="commencementDate"
                        name="commencementDate"
                        type="date"
                        value={commencementDate}
                        maxLength={10}
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="dd-mm-yyyy"
                        onChange={(e) => {
                            setCommencementDateError("");
                            if (validator.isDate(e.target.value)) {
                                setCommencementDate(e.target.value)
                            } else {
                                setCommencementDate("");
                            }
                        }}
                    />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: commencementDateError ? "block" : "none" }}>
                        {commencementDateError}
                    </span>
                </div>
            </div>

            <label className="inline-flex items-center p-2">
                <input 
                    type="checkbox" 
                    checked={registrationFeeAcceptance}
                    className="w-4 h-4 rounded"
                    onChange={e => {
                        setRegistrationFeeError("");
                        setRegistrationFeeAcceptance(e.target.checked);
                    }} />
                    <span className="ml-2 text-sm font-normal">
                        {`I hereby accept a deduction of Ksh 500 being registration fees from my
                            contributions, which is non-refundable upon withdrawal of membership.`}
                    </span>
            </label>
            <span
                className="text-xs text-red-700"
                style={{ display: registrationFeeError ? "block" : "none" }}>
                {registrationFeeError}
            </span>
        </div>
    );
};

export default Step7;