import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Branch } from './branchSlice';

export type MemberBranchParams = {
    authProps: AuthProps;
    memberId: string;
    branchId?: string;
    memberBranch?: MemberBranch;
}

export type MemberBranch = {
    id: string;     // =memberId
    branchId?: string;
    branch?: Branch;
}

interface MemberBranchState {
    status: "idle" | "adding" | "loading";
    error: string | null;
    memberBranch: MemberBranch;
}

interface FetchErrors {
    message: string;
}

const initialState: MemberBranchState = {
    memberBranch: null,
    error: null,
    status: "idle",
}

export const fetchMemberBranchById = 
    createAsyncThunk<MemberBranch, AuthProps, { rejectValue: FetchErrors }>(
    "memberBranch/fetchById",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBER_BRANCH_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member branch",
            });
        }
        return response.json();
    }
);

export const addMemberBranch =
    createAsyncThunk<MemberBranch, MemberBranchParams, { rejectValue: FetchErrors }>(
    "memberBranch/add",
    async (params: MemberBranchParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBER_BRANCH_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberBranch),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberBranchSlice = createSlice({
    name: 'memberBranch',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberBranchById.pending, (state) => {
            state.memberBranch = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMemberBranch.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberBranchById.fulfilled, (state, { payload }) => {
            state.memberBranch = payload;
            state.status = "idle";
        });
        builder.addCase(addMemberBranch.fulfilled, (state, { payload }) => {
            state.memberBranch = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberBranchById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMemberBranch.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberBranchStatus = (state: RootState) => state.memberBranch.status;
export const selectMemberBranch = (state: RootState) => state.memberBranch;
export const selectMemberBranchError = (state: RootState) => state.memberBranch.error;
export const { clearError } = memberBranchSlice.actions;
export default memberBranchSlice.reducer;