import React, { FC, SetStateAction } from 'react';
import { grayedOutButtonClass, enabledButtonClass } from '../shared/Utils';

interface Props {
    message: string;
    userAuthToken: any;
    setUserAuthToken: React.Dispatch<SetStateAction<any>>;
    handleSubmitWithToken: React.Dispatch<SetStateAction<any>>;
    cancel: React.Dispatch<SetStateAction<any>>;
}

const PhoneAuthentication: FC<Props> = ({ message, 
    userAuthToken, setUserAuthToken,
    handleSubmitWithToken, cancel }) => {

    const isInvalid =
        userAuthToken === "" || userAuthToken.length !== 6;

    return (
        <div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmitWithToken}>
                <h2 className="text-center text-normal font-normal text-gray-900">
                    {message}
                </h2>
                <input
                    id="userAuthToken"
                    type="text"
                    maxLength={6}
                    required
                    className="appearance-none rounded-none relative block w-auto px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-s"
                    placeholder="Enter token"
                    onChange={(e) => setUserAuthToken(e.target.value)}
                    autoFocus
                />

                <div className="flex items-center justify-between">
                    <div>
                        <button
                            type="submit"
                            disabled={isInvalid}
                            className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                <svg
                                    className="h-5 w-5 text-indigo-400 group-hover:text-indigo-300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true">
                                <path
                                    fillRule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clipRule="evenodd"
                                />
                                </svg>
                            </span>
                            <span className="flex items-center pl-6">
                                Send
                            </span>
                        </button>
                    </div>
                    <div className="text-sm">
                        <button
                            type="submit"
                            onClick={cancel}
                            className="transition-colors hover:text-gray-900 font-medium duration-200">
                            Cancel
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default PhoneAuthentication;