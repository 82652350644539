import React, { FC, useEffect, useState, useMemo } from 'react';
import { selectEmailStatus, selectEmailError,
    selectEmails, fetchEmailsById,
    clearError, EmailParams, Email,
    deleteEmail} from '../../store/emailSlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { WrappedTable } from "../common/Table/WrappedTable";
import { Column } from 'react-table';
import AddEditEmail from './AddEmail';

const EmailPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectEmailStatus);
    const emails = useAppSelector(selectEmails);
    const emailError = useAppSelector(selectEmailError);

    const [email, setEmail] = useState<Email>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchEmailsById(authProps))
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setEmail(undefined);
        }
    };

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delEmail: Email) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setEmail(email);
    	const params: EmailParams = {
        	authProps: authProps,
        	email: delEmail,
      	}
    	dispatch(deleteEmail(params))
 	};

     const columns: Column<Email>[] = useMemo(() => [
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('ugu0k08g5a') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[emails])

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {emailError &&
                    <span
                        className="text-red-500"
                        style={{ display: emailError ? "block" : "none" }}>
                        {emailError}
                    </span>}
                    <div>
                        {emails &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<Email>
                                        name="Email Address"
                                        columns={columns}
                                        data={emails}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('kgmcht8bo2')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<Email>
                                        name="Email Address"
                                        columns={columns}
                                        data={emails}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('kgmcht8bo2')}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && <AddEditEmail 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit} />}
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default EmailPage;