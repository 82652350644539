import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Member } from './memberSlice';

export type NextOfKinParams = {
    authProps: AuthProps;
    nextOfKin?: NextOfKin;
}

export type NextOfKin = {
    memberId: string;
    nextOfKinId: string;
    nextOfKin: Member;
    relationship: string;
    nationalId: string;
    areaCode: string;
    phoneNumber: string;
    address: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

interface NextOfKinState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: NextOfKin[];
}

interface FetchErrors {
    message: string;
}

const initialState: NextOfKinState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchNextOfKinById =
    createAsyncThunk<NextOfKin[], AuthProps, { rejectValue: FetchErrors }>(
    "nextOfKin/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.NEXTOFKIN_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member next-of-kin",
            });
        }
        return response.json();
    }
)

export const addNextOfKin =
    createAsyncThunk<NextOfKin, NextOfKinParams, { rejectValue: FetchErrors }>(
    "nextOfKin/add",
    async (params: NextOfKinParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.NEXTOFKIN_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.nextOfKin),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editNextOfKin =
    createAsyncThunk<NextOfKin, NextOfKinParams, { rejectValue: FetchErrors }>(
    "nextOfKin/edit",
    async (params: NextOfKinParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.NEXTOFKIN_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.nextOfKin),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteNextOfKin =
    createAsyncThunk<NextOfKin, NextOfKinParams, { rejectValue: FetchErrors }>(
    "nextOfKin/delete",
    async (params: NextOfKinParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.NEXTOFKIN_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.nextOfKin),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const nextOfKinSlice = createSlice({
    name: 'nextOfKin',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
        clearNextOfKinList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        addVirtual: (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        },
        editVirtual: (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, nextOfKinId }) => memberId === payload.memberId
                && nextOfKinId === payload.nextOfKinId);
            state.list[index] = payload;
            state.status = "idle";
        },
        deleteVirtual: (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, nextOfKinId }) => memberId === payload.memberId
            && nextOfKinId === payload.nextOfKinId);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchNextOfKinById.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addNextOfKin.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editNextOfKin.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteNextOfKin.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchNextOfKinById.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addNextOfKin.fulfilled, (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        });
        builder.addCase(editNextOfKin.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, nextOfKinId }) => memberId === payload.memberId
                && nextOfKinId === payload.nextOfKinId);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteNextOfKin.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, nextOfKinId }) => memberId === payload.memberId
            && nextOfKinId === payload.nextOfKinId);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchNextOfKinById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addNextOfKin.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editNextOfKin.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteNextOfKin.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectNextOfKinStatus = (state: RootState) => state.nextOfKin.status;
export const selectNextOfKin = (state: RootState) => state.nextOfKin.list;
export const selectNextOfKinCount = (state: RootState) => state.nextOfKin.list.length;
export const selectNextOfKinError = (state: RootState) => state.nextOfKin.error;
export const { clearError, clearNextOfKinList, addVirtual,
    editVirtual, deleteVirtual } = nextOfKinSlice.actions;
export default nextOfKinSlice.reducer;