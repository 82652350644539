import React, { FC, useState, useEffect } from 'react';
import { selectMonthlyDeductionError, selectMonthlyDeductionStatus,
    fetchMonthlyDeduction, selectMonthlyDeduction, editDeduction,
    MonthlyDeduction, DeductionParams, clearError } from '../../store/monthlyDeductionSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import useToast from '../../hooks/useToast';
import { grayedOutButtonClass, enabledButtonClass } from '../../shared/Utils';

const MonthlyDeductionPage: FC<AuthProps> = (authProps) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const status = useAppSelector(selectMonthlyDeductionStatus);
    const monthyDeductionError = useAppSelector(selectMonthlyDeductionError);
    const monthlyDeduction = useAppSelector(selectMonthlyDeduction);

    const [deduction, setDeduction] = useState<number>(0);
    const [prevDeduction, setPrevDeduction] = useState<number>(0);
    const [commencementDate, setCommencementDate] = useState<string>("");

    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchMonthlyDeduction(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        setDeduction(monthlyDeduction?.deduction);
        setPrevDeduction(monthlyDeduction?.deduction);
        setCommencementDate(monthlyDeduction?.commencementDate);
    },[monthlyDeduction]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!editMode) {
            setEditMode(true);
            return;
        }

        // Edit deduction if it has been updated
        if (Number(prevDeduction) !== Number(deduction)) {

            const editedDeduction: MonthlyDeduction = {
                memberId: authProps.auth.token.userId,
                deduction: deduction,
                commencementDate: commencementDate,
            }

            const deductionParams: DeductionParams = {
                authProps: authProps,
                monthlyDeduction: editedDeduction,
            }

            const deductionResult = await dispatch(editDeduction(deductionParams));
            const errorCondition = !!JSON.stringify(deductionResult).includes("message");

            if (!errorCondition) {
                toast('success', 'Monthly deduction updated successfully');
                setEditMode(false);
            }
        }

    };

    const cancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setEditMode(false);
    };

    const isInvalid = 
        Number(deduction) === 0
        || Number(deduction) < 0
        || Number(deduction) > 10000;

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
			<React.Fragment>
                {monthyDeductionError &&
                    <span
                        className="text-red-500"
                        style={{ display: monthyDeductionError ? "block" : "none" }}>
                        {monthyDeductionError}
                    </span>}
                    <div className="inline-block align-bottom">

                        <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                            <div className="rounded-md shadow-sm -space-y-px">

                                <div className="flex flex-row relative">
                                    <label className="block text-gray-700 text-sm font-bold w-36"
                                        htmlFor="monthlyDeduction">
                                        Monthly Deduction
                                    </label>
                                    <input
                                        disabled={!editMode}
                                        id="deduction"
                                        type="number"
                                        name="deduction"
                                        value={deduction}
                                        maxLength={9}
                                        required
                                        className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        onChange={(e) => {
                                            setDeduction(Number(e.target.value));
                                        }}
                                    />
                                </div>

                            </div>

                            <div className="flex items-center justify-center space-x-6">
                                {authProps.auth.token.features?.map(a => a.id).includes('c0e4thfltj') &&
                                    <React.Fragment>
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                                        className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                    </svg>
                                                </span>
                                                <span className="flex items-center pl-6">
                                                    {editMode ? 'Save' : 'Edit'}
                                                </span>
                                            </button>
                                        </div>

                                        <div className="text-sm">
                                            <button
                                                type="submit"
                                                onClick={cancel}
                                                className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                Cancel
                                            </button>
                                        </div>
                                    </React.Fragment>}
                            
                            </div>
                        </form>

                    </div>

            </React.Fragment>
        );
};

export default MonthlyDeductionPage;