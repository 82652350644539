import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { MemberGuarantor } from './memberGuarantorSlice';
import { Loan } from './loanSlice';

export type LoanGuarantorParams = {
    authProps: AuthProps;
}

export type Guarantor = {
    loanId: string;
    guarantorId: string;
    loan?: Loan;
    guarantor?: MemberGuarantor;
    amount: number;
    guarantorStatus?: string;
    reason?: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
    loanAmount?: void;
}

interface GuarantorState {
    status: "loading" | "idle";
    error: any;
    list: Guarantor[];
}

interface FetchErrors {
    message: string;
}

const initialState: GuarantorState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMyGuarantors =
    createAsyncThunk<Guarantor[], AuthProps, { rejectValue: FetchErrors }>(
    "guarantors/myGuarantors",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.LOAN_GUARANTORS_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member guarantors",
            });
        }
        return response.json();
    }
)

export const loanGuarantorSlice = createSlice({
    name: 'guarantor',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
        clearGuarantorList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        addVirtual: (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        },
        editVirtual: (state, { payload }) => {
            var index = state.list.findIndex( ({ loanId, guarantorId }) => loanId === payload.loanId
                && guarantorId === payload.guarantorId);
            state.list[index] = payload;
            state.status = "idle";
        },
        deleteVirtual: (state, { payload }) => {
            var index = state.list.findIndex( ({ loanId, guarantorId }) => loanId === payload.loanId
            && guarantorId === payload.guarantorId);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMyGuarantors.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMyGuarantors.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMyGuarantors.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectGuarantorStatus = (state: RootState) => state.guarantors.status;
export const selectGuarantors = (state: RootState) => state.guarantors.list;
export const selectGuarantorError = (state: RootState) => state.guarantors.error;
export const { clearError, clearGuarantorList, addVirtual,
    editVirtual, deleteVirtual } = loanGuarantorSlice.actions;
export default loanGuarantorSlice.reducer;