import React, { FC, useState } from 'react';
import AuthProps from '../common/AuthProps';
import BeneficiaryPage from './BeneficiaryPage';
import MemberIdentityPage from './MemberIdentityPage';
import MemberPage from './MemberPage';
import NextOfKinPage from './NextOfKinPage';
import ContactPage from './ContactPage';
import MonthlyDeductionPage from './MonthlyDeductionPage';

const ItemMain: FC<AuthProps> = (authProps) => {

    const [openTab, setOpenTab] = useState(1);

    return (

        <>
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul
                        className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row"
                        role="tablist"
                    >
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('18v74r4cgc') &&
                                <a className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 1
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(1);
                                    }}
                                    data-toggle="tab"
                                    href="#link1"
                                    role="tablist"
                                >
                                    Personal
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('7imkvd2f2l') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 2
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(2);
                                    }}
                                    data-toggle="tab"
                                    href="#link2"
                                    role="tablist"
                                >
                                    Identities
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('hvghliblbg') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 3
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(3);
                                    }}
                                    data-toggle="tab"
                                    href="#link3"
                                    role="tablist"
                                >
                                    Next-of-Kin
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('cdb4dubivu') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 4
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(4);
                                    }}
                                    data-toggle="tab"
                                    href="#link4"
                                    role="tablist"
                                >
                                    Beneficiaries
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('rfn0unk81m') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 5
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(5);
                                    }}
                                    data-toggle="tab"
                                    href="#link5"
                                    role="tablist"
                                >
                                    Contacts
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('gu8otpgg6r') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 6
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(6);
                                    }}
                                    data-toggle="tab"
                                    href="#link6"
                                    role="tablist"
                                >
                                    Monthly Deduction
                                </a>}
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                            {authProps.auth.token.features?.map(a => a.id).includes('gu8otpgg6r') &&
                                <a
                                    className={
                                    "text-xs font-bold uppercase px-5 py-1 shadow-sm rounded-xs block leading-normal " +
                                    (openTab === 7
                                        ? "text-white bg-[#A7CE3C]"
                                        : "text-gray-400 bg-white")
                                    }
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(7);
                                    }}
                                    data-toggle="tab"
                                    href="#link7"
                                    role="tablist"
                                >
                                    Employer Details
                                </a>}
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm">
                        <div className="px-0 py-0 flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                                    <MemberPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <MemberIdentityPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <NextOfKinPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                                    <BeneficiaryPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                                    <ContactPage auth={authProps.auth} config={authProps.config} />
                                </div>
                                <div className={openTab === 6 ? "block" : "hidden"} id="link6">
                                    <MonthlyDeductionPage auth={authProps.auth} config={authProps.config} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export default ItemMain;