import React, { FC, SetStateAction } from 'react';
import PhoneNumber from '../../common/PhoneNumber';
import Address from '../../common/Address';

interface Props {
    setAreaCode: React.Dispatch<SetStateAction<string>>;
    areaCode: string;
    setPhoneNumber: React.Dispatch<SetStateAction<string>>;
    phoneNumber: string;
    setPhoneNumberError: React.Dispatch<SetStateAction<string>>;
    phoneNumberError: string;
    setEmail: React.Dispatch<SetStateAction<string>>;
    setEmailError: React.Dispatch<SetStateAction<string>>;
    emailError: string;
    setAddress: React.Dispatch<SetStateAction<string>>;
    address: string;
    setAddressError: React.Dispatch<SetStateAction<string>>;
    addressError: string;
}

const Step3: FC<Props> = ({ setAreaCode, areaCode,
    setPhoneNumber, phoneNumber, setPhoneNumberError, phoneNumberError, setEmail, 
    setEmailError, emailError, setAddress, address, setAddressError, addressError }) => {

    return (
        <div>
            
            <PhoneNumber
                setAreaCode={setAreaCode}
                areaCode={areaCode}
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
                setPhoneNumberError={setPhoneNumberError}
                phoneNumberError={phoneNumberError} />

            <div className="grid grid-cols-3 w-full">

                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="email">
                    Email:
                </label>

                <div className="col-span-2">
                    <input
                        id="email"
                        name="email"
                        type="text"
                        maxLength={60}
                        required
                        className="appearance-none rounded-none mb-1 px-3 py-2 border w-full border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        onChange={e => {
                            setEmailError("");
                            setEmail(e.target.value)
                    }} />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: emailError ? "block" : "none" }}>
                        {emailError}
                    </span>
                </div>
            </div>

            <Address
                setAddress={setAddress}
                address={address}
                setAddressError={setAddressError}
                addressError={addressError} />
            
        </div>
    );
};

export default Step3;