import React, { FC, SetStateAction } from 'react';
import { CategoryParams, fetchCategoriesByType, selectCategories } from '../../../store/loanCategorySlice';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import AuthProps from '../../common/AuthProps';
import Select from '../../common/Select';
import RepaymentPeriod from '../../common/RepaymentPeriod';

interface Props {
    authProps: AuthProps;
    setType: React.Dispatch<SetStateAction<string>>;
    setTypeError: React.Dispatch<SetStateAction<string>>;
    typeError: string;
    setCategory: React.Dispatch<SetStateAction<string>>;
    setCategoryError: React.Dispatch<SetStateAction<string>>;
    categoryError: string;
    setExplanation: React.Dispatch<SetStateAction<string>>;
    explanation: string;
    setExplanationError: React.Dispatch<SetStateAction<string>>;
    explanationError: string;
    setRepaymentPeriod: React.Dispatch<SetStateAction<number>>;
    repaymentPeriod: number;
    setRepaymentPeriodError: React.Dispatch<SetStateAction<string>>;
    repaymentPeriodError: string;
    category: string;
    type: string;
};

const Step3: FC<Props> = ({ authProps, setType, setTypeError, typeError, setCategory, 
    setCategoryError, categoryError, setExplanation, explanation, setExplanationError,
    explanationError, setRepaymentPeriod, repaymentPeriod, setRepaymentPeriodError,
    repaymentPeriodError, category, type }) => {

    const dispatch = useAppDispatch();

    const categories = useAppSelector(selectCategories);

    const types = [
        {'id':'6clsf0l8ki', 'name':'Emergency'},
        {'id':'rov58lveei', 'name':'Development'},
    ];

    return (
        <div>

            <label htmlFor="type" className="sr-only">
                Type
            </label>
            <Select
                id="type"
                placeHolder="Select Loan Type"
                className="flex-grow appearance-none rounded-none w-full relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                selectList={types.map(type => {
                    return { id: type.id, name: type.name }})}
                required={true}
                onChange={e => {
                    setTypeError("");
                    setCategory("");
                    setRepaymentPeriod(0);
                    setType(e.target.value);
                    if (authProps.auth.token.features?.map(a => a.id).includes('m9qsq1kvnb')) {
                        const params: CategoryParams = {
                            authProps: authProps,
                            type: e.target.value,
                        }
                        dispatch(fetchCategoriesByType(params));
                    }
                }}
                autoFocus />
                <span
                    className="text-xs text-red-700"
                    style={{ display: typeError ? "block" : "none" }}>
                    {typeError}
                </span>

            <label htmlFor="category" className="sr-only">
                Category
            </label>
            <Select
                id="category"
                disabled={type === ""}
                placeHolder="Select Category"
                className="flex-grow appearance-none rounded-none w-full relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                selectList={categories.map(category => {
                    return { id: category.name, name: category.name }})}
                required={true}
                onChange={e => {
                    setCategoryError("");
                    setCategory(e.target.value);
                }} />
                <span
                    className="text-xs text-red-700"
                    style={{ display: categoryError ? "block" : "none" }}>
                    {categoryError}
                </span>

            {category && category === 'Other' &&
                <React.Fragment>
                    <label htmlFor="explanation" className="sr-only">
                        Explanation
                        </label>
                        <textarea
                            id="explanation"
                            name="explanation"
                            value={explanation}
                            maxLength={100}
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Explain purpose of loan (max 100)"
                            onChange={e => {
                                setExplanationError("");
                                setExplanation(e.target.value);
                            }}
                        />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: explanationError ? "block" : "none" }}>
                        {explanationError}
                    </span>
                </React.Fragment>}
            
            <RepaymentPeriod
                setRepaymentPeriod={setRepaymentPeriod}
                repaymentPeriod={repaymentPeriod}
                setRepaymentPeriodError={setRepaymentPeriodError}
                repaymentPeriodError={repaymentPeriodError}
                type={type} />
                
        </div>
    );
};

export default Step3;