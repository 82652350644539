import React, { FC } from 'react';
import AuthProps from '../common/AuthProps';
import dashboardIcon from '../../assets/medium-dashboard2.jpg';


const Overview:FC<AuthProps> = (authProps) => {


    return (
        <>
            <img
                className='object-cover cursor-pointer rounded-sm'
                src={dashboardIcon}
                alt='' />
        </>
    );
};

export default Overview;