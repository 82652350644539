import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthProps from '../common/AuthProps';
import { grayedOutContentClass, enabledContentClass } from '../../shared/Utils';
import useToast from '../../hooks/useToast';
import Dashboard from './Dashboard';
import { useQuery } from "react-query";
import { RefreshIcon } from '@heroicons/react/solid';

type ContentHeading = {
    id: string;
    heading: string;
    priority: any;
    contentBodies?: ContentBody[];
    contentActions?: ContentAction[];
}

type ContentBody = {
    id: string;
    contentHeadingId: string;
    body: string;
    priority: any;
}

type ContentAction = {
    id: string;
    contentHeadingId: string;
    action: string;
    priority: any;
    disabled: boolean;
    path: string;
}

const LandingPage: FC<AuthProps> = (authProps) => {

    const navigate = useNavigate();
    const toast = useToast(5000);

    const [areaCode, setAreaCode] = useState<string>("+254");
    const areaCodes = [{'id':'KE', 'name':'+254'}];

    useEffect(() => {
        const controller = new AbortController();
        window.scrollTo(0, 0);
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function fetchContent() {
        const response = await fetch(authProps.auth.token ? 
                authProps.config.CONTENT_PRIVATE_HEADER_URL :
                authProps.config.CONTENT_PUBLIC_HEADER_URL, {
            method: "GET",
            mode: "cors",
            headers: {
                ...authProps.config.baseHeaderOnToken(authProps.auth.token),
            },
        });
        if (response.status !== 200) {
            toast('failure', 'failed to fetch content');
            throw new Error('failed to fetch content');
        }
        return response.json();
    }

    const { isLoading, isError, data, error } = useQuery(['content'], fetchContent);

    return (
        <>
            <div className="w-full z-50 overflow-y-auto flex-col">
                {isLoading && <RefreshIcon className="w-10 h-5 animate-spin mx-auto"></RefreshIcon>}
                
                <div>
                    {!authProps.auth.token && 
                    <h1 className="text-center text-3xl font-base text-blue-900 p-2">
                        Sasco Sacco Savings & Credit Society Limited
                    </h1>}
                    <div className="space-y-1">
                        {data && data.map((content: ContentHeading, index: number) => {
                            return (
                                <React.Fragment key={index}>
                                    <h2 className="text-start text-2xl font-normal text-gray-700 border-2 rounded-sm pl-2 pt-2">
                                        {content.heading}
                                    </h2>
                                    {content.contentBodies &&
                                        <div className="space-y-2 pt-1 mx-6">
                                            <h4 className="text-start text-base font-normal pb-2">
                                                {content.contentBodies.map(body => {
                                                    return (
                                                        <div key={body.id}>
                                                            {body.body}
                                                        </div>
                                                    )
                                                })}
                                            </h4>
                                        </div>}
                                    {content.contentActions &&
                                        <div className="flex flex-col space-y-1 pb-5 mx-6">
                                            {content.contentActions.map(action => {
                                                return (
                                                    <button key={action.id}
                                                        type="button"
                                                        disabled={action.disabled}
                                                        className={action.disabled ? grayedOutContentClass : enabledContentClass}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            navigate(action.path);
                                                        }}>
                                                        {action.action}
                                                    </button>
                                                )
                                            })}
                                        </div>}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );

}

export default LandingPage;