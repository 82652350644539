import { useState } from "react";

export default function useSessionToken() {

    const getSessionToken = () => {
        const sessionTokenResponse = localStorage.getItem(
            "sessionTokenResponse");
        const userInfo = sessionTokenResponse ?
            JSON.parse(sessionTokenResponse) : "";
        return userInfo;
    };

    const [sessionToken, setSessionToken] = useState(getSessionToken());
    const saveSessionToken = (sessionTokenResponse: string) => {
        localStorage.setItem("sessionTokenResponse",
            JSON.stringify(sessionTokenResponse));
        setSessionToken(sessionTokenResponse);
    };
    return { setSessionToken: saveSessionToken, sessionToken };
}