import React, { FC, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AuthProps from '../common/AuthProps';
import homePageIcon from '../../assets/menu/home-svgrepo-com.svg';
import dashboardIcon from '../../assets/menu/dashboard-svgrepo-com.svg';
import accountIcon from '../../assets/menu/account-svgrepo-com.svg';
import bankIcon from '../../assets/menu/bank-svgrepo-com-2.svg';
import loansIcon from '../../assets/menu/loan-svgrepo-com.svg';
import guarantorshipIcon from '../../assets/menu/guarantor-svgrepo-com.svg';
import savingsIcon from '../../assets/menu/savings-svgrepo-com.svg';
import deductionsIcon from '../../assets/menu/bills-svgrepo-com.svg';
import dividendsIcon from '../../assets/menu/dividend-cap-gains-svgrepo-com.svg';
import sascoSaccoInfoIcon from '../../assets/menu/information-1446-svgrepo-com.svg';
import reportsIcon from '../../assets/menu/reports-svgrepo-com.svg';

import { enabledButtonClass } from '../../shared/Utils';

const Sidebar: FC<AuthProps> = (authProps) => {

	const navigate = useNavigate();

	const [currentSelected, setCurrentSelected] = useState<number>(0);
	const onChangeCurrentSelected = (e: React.MouseEvent<HTMLButtonElement>, 
		index: number, url: string) => {
		setCurrentSelected(index);
		navigate(url);
	}

	const localIcon = (id: string) => {
		switch (id) {
			case "a9z2rfkncx":
				return homePageIcon;
			case "dgm6tj74wz":
				return dashboardIcon;
			case "u3szck87br":
				return accountIcon;
			case "qx5v9embj2":
				return bankIcon;
			case "qs54wejgpz":
				return loansIcon;
			case "ke5ycp7ram":
				return guarantorshipIcon;
			case "g87cvqf9b4":
				return savingsIcon;
			case "vexunap6jz":
				return deductionsIcon;
			case "wsy5q2cup4":
				return dividendsIcon;
			case "f8m5cw2xsz":
				return sascoSaccoInfoIcon;
			case "y3sx6nqbkc":
				return reportsIcon;
			default:
				return "";
		}
	}

	return (
		<div>
			<nav className="bg-[#edf5d6]">
				<React.Fragment>
					{authProps.auth.token.userMenu?.map((menu, index) => {
						const { id, url, feature } = menu;
						return (
							<button
								key={id}
								onClick={(e) => onChangeCurrentSelected(e, index, url)}
								className={`capitalize flex items-center align-bottom px-1 py-1 w-48
								${
									index === currentSelected
										? 'bg-[#b7e8fb] text-white'
										: null
								} ${
									index >= 0
										? 'mt-2 text-blue-900 hover:bg-[#b7e8fb] hover:text-gray-700 transition-colors duration-200 transform'
										: null
								} rounded-sm`}>
								<div className="flex items-left justify-between ml-2 w-full shadow-sm">
									<span className="flex flex-row items-center text-xs font-normal mx-1 mt-1">
										<img
											className='h-6 w-6 mr-2'
											src={localIcon(id)}
											alt='' />
										{feature}
									</span>
								</div>
							</button>
						)
					})}

					<div className="text-xs font-light text-center text-wrap pt-3">
						<h2>
							&copy; Sasco Sacco 2014-2024
						</h2>
					</div>
					{Notification.permission !== "granted" && 
						<div className="flex items-center justify-center p-2">
							<button className={enabledButtonClass}
								onClick={() => {
									Notification.requestPermission();
								}}>
								Show notifications
							</button>
						</div>}
				</React.Fragment>
			</nav>
		</div>
	);
};

export default Sidebar;