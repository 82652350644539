import React, { FC, SetStateAction } from 'react';
import validator from 'validator';

interface Props {
    setNationalId: React.Dispatch<SetStateAction<string>>;
    nationalId: string;
    setNationalIdError: React.Dispatch<SetStateAction<string>>;
    nationalIdError: string;
}

const NationalId: FC<Props> = ({ setNationalId, nationalId,
    setNationalIdError, nationalIdError }) => {

    return (
        <div className="grid grid-cols-3 w-full mb-1">
            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="nationalId">
                National Id:
            </label>

            <div className="col-span-2">
                <input
                    id="nationalId"
                    name="nationalId"
                    value={nationalId}
                    type="text"
                    maxLength={8}
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    onChange={(e) => {
                        setNationalIdError("");
                        if (validator.isInt(e.target.value)) {
                            setNationalId(e.target.value);
                        } else {
                            setNationalId("");
                        }
                    }}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: nationalIdError ? "block" : "none" }}>
                    {nationalIdError}
                </span>
            </div>
        </div>
    );

};

export default NationalId;

