import { FC, ReactElement, PropsWithChildren } from "react";

interface ButtonProps {
  id: string;
  hasPopup: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  color: any;
  text: string | ReactElement;
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({ id, hasPopup, onClick, color, text }) => {
  let buttonClass =
    " group flex items-center rounded-md font-medium text-sm px-3 py-2";
  let classColor =
    color === "green"
      ? "hover:bg-green-200 hover:text-green-700 bg-green-500 text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
      : "hover:bg-red-200 hover:text-red-700 bg-red-500 text-red-100 focus:outline-none";
  classColor = classColor + buttonClass;
  return (
    <button
      type="button"
      className={classColor}
      onClick={onClick}
      id={id}
      aria-haspopup={hasPopup ? "true" : "false"}
    >
      {text}
    </button>
  );
};

export default Button;
