import React, { FC, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../common/hooks";
import { addPersonalPhone, PersonalPhone, PersonalPhoneParams, 
    selectPersonalPhoneError, clearError } from "../../store/personalPhoneSlice";
import AuthProps from "../common/AuthProps";
import Select from "../common/Select";
import { grayedOutButtonClass, enabledButtonClass } from "../../shared/Utils";
import useToast from "../../hooks/useToast";

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    personalPhone?: PersonalPhone | undefined;
}

const AddPersonalPhone: FC<Props> = ({ authProps, 
    setShowAddEdit, personalPhone }) => {

    const toast = useToast();

    const [areaCode, setAreaCode] = useState<string>("KE");
    const [phoneNumber, setPhoneNumber] = useState<string>("");

    const areaCodes = [{'id':'KE', 'name':'+254'}];

    const dispatch = useAppDispatch();
    const personalPhoneError = useAppSelector(selectPersonalPhoneError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentPersonalPhone: PersonalPhone = {
            memberId: authProps.auth.token.userId,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            role: authProps.auth.token.role,
        }
        const params: PersonalPhoneParams = {
            authProps: authProps,
            personalPhone: currentPersonalPhone,
        }
 
        const result = await dispatch(addPersonalPhone(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', 'Member personalPhone created successfully');
            // clear fields
            setPhoneNumber("");

            setShowAddEdit(false);
        }
    }

    const isInvalid = areaCode === ""
        || phoneNumber === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        Create new personal phone
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: personalPhoneError ? "block" : "none" }}>
                                                {personalPhoneError}
                                            </span>

                                            <div className="flex flex-row relative">
                                                <Select
                                                    id="areaCode"
                                                    placeHolder="+254"
                                                    className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={areaCodes.map(areaCode => {
                                                        return { id: areaCode.id, name: areaCode.name }})}
                                                    required={true}
                                                    onChange={(e) => 
                                                        setAreaCode(e.target.value)} />

                                                <label htmlFor="phoneNumber" className="sr-only">
                                                    PhoneNumber
                                                </label>
                                                <input
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    maxLength={10}
                                                    type="text"
                                                    required
                                                    className="flex-grow appearance-none rounded-none relative block px-3 py-2 border w-0 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Phone Number"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setPhoneNumber(e.target.value)
                                                    }}
                                                    autoFocus />
                                            </div>
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    Add
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddPersonalPhone;
