import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type CalculatorParams = {
    authProps: AuthProps;
    calculator: Calculator;
    calculatorId?: string;
}

export type Schedule = {
    id: string;
    calculatorId: string;
    dueDate: string;
    payment: number;
    interest: number;
    principal: number;
    balance: number;
}

export type Calculator = {
    id: string;
    memberId: string;
    amount: number;
    repaymentPeriod: number;
    firstPaymentDate: string;
    paymentFrequency: string;
    schedule?: Schedule[];
    accountId: string;
}

interface CalculatorState {
    status: "calculate" | "idle" ;
    error: any;
    calculator: Calculator;
}

interface FetchErrors {
    message: string;
}

const initialState: CalculatorState = {
    calculator: null,
    error: null,
    status: "idle",
}

export const calculateSchedule =
    createAsyncThunk<Calculator, CalculatorParams, { rejectValue: FetchErrors }>(
    "amortizationCalculator/calculate",
    async (params: CalculatorParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOAN_AMORTIZATION_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.calculator),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const calculatorSlice = createSlice({
    name: 'calculator',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
        clearCalculator: (state, { payload }) => {
            state.calculator = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(calculateSchedule.pending, (state) => {
            state.status = "calculate";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(calculateSchedule.fulfilled, (state, { payload }) => {
            state.calculator = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(calculateSchedule.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectCalculatorStatus = (state: RootState) => state.calculator.status;
export const selectCalculator = (state: RootState) => state.calculator.calculator;
export const selectSchedule = (state: RootState) => state.calculator.calculator?.schedule;
export const selectCalculatorError = (state: RootState) => state.calculator.error;
export const { clearError, clearCalculator } = calculatorSlice.actions;
export default calculatorSlice.reducer;