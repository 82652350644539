import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MemberIdentityParams = {
    authProps: AuthProps;
    memberIdentity?: MemberIdentity;
}

export type MemberIdentity = {
    memberId: string;
    identityType: string;
    value: string;
    creationDate?: string;
    modifiedDate?: string;
    edit?: void;
    delete?: void;
}

interface MemberIdentityState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: MemberIdentity[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberIdentityState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberIdentitiesById =
    createAsyncThunk<MemberIdentity[], AuthProps, { rejectValue: FetchErrors }>(
    "memberIdentities/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBERIDENTITY_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member identities",
            });
        }
        return response.json();
    }
)

export const addMemberIdentity =
    createAsyncThunk<MemberIdentity, MemberIdentityParams, { rejectValue: FetchErrors }>(
    "memberIdentity/add",
    async (params: MemberIdentityParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERIDENTITY_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberIdentity),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editMemberIdentity =
    createAsyncThunk<MemberIdentity, MemberIdentityParams, { rejectValue: FetchErrors }>(
    "memberIdentity/edit",
    async (params: MemberIdentityParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERIDENTITY_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberIdentity),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMemberIdentity =
    createAsyncThunk<MemberIdentity, MemberIdentityParams, { rejectValue: FetchErrors }>(
    "memberIdentity/delete",
    async (params: MemberIdentityParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MEMBERIDENTITY_URL, {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberIdentity),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberIdentitySlice = createSlice({
    name: 'memberIdentity',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberIdentitiesById.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMemberIdentity.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editMemberIdentity.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMemberIdentity.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberIdentitiesById.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addMemberIdentity.fulfilled, (state, { payload }) => {
            state.list.unshift(payload);
            state.status = "idle";
        });
        builder.addCase(editMemberIdentity.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, identityType }) => memberId === payload.memberId
                && identityType === payload.identityType);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMemberIdentity.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ memberId, identityType }) => memberId === payload.memberId
            && identityType === payload.identityType);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberIdentitiesById.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMemberIdentity.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMemberIdentity.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMemberIdentity.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberIdentityStatus = (state: RootState) => state.memberIdentities.status;
export const selectMemberIdentities = (state: RootState) => state.memberIdentities.list;
export const selectMemberIdentityCount = (state: RootState) => state.memberIdentities.list.length;
export const selectMemberIdentityError = (state: RootState) => state.memberIdentities.error;
export const { clearError, clearList } = memberIdentitySlice.actions;
export default memberIdentitySlice.reducer;