import Dialog from "./Dialog";
import Button from "./Button";

/*
    courtesy: Andrew Bliss: https://javascript.plainenglish.io/creating-a-confirm-dialog-in-react-and-tailwind-css-3b99f6e56fab
*/

interface Props {
    title: string;
    children: React.ReactNode;
    open: boolean;
    onClose: Function;
    onConfirm: Function; 
}
  
export default function Confirm(props: Props) {
    const { open, onClose, title, children, onConfirm } = props;

    if (!open) {
        return <></>;
    }

    return (
        <Dialog open={open} 
            onClose={onClose}>
            <h2 className="text-base">{title}</h2>
            <div className="py-2">{children}</div>
            <div className="flex justify-end">
                <div className="p-1">
                    <Button
                        onClick={() => onClose()}
                        className="bg-blue-500 hover:bg-slate-600">
                        Cancel
                    </Button>
                </div>
                <div className="p-1">
                    <Button
                        onClick={() => {
                            onClose();
                            onConfirm();
                        }}
                        className="bg-red-600 hover:bg-slate-600">
                        Yes
                    </Button>
                </div>
            </div>
        </Dialog>
    );

};