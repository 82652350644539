import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type DeductionParams = {
    authProps: AuthProps;
    monthlyDeduction?: MonthlyDeduction;
}

export type MonthlyDeduction = {
    memberId: string;
    deduction: number;
    commencementDate: string;
    modifiedDate?: string;
}

interface MonthlyDeductionState {
    status: "loading" | "idle" | "editing";
    error: any;
    monthlyDeduction: MonthlyDeduction;
}

interface FetchErrors {
    message: string;
}

const initialState: MonthlyDeductionState = {
    monthlyDeduction: null,
    error: null,
    status: "idle",
}

export const fetchMonthlyDeduction =
    createAsyncThunk<MonthlyDeduction, AuthProps, { rejectValue: FetchErrors }>(
    "deduction/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MONTHLY_DEDUCTION_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member monthly deduction",
            });
        }
        return response.json();
    }
);

export const editDeduction =
    createAsyncThunk<MonthlyDeduction, DeductionParams, { rejectValue: FetchErrors }>(
    "member/edit",
    async (params: DeductionParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MONTHLY_DEDUCTION_URL, {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.monthlyDeduction),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const monthlyDeductionSlice = createSlice({
    name: 'monthlyDeduction',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMonthlyDeduction.pending, (state) => {
            state.monthlyDeduction = null;
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(editDeduction.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMonthlyDeduction.fulfilled, (state, { payload }) => {
            state.monthlyDeduction = null;
            state.monthlyDeduction = payload;
            state.status = "idle";
        });
        builder.addCase(editDeduction.fulfilled, (state, { payload }) => {
            state.monthlyDeduction = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMonthlyDeduction.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editDeduction.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMonthlyDeductionStatus = (state: RootState) => state.monthlyDeduction.status;
export const selectMonthlyDeduction = (state: RootState) => state.monthlyDeduction.monthlyDeduction;
export const selectMonthlyDeductionError = (state: RootState) => state.monthlyDeduction.error;
export const { clearError } = monthlyDeductionSlice.actions;
export default monthlyDeductionSlice.reducer;
