import React, { FC, useEffect, useMemo, useCallback } from 'react'
import { Schedule } from '../../store/loanSlice';
import AuthProps from '../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../common/hooks';
import { Column } from 'react-table';
import { WrappedTable } from '../common/Table/WrappedTable';
import { ccyFormat } from '../../shared/Utils';
import { Guarantor, fetchMyIssuedGuarantees, selectGuarantorError, 
    selectGuarantorStatus, selectGuarantors } from '../../store/issuedGuaranteeSlice';

const MyIssugedGuarantees: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const guarantors = useAppSelector(selectGuarantors);
    const guarantorError = useAppSelector(selectGuarantorError);
    const status = useAppSelector(selectGuarantorStatus);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('1kcuiehrte')) {
            dispatch(fetchMyIssuedGuarantees(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const totalAmount: any = (items: Guarantor[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const totalPayment: any = (items: Schedule[]) => {
        return items.map( ({ payment }) => Number(payment))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const totalInterest: any = (items: Schedule[]) => {
        return items.map( ({ interest }) => Number(interest))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<Guarantor>[] = useMemo(() => [
        {
            Header: "Loan ID",
            accessor: loanId => { return ("L".concat(loanId.loan.id.toUpperCase())) },
        },
        {
            Header: "Guarantor",
            accessor: guarantor => { return(`${guarantor.guarantor.firstName} ${guarantor.guarantor.middleName} ${guarantor.guarantor.surname}`) },
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.amount))}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalAmount(columnProps.data))}
                </div>
            ),
        },
        {
            Header: "Status",
            accessor: "guarantorStatus"
        },
        {
            Header: "Reason",
            accessor: "reason"
        },
        {
            Header: "Loan Applied",
            accessor: "creationDate",
        },
        {
            Header: () => null,
            accessor: "loan",
            width: 10,
            Cell: ({ row }) => (
                row.original?.loan.schedule &&
                    row.original.loan.schedule.length > 0 ? (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            className="flex flex-row">   
                            {row.isExpanded ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                                </svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>}
                        </span>) : (<div></div>)
            ),
        },
        {
            Header: "Type",
            accessor: type => { return (type.loan.type) }
        },
        {
            Header: "Category",
            accessor: category => { return (`${category.loan.category} ${category.loan.explanation ? category.loan.explanation : ""}`)}
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Loan Amount
                </div>
            ),
            accessor: "loanAmount",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.loan.amount))}
                        </div>
                    </div>
                );
            },
        },
        {
            Header: "Months",
            accessor: repayment => { return(repayment.loan.repaymentPeriod) },
        },
        {
            Header: "Status",
            accessor: loanStatus => { return (loanStatus.loan.loanStatus) }
        },
        {
            Header: "Reason",
            accessor: statusReason => { return (statusReason.loan.reason) }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[guarantors]);

    const columnsSm: Column<Guarantor>[] = useMemo(() => [
        {
            Header: "Applied",
            accessor: "creationDate"
        },
        {
            Header: () => null,
            accessor: "loan",
            width: 30,
            Cell: ({ row }) => (
                row.original?.loan.schedule &&
                    row.original.loan.schedule.length > 0 ? (
                        <span
                            {...row.getToggleRowExpandedProps()}
                            className="flex flex-row">   
                            {row.isExpanded ? 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                                </svg> : 
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>}
                        </span>) : (<div></div>)
            ),
        },
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[guarantors]);

    const schedule: Column<Schedule>[] = useMemo(() => [
        {
            Header: "Due",
            accessor: "dueDate"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Payment
                </div>
            ),
            accessor: "payment",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        {ccyFormat(row.original.payment)}
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalPayment(columnProps.data))}
                </div>
            ),
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Interest
                </div>
            ),
            accessor: "interest",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                            {ccyFormat(row.original.interest)}
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalInterest(columnProps.data))}
                </div>
            ),
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Principal
                </div>
            ),
            accessor: "principal",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                            {ccyFormat(row.original.principal)}
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Balance
                </div>
            ),
            accessor: "balance",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                            {ccyFormat(row.original.balance)}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    const subTable: any = useCallback(
        ({ row }) =>
            row.original.loan.schedule.length > 0 ?
                (
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<Schedule>
                                name=""
                                columns={schedule}
                                data={row.original.loan.schedule}
                                addonHooks={[]}
                                includeFooter />
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<Schedule>
                                name=""
                                columns={schedule}
                                data={row.original.loan.schedule}
                                addonHooks={[]}
                                includeFooter />
                        </div>
                    </React.Fragment>
                ) : (<div></div>),
            [schedule]
    );

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
			<React.Fragment>
                {guarantorError &&
                <span
                    className="text-red-500"
                    style={{ display: guarantorError ? "block" : "none" }}>
                    {guarantorError}
                </span>}
				<div>
                    {guarantors &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<Guarantor>
                                    name="Guarantors"
                                    columns={columns}
                                    data={guarantors}
                                    addonHooks={[]}
                                    includeFooter
                                    renderRowSubComponent={subTable} />
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<Guarantor>
                                    name="Guarantors"
                                    columns={columnsSm}
                                    data={guarantors}
                                    addonHooks={[]}
                                    includeFooter
                                    renderRowSubComponent={subTable} />
                            </div>
                        </React.Fragment>}
				</div>
			</React.Fragment>
        );
};

export default MyIssugedGuarantees;