import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { selectBeneficiaryStatus, selectBeneficiaryError,
    selectBeneficiaries, fetchBeneficiariesById,
    clearError, BeneficiaryParams, Beneficiary,
    deleteBeneficiary} from '../../store/beneficiarySlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { WrappedTable } from "../common/Table/WrappedTable";
import { Column } from 'react-table';
import AddEditBeneficiary from './AddEditBeneficiary';
import { enabledButtonClass } from '../../shared/Utils';
import AddEditGuardian from './AddEditGuardian';
import { Guardian } from '../../store/guardianSlice';
import { ccyFormatZero } from '../../shared/Utils';

const BeneficiaryPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectBeneficiaryStatus);
    const beneficiaries = useAppSelector(selectBeneficiaries);
    const beneficiaryError = useAppSelector(selectBeneficiaryError);

    const [beneficiary, setBeneficiary] = useState<Beneficiary>();
    const [beneficiaryId, setBeneficiaryId] = useState<string>("");
    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [showAddEditGuardian, setShowAddEditGuardian] = useState<boolean>(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchBeneficiariesById(authProps))
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setBeneficiary(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, editBeneficiary: Beneficiary) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setBeneficiary(editBeneficiary);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delBeneficiary: Beneficiary) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setBeneficiary(delBeneficiary);
    	const params: BeneficiaryParams = {
        	authProps: authProps,
        	beneficiary: delBeneficiary,
      	}
    	dispatch(deleteBeneficiary(params))
 	};

    const columns: Column<Beneficiary>[] = useMemo(() => [
        {
            Header: () => null,
            accessor: "beneficiaryId",
            width: 30,
            Cell: ({ row }) => (
                row.original.guardians?.length > 0 ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                            </svg> : 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>}
                    </span>) : (<div></div>)
            )
        },
        {
            Header: "Names",
            accessor: names => {return `${names.beneficiary.firstName} ${names.beneficiary?.middleName} ${names.beneficiary.surname}`}
        },
        {
            Header: "Gender",
            accessor: gender => {return gender.beneficiary.gender}
        },
        {
            Header: "DOB",
            accessor: dob => {return dob.beneficiary.dateOfBirth}
        },
        {
            Header: "Marital Status",
            accessor: ms => {return ms.beneficiary.maritalStatus}
        },
        {
            Header: "Relationship",
            accessor: "relationship"
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    % Shares
                </div>
            ),
            accessor: "percentShare",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        {ccyFormatZero(row.original.percentShare)}
                    </div>
                );
            }
        },
        {
            Header: "",
            accessor: "memberId",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        {authProps.auth.token.features?.map(a => a.id).includes('krh845n7av') &&
                            <button
                                className={enabledButtonClass}
                                onClick={(e) => {
                                    setBeneficiaryId(row.original.beneficiaryId);
                                    setShowAddEditGuardian(true);
                                }}>
                                Add Guardian
                            </button>}
                    </div>
                );
            }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('u5ke3u846k') &&
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                    {authProps.auth.token.features?.map(a => a.id).includes('8l3efq2ccr') &&
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[beneficiary]);

    const columnsSm: Column<Beneficiary>[] = useMemo(() => [
        {
            Header: () => null,
            accessor: "beneficiaryId",
            width: 30,
            Cell: ({ row }) => (
                row.original.guardians?.length > 0 ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ? 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                            </svg> : 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>}
                    </span>) : (<div></div>)
            )
        },
        {
            Header: "Beneficiary",
            accessor: "beneficiary",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {`${row.original.beneficiary.firstName} ${row.original.beneficiary.middleName} ${row.original.beneficiary.surname}
                                    ${row.original.beneficiary.gender} ${row.original.beneficiary.dateOfBirth} ${row.original.beneficiary.maritalStatus}
                                     ${row.original.relationship} ${ccyFormatZero(row.original.percentShare)}`}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('u5ke3u846k') &&
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                    {authProps.auth.token.features?.map(a => a.id).includes('8l3efq2ccr') &&
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[beneficiaries]);

    const guardians: Column<Guardian>[] = useMemo(() => [
        {
            Header: "Guardian",
            accessor: "memberId",
            Cell: ({ row }) => {
                return (
                    <div>
                        {`${row.original.guardian?.firstName} ${row.original.guardian?.middleName} ${row.original.guardian?.surname}`}
                    </div>
                );
            },
        },
        {
            Header: "Relationship",
            accessor: "beneficiaryId",
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.relationship}
                    </div>
                );
            },
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]);

    const subTable: any = useCallback(
        ({ row }) =>
            row.original.guardians.length > 0 ?
                (<WrappedTable<Guardian>
                    name=""
                    columns={guardians}
                    data={row.original.guardians}
                    addonHooks={[]} />) :
                (<div></div>),
            [guardians]
    );

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {beneficiaryError &&
                    <span
                        className="text-red-500"
                        style={{ display: beneficiaryError ? "block" : "none" }}>
                        {beneficiaryError}
                    </span>}
                    <div>
                        {beneficiaries &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<Beneficiary>
                                        name=""
                                        columns={columns}
                                        data={beneficiaries}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        renderRowSubComponent={subTable}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('hqreeuvb3q')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<Beneficiary>
                                        name=""
                                        columns={columnsSm}
                                        data={beneficiaries}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        renderRowSubComponent={subTable}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('hqreeuvb3q')}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && <AddEditBeneficiary 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit}
                                beneficiary={beneficiary} />}
                    </div>
                    <div>
                        {showAddEditGuardian
                            && authProps.auth.token.features?.map(a => a.id).includes('krh845n7av')
                            && <AddEditGuardian 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEditGuardian}
                                beneficiaryId={beneficiaryId} />}
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default BeneficiaryPage;