import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MemberRegistrationStatus = {
    memberId: string;
    registrationStatus: string;
    modifiedDate: string;
}

interface MemberRegistrationState {
    status: "loading" | "idle";
    error: any;
    memberRegistrationStatus: MemberRegistrationStatus;
}

interface FetchErrors {
    message: string;
}

const initialState: MemberRegistrationState = {
    memberRegistrationStatus: null,
    error: null,
    status: "idle",
}

export const fetchMemberRegistrationStatus =
    createAsyncThunk<MemberRegistrationStatus, AuthProps, { rejectValue: FetchErrors }>(
    "registrationStatus/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.REGISTRATIONSTATUS_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member registration status",
            });
        }
        return response.json();
    }
)

export const memberRegistrationStatusSlice = createSlice({
    name: 'memberRegistrationStatus',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberRegistrationStatus.pending, (state) => {
            state.memberRegistrationStatus = null;
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberRegistrationStatus.fulfilled, (state, { payload }) => {
            state.memberRegistrationStatus = null;
            state.memberRegistrationStatus = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberRegistrationStatus.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberRegistrationStatusStatus = (state: RootState) => state.memberRegistrationStatus.status;
export const selectMemberRegistrationStatus = (state: RootState) => state.memberRegistrationStatus.memberRegistrationStatus;
export const selectMemberRegistrationStatusError = (state: RootState) => state.memberRegistrationStatus.error;
export const { clearError } = memberRegistrationStatusSlice.actions;
export default memberRegistrationStatusSlice.reducer;
