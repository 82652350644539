import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MobileMoneyProvider = {
    mobileMoneyProvider: string;
}

interface MobileMoneyProviderState {
    status: "loading" | "idle" ;
    error: any;
    list: MobileMoneyProvider[];
}

interface FetchErrors {
    message: string;
}

const initialState: MobileMoneyProviderState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMobileMoneyProviders = 
    createAsyncThunk<MobileMoneyProvider[], AuthProps, { rejectValue: FetchErrors }>(
    "mobileMoneyProviders/fetchAll",
    async (params: AuthProps, thunkApi) => {
        const response = 
            await fetch(params.config.PUBLIC_MONEY_URL +
                "/providers", {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.defaultHeaders(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch mobile money providers",
            });
        }
        return response.json();
    }
);



export const mobileMoneyProviderSlice = createSlice({
    name: 'mobileMoneyProvider',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMobileMoneyProviders.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMobileMoneyProviders.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMobileMoneyProviders.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMobileMoneyProviderStatus = (state: RootState) => state.mobileMoneyProviders.status;
export const selectMobileMoneyProviders = (state: RootState) => state.mobileMoneyProviders.list;
export const selectMobileMoneyProviderError = (state: RootState) => state.mobileMoneyProviders.error;
export const { clearList } = mobileMoneyProviderSlice.actions;
export default mobileMoneyProviderSlice.reducer;