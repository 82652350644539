        import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MemberMobileMoneyParams = {
    authProps: AuthProps;
    memberMobileMoney?: MemberMobileMoney;
    id?: string;
}

export type MemberMobileMoney = {
    id: string;
    memberId: string;
    mobileMoneyProvider: string;
    areaCode: string;
    phoneNumber: string;
    edit?: void;
    delete?: void;
}

interface MemberMobileMoneyState {
    status: "loading" | "idle" | "adding" | "editing" | "deleting";
    error: any;
    list: MemberMobileMoney[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberMobileMoneyState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberMobileMoney = 
    createAsyncThunk<MemberMobileMoney[], AuthProps, { rejectValue: FetchErrors }>(
    "memberMobileMoney/fetchByMember",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MOBILE_MONEY_URL +
                "/member/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch mobile money account(s)",
            });
        }
        return response.json();
    }
);

export const addMemberMobileMoney =
    createAsyncThunk<MemberMobileMoney, MemberMobileMoneyParams, { rejectValue: FetchErrors }>(
    "memberMobileMoney/add",
    async (params: MemberMobileMoneyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MOBILE_MONEY_URL +
                "/member", {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberMobileMoney),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const editMemberMobileMoney =
    createAsyncThunk<MemberMobileMoney, MemberMobileMoneyParams, { rejectValue: FetchErrors }>(
    "memberMobileMoney/edit",
    async (params: MemberMobileMoneyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MOBILE_MONEY_URL +
                "/member", {
                method: "PATCH",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberMobileMoney),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const deleteMemberMobileMoney =
    createAsyncThunk<MemberMobileMoney, MemberMobileMoneyParams, { rejectValue: FetchErrors }>(
    "memberMobileMoney/delete",
    async (params: MemberMobileMoneyParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.MOBILE_MONEY_URL +
                "/member", {
                method: "DELETE",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.memberMobileMoney),
            });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const memberMobileMoneySlice = createSlice({
    name: 'memberMobileMoney',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberMobileMoney.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(addMemberMobileMoney.pending, (state) => {
            state.status = "adding";
            state.error = null;
        });
        builder.addCase(editMemberMobileMoney.pending, (state) => {
            state.status = "editing";
            state.error = null;
        });
        builder.addCase(deleteMemberMobileMoney.pending, (state) => {
            state.status = "deleting";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberMobileMoney.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(addMemberMobileMoney.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(editMemberMobileMoney.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list[index] = payload;
            state.status = "idle";
        });
        builder.addCase(deleteMemberMobileMoney.fulfilled, (state, { payload }) => {
            var index = state.list.findIndex( ({ id }) => id === payload.id);
            state.list = [...state.list.slice(0, index),
                    ...state.list.slice(index + 1)];
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberMobileMoney.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(addMemberMobileMoney.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(editMemberMobileMoney.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(deleteMemberMobileMoney.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberMobileMoneyStatus = (state: RootState) => state.memberMobileMoney.status;
export const selectMemberMobileMoney = (state: RootState) => state.memberMobileMoney.list;
export const selectMemberMobileMoneyError = (state: RootState) => state.memberMobileMoney.error;
export const { clearList, clearError } = memberMobileMoneySlice.actions;
export default memberMobileMoneySlice.reducer;