import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Loan } from './loanSlice';

export type LoanParams = {
    authProps: AuthProps;
    loan?: Loan;
}

interface LoanState {
    status: "loading" | "idle" | "applying";
    error: string | null;
    loan: Loan;
}

interface FetchErrors {
    message: string;
}

const initialState: LoanState = {
    loan: null,
    error: null,
    status: "idle",
}

export const application =
    createAsyncThunk<Loan, LoanParams, { rejectValue: FetchErrors }>(
    "loan/loanApplication",
    async (params: LoanParams, thunkApi) => {
        if (params.authProps.config.tokenExpired()) {
            await params.authProps.auth.refreshToken();
        }
        const response =
            await fetch(params.authProps.config.LOAN_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                    ...params.authProps.config.headersWithAuthorization(),
                },
                body: JSON.stringify(params.loan),
            });
        if (response.status !== 201) {
            return thunkApi.rejectWithValue({
                message: (await response.json()).message,
            });
        }
        return response.json();
    }
);

export const loanApplicationSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(application.pending, (state) => {
            state.status = "applying";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(application.fulfilled, (state, { payload }) => {
            state.loan = payload;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(application.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectLoanStatus = (state: RootState) => state.loanApplication.status;
export const selectLoan = (state: RootState) => state.loanApplication;
export const selectLoanError = (state: RootState) => state.loanApplication.error;
export const { clearError } = loanApplicationSlice.actions;
export default loanApplicationSlice.reducer;