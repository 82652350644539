import React, { FC, useState, useMemo, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch } from '../../common/hooks';
import { Guarantor, deleteVirtual } from '../../../store/loanGuarantorSlice';
import { Column } from 'react-table';
import { ccyFormat } from '../../../shared/Utils';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditVirtualGuarantor from './AddVirtualGuarantor';

interface Props {
    authProps: AuthProps;
    guarantors: Guarantor[]; 
    setGuarantorsError: React.Dispatch<SetStateAction<string>>;
    guarantorsError: string;
    loanAmount: string;
};

const Step5: FC<Props> = ({ authProps, guarantors, setGuarantorsError,
    guarantorsError, loanAmount }) => {

    const dispatch = useAppDispatch();

    const [guarantor, setGuarantor] = useState<Guarantor>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        setGuarantorsError("");
        setShowAddEdit(true);
        if (!showAddEdit) {
            setGuarantor(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, editGurantor: Guarantor) => {
    	e.preventDefault();
    	setGuarantor(editGurantor);
    	setShowAddEdit(true);
  	};

    const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delGuarantor: Guarantor) => {
		e.preventDefault();
    	setGuarantor(delGuarantor);
        dispatch(deleteVirtual(delGuarantor));
 	};

    const totalCharged: any = (items: Guarantor[]) => {
        return items.map( ({ amount }) => Number(amount))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<Guarantor>[] = useMemo(() => [
        {
            Header: "Name",
            accessor: names => {return `${names.guarantor.firstName} ${names.guarantor.middleName} ${names.guarantor.surname}`}
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Amount
                </div>
            ),
            accessor: "guarantor",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-bold">
                            {ccyFormat(Number(row.original.amount))}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalCharged(columnProps.data))}
                </div>
            ),
        },
        {
            Header: "",
            accessor: "edit",
            Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                     </div>
              );
          }

        },
        {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[guarantor]);

    const columnsSm: Column<Guarantor>[] = useMemo(() => [
        {
            Header: "Guarantor",
            accessor: "guarantor",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {`${row.original.guarantor.firstName} ${row.original.guarantor.middleName} ${row.original.guarantor.surname} ${ccyFormat(row.original.amount)}`}
                            </div>
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    {ccyFormat(totalCharged(columnProps.data))}
                </div>
            ),
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
                return (
                    <div className="items-center margin: auto">
                        <button
                            disabled
                            onClick={e => inLineEdit(e, row.original)}>
                            <svg xmlns="http://www.w3.org/2000/svg" 
                                className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                    </div>
                );
          }

        },
        {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[guarantor]);

    return (
        <React.Fragment>
            <div className="w-full">
                {guarantors &&
                    <React.Fragment>
                        <div className="hidden md:block">
                            <WrappedTable<Guarantor>
                                name=""
                                columns={columns}
                                data={guarantors}
                                addonHooks={[]}
                                showAddDialog={showAddEditDialog}
                                allowAdd={true}
                                includeFooter
                            /> 
                        </div>
                        <div className="md:hidden">
                            <WrappedTable<Guarantor>
                                name=""
                                columns={columnsSm}
                                data={guarantors}
                                addonHooks={[]}
                                showAddDialog={showAddEditDialog}
                                allowAdd={true}
                                includeFooter
                            /> 
                        </div>
                    </React.Fragment>}
            </div>
            <div>
                {showAddEdit
                    && <AddEditVirtualGuarantor 
                        authProps={authProps} 
                        setShowAddEdit={setShowAddEdit}
                        loanAmount={loanAmount}
                        guarantor={guarantor} />}
            </div>
            <span
                className="text-xs text-red-700"
                style={{ display: guarantorsError ? "block" : "none" }}>
                {guarantorsError}
            </span>
        </React.Fragment>
    );
};

export default Step5;