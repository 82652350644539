import React, { FC, useEffect, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import Select from '../../common/Select';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { fetchEmployers, selectEmployers } from '../../../store/employerSlice';
import { BranchParams, fetchBranchesByEmployer, selectBranches } from '../../../store/branchSlice';
import NationalId from '../../common/NationalId';
import validator from 'validator';

interface Props {
    authProps: AuthProps;
    setNationalId: React.Dispatch<SetStateAction<string>>;
    nationalId: string;
    setNationalIdError: React.Dispatch<SetStateAction<string>>;
    nationalIdError: string;
    setPassportNumber: React.Dispatch<SetStateAction<string>>;
    passportNumber: string;
    setPassportNumberError: React.Dispatch<SetStateAction<string>>;
    passportNumberError: string;
    setKraPIN: React.Dispatch<SetStateAction<string>>;
    kraPIN: string; 
    setKraPINError: React.Dispatch<SetStateAction<string>>;
    kraPINError: string;
    setEmployer: React.Dispatch<SetStateAction<string>>;
    employer: string;
    setEmployerError: React.Dispatch<SetStateAction<string>>;
    employerError: string;
    setBranchId: React.Dispatch<SetStateAction<string>>;
    setBranchIdError: React.Dispatch<SetStateAction<string>>;
    branchIdError: string;
    setMemberType: React.Dispatch<SetStateAction<string>>;
    memberType: string;
    setMemberTypeError: React.Dispatch<SetStateAction<string>>;
    memberTypeError: string;
    setPayrollNumber: React.Dispatch<SetStateAction<string>>;
    payrollNumber: string;
    setPayrollNumberError: React.Dispatch<SetStateAction<string>>;
    payrollNumberError: string;
    setAgentNumber: React.Dispatch<SetStateAction<string>>;
    agentNumber: string;
    setAgentNumberError: React.Dispatch<SetStateAction<string>>;
    agentNumberError: string;
}

const Step2: FC<Props> = ({ authProps, setNationalId, nationalId, setNationalIdError, nationalIdError,
    setPassportNumber, passportNumber, setPassportNumberError, passportNumberError, setKraPIN,
    kraPIN, setKraPINError, kraPINError, setEmployer, employer, setEmployerError, employerError, 
    setMemberType , memberType, setMemberTypeError, memberTypeError, setBranchId, setBranchIdError,
    branchIdError, setPayrollNumber, payrollNumber, setPayrollNumberError, payrollNumberError,
    setAgentNumber, agentNumber, setAgentNumberError, agentNumberError }) => {

    const dispatch = useAppDispatch();

    const employers = useAppSelector(selectEmployers);
    const branches = useAppSelector(selectBranches);

    const memberTypesPru = [
        {'id':'PERMANENT_EMPLOYEE', 'name':'PERMANENT EMPLOYEE'},
        {'id':'AGENT_OF_PRUDENTIAL', 'name':'AGENT OF PRUDENTIAL'},
        {'id':'CONTRACT_EMPLOYEE', 'name':'CONTRACT EMPLOYEE'},
    ];

    const memberTypesNonPru = [
        {'id':'EMPLOYEE_OF_SASCO', 'name':'EMPLOYEE OF SASCO'},
        {'id':'SPOUSE_OF_EMPLOYEE', 'name':'SPOUSE OF EMPLOYEE'},
        {'id':'RELATIVE_OF_EMPLOYEE', 'name':'RELATIVE OF EMPLOYEE'},
        {'id':'FRIEND_OF_EMPLOYEE', 'name':'FRIEND OF EMPLOYEE'},
    ];

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchEmployers(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>

            <div className="flex flex-col p-1 inset border mb-1">

                <NationalId 
                    setNationalId={setNationalId}
                    nationalId={nationalId}
                    setNationalIdError={setNationalIdError}
                    nationalIdError={nationalIdError} />

                <h4 className="flex items-center m-1 text-xs font-bold justify-center">
                    And / Or
                </h4>

                <div className="grid grid-cols-3 w-full">
                    <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="passportNumber">
                        Passport No.:
                    </label>

                    <div className="col-span-2">
                        <input
                            id="passportNumber"
                            name="passportNumber"
                            type="text"
                            maxLength={10}
                            value={passportNumber}
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            onChange={(e) => {
                                setPassportNumberError("");
                                if (validator.isAlpha(e.target.value)) {
                                    setPassportNumber(e.target.value);
                                } else {
                                    setPassportNumber("");
                                }
                            }}
                        />
                        <span
                            className="text-xs text-red-700"
                            style={{ display: passportNumberError ? "block" : "none" }}>
                            {passportNumberError}
                        </span>
                    </div>
                </div>

            </div>

            <div className="grid grid-cols-3 w-full mb-1">
                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="kraPIN">
                    KRA PIN:
                </label>

                <div className="col-span-2">
                    <input
                        id="kraPIN"
                        name="kraPIN"
                        type="text"
                        maxLength={11}
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="optional"
                        onChange={(e) => {
                            if (validator.isAlpha(e.target.value)) {
                                setKraPIN(e.target.value);
                            } else {
                                setKraPIN("");
                            }
                        }}
                    />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: kraPINError ? "block" : "none" }}>
                        {kraPINError}
                    </span>
                </div>
            </div>

            <div className="p-1 inset border">

                <label htmlFor="employer" className="sr-only">
                    Employer
                </label>
                <Select
                    id="employer"
                    placeHolder="Select Employer"
                    className="flex-grow appearance-none rounded-none w-full mb-1 relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    selectList={employers.map(employer => {
                        return { id: employer.id, name: employer.name }})}
                    required={true}
                    onChange={(e) => {
                        setBranchId("");
                        setMemberType("");
                        setPayrollNumber("");
                        setAgentNumber("");
                        setEmployer(e.target.value);
                        setEmployerError("");

                        const branchParams: BranchParams = {
                            authProps: authProps,
                            employer: e.target.value,
                        }
                        if (e.target.value !== "") {
                            dispatch(fetchBranchesByEmployer(branchParams));
                        }
                    }} />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: employerError ? "block" : "none" }}>
                        {employerError}
                    </span>

                <label htmlFor="memberType" className="sr-only">
                    Branch
                </label>
                <Select
                    id="branch"
                    disabled={employer === ""}
                    placeHolder="Select Branch"
                    className="flex-grow appearance-none rounded-none w-full mb-1 relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    selectList={branches.map(branch => {
                        return { id: branch.id, name: `${branch.town}: ${branch.name}` }})}
                    required={true}
                    onChange={(e) => {
                        setBranchIdError("");
                        setBranchId(e.target.value);
                    }} />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: branchIdError ? "block" : "none" }}>
                        {branchIdError}
                    </span>

                <label htmlFor="memberType" className="sr-only">
                    Terms of Service
                </label>
                <Select
                    id="memberType"
                    disabled={employer === ""}
                    placeHolder="Select Terms of Service"
                    className="flex-grow appearance-none rounded-none w-full mb-1 relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    selectList={employer && employer.includes('35o5mjqvf8') ? memberTypesPru.map(memberType => {
                        return { id: memberType.id, name: memberType.name }}) :
                        memberTypesNonPru.map(memberType => {
                            return { id: memberType.id, name: memberType.name }})}
                    required={true}
                    onChange={(e) => {
                        setMemberTypeError("");
                        setPayrollNumber("");
                        setAgentNumber("");
                        setMemberType(e.target.value);
                    }} />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: memberTypeError ? "block" : "none" }}>
                        {memberTypeError}
                    </span>

                {memberType &&
                    (memberType === 'PERMANENT_EMPLOYEE'
                        || memberType === 'CONTRACT_EMPLOYEE') ?
                    (
                        <React.Fragment>
                            <div className="grid grid-cols-3 w-full">
                                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="payrollNumber">
                                    Payroll No.:
                                </label>

                                <div className="col-span-2">
                                    <input
                                        id="payrollNumber"
                                        name="payrollNumber"
                                        value={payrollNumber}
                                        type="text"
                                        maxLength={10}
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        placeholder="5-digit number"
                                        onChange={e => {
                                            setPayrollNumberError("");
                                            if (payrollNumber.startsWith('PLAK/')) {
                                                setPayrollNumber(e.target.value);
                                            } else {
                                                setPayrollNumber('PLAK/' +
                                                    e.target.value);
                                            }
                                        }}
                                    />
                                    <span
                                        className="text-xs text-red-700"
                                        style={{ display: payrollNumberError ? "block" : "none" }}>
                                        {payrollNumberError}
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    : memberType === 'AGENT_OF_PRUDENTIAL' ?
                    (
                        <React.Fragment>
                            <div className="grid grid-cols-3 w-full">
                                <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="agentNumber">
                                    Agent No.:
                                </label>

                                <div className="col-span-2">
                                    <input
                                        id="agentNumber"
                                        name="agentNumber"
                                        value={agentNumber}
                                        type="text"
                                        maxLength={10}
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                        placeholder="5-digit number"
                                        onChange={e => {
                                            setAgentNumberError("");
                                            if (agentNumber.startsWith('PLAK/')) {
                                                setAgentNumber(e.target.value);
                                            } else {
                                                setAgentNumber('PLAK/' +
                                                    e.target.value);
                                            }
                                        }}
                                    />
                                    <span
                                        className="text-xs text-red-700"
                                        style={{ display: agentNumberError ? "block" : "none" }}>
                                        {agentNumberError}
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    ) 
                    : ""
                }

            </div>

        </>
    );
};

export default Step2;