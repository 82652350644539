import React, { FC, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks";
import AuthProps from "../../common/AuthProps";
import Select from "../../common/Select";
import { grayedOutButtonClass, enabledButtonClass, getRandomId } from "../../../shared/Utils";
import useToast from "../../../hooks/useToast";
import { Guarantor, addVirtual, editVirtual, selectGuarantors } from "../../../store/loanGuarantorSlice";
import { fetchMemberGuarantorsExcludingUser, selectMemberGuarantorError, selectMemberGuarantors } from "../../../store/memberGuarantorSlice";
import { ccyFormat } from "../../../shared/Utils";
import Amount from "../../common/Amount";
import validator from 'validator';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    loanAmount: string;
    guarantor?: Guarantor | undefined;
}

const AddEditVirtualGuarantor: FC<Props> = ({ authProps, 
    setShowAddEdit, loanAmount, guarantor }) => {

    const toast = useToast();

    const [editMode] = useState<boolean>(!!guarantor);
    const [guarantorId, setGuarantorId] = useState<string>(guarantor ? guarantor.guarantorId : "");

    const [amount, setAmount] = useState<string>(guarantor ? guarantor.amount.toString() : "");
    const [amountError, setAmountError] = useState<string>("");

    const dispatch = useAppDispatch();

    const memberGuarantors = useAppSelector(selectMemberGuarantors);
    const memberGuarantorError = useAppSelector(selectMemberGuarantorError);
    const guarantors = useAppSelector(selectGuarantors);

    const selectSelectGuarantor = [{'id':'select','name':'Select Guarantor'}];

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('pus8q405vl')) {
            dispatch(fetchMemberGuarantorsExcludingUser(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Reject if amount is > loanAmount
        if (Number(amount) > Number(loanAmount)) {
            toast('error', `Charge amount cannot exceed loan amount of ${ccyFormat(Number(loanAmount))}`);
            return;
        }

        const guarantorUpdate: Guarantor = {
            loanId: guarantor ? guarantor.loanId : getRandomId(10),
            guarantorId: guarantor ? guarantor.guarantorId : guarantorId,
            guarantor: memberGuarantors.find( ({ id }) => id === guarantorId),
            amount: Number(amount),
        }
        
        dispatch(editMode ? editVirtual(guarantorUpdate) : addVirtual(guarantorUpdate));
        toast('success', editMode ? 'Guarantor edited' : 'Guarantor added');
        setShowAddEdit(false);
        
    }

    const isInvalid = guarantorId === ""
        || !validator.isNumeric(amount)
        || Number(amount) === 0
        || Number(amount) < 1000
        || Number(amount) > 2000000;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit Guarantor ${guarantor.guarantor.firstName} ${guarantor.guarantor.middleName} ${guarantor.guarantor.surname}` 
                                            : "Add Member Guarantor"}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">
                                            
                                            {!editMode && 
                                                <React.Fragment>

                                                <span
                                                    className="text-red-500"
                                                    style={{ display: memberGuarantorError ? "block" : "none" }}>
                                                    {memberGuarantorError}
                                                </span>

                                                    <label htmlFor="guarantorId" className="sr-only">
                                                        Guarantor
                                                    </label>
                                                    <Select
                                                        id="guarantorId"
                                                        placeHolder="Select Guarantor"
                                                        className="flex-grow appearance-none rounded-none w-full relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                        selectList={
                                                            selectSelectGuarantor.map(select => {
                                                                return {id: select.id, name: select.name}
                                                            }).concat(
                                                                memberGuarantors
                                                                    .filter( ({ id }) => !guarantors?.map(a => a.guarantorId).includes(id))
                                                                    .map(guarantor => {
                                                                return { id: guarantor.id, name: `${guarantor.firstName} ${guarantor.middleName} ${guarantor.surname} ${guarantor.memberType}` }}))}
                                                        required={true}
                                                        value={guarantorId}
                                                        onChange={e => {
                                                            setGuarantorId(e.target.value);
                                                        }}
                                                        autoFocus />
                                                </React.Fragment>}

                                           <Amount 
                                                setAmount={setAmount}
                                                amount={amount}
                                                setAmountError={setAmountError}
                                                amountError={amountError}
                                                placeholderText='>= 1K and <= 2M'
                                                maxLengthValue={7} />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Add"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddEditVirtualGuarantor
