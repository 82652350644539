import React, { FC, SetStateAction } from 'react';
import { ccyFormat, grayedOutButtonClass } from '../../../shared/Utils';
import { selectLoanError } from '../../../store/loanApplicationSlice';
import { useAppSelector } from '../../common/hooks';
import { Guarantor } from '../../../store/loanGuarantorSlice';
import { Member } from '../../../store/memberSlice';

interface Props {
    amount: number;
    setCertifyApplication: React.Dispatch<SetStateAction<boolean>>;
    certifyApplication: boolean;
    setDeductionFromSalary:  React.Dispatch<SetStateAction<boolean>>;
    deductionFromSalary: boolean;
    type: string;
    category: string;
    explanation: string;
    repaymentPeriod: number;
    guarantors: Guarantor[];
    member: Member;
};

const Step6: FC<Props> = ({ amount, setCertifyApplication, certifyApplication,
    setDeductionFromSalary, deductionFromSalary, type, category, explanation,
     repaymentPeriod, guarantors, member }) => {

    const loanError = useAppSelector(selectLoanError);

    return (
        <>
            {member &&
            <React.Fragment>

                <span
                    className="text-red-500"
                    style={{ display: loanError ? "block" : "none" }}>
                    {loanError}
                </span>

                <div className="grid grid-cols-2 pt-2 pb-4 w-full">

                    <h6 className="block text-xs font-normal p-1">
                        {`Amount:`}
                    </h6>
                    <h6 className="block text-xs font-bold p-1">
                        {ccyFormat(amount)}
                    </h6>

                    <h6 className="block text-xs font-normal p-1">
                        {`Type:`}
                    </h6>
                    <h6 className="block text-xs font-bold p-1">
                        {type}
                    </h6>

                    <h6 className="block text-xs font-normal p-1">
                        {`Category:`}
                    </h6>
                    <h6 className="block text-xs font-bold p-1">
                        {category}
                    </h6>

                    {category === 'Other' &&
                        <React.Fragment>
                            <h6 className="block text-xs font-normal p-1">
                                {`Explanation:`}
                            </h6>
                            <h6 className="block text-xs font-bold p-1">
                                {explanation}
                            </h6>
                        </React.Fragment>
                    }

                    <h6 className="block text-xs font-normal p-1">
                        {`Repayment Period (Months):`}
                    </h6>
                    <h6 className="block text-xs font-bold p-1">
                        {repaymentPeriod}
                    </h6>

                    <h6 className="block text-xs font-normal p-1">
                        {`Guarantor(s):`}
                    </h6>
                    <h6 className="block text-xs font-bold p-1">
                        {guarantors.map((guarantor, index) => {
                            return (
                                <div key={index}>
                                    {`${guarantor.guarantor.firstName} ${guarantor.guarantor.middleName} ${guarantor.guarantor.surname} ${ccyFormat(guarantor.amount)}`}
                                </div>
                            );
                        })}
                    </h6>

                    <label className="inline-flex items-center col-span-2 p-1 border-2 inset">
                        <input 
                            type="checkbox" 
                            checked={certifyApplication}
                            className="w-4 h-4 rounded"
                            onChange={(e) => setCertifyApplication(e.target.checked)} />
                            <span className="ml-2 text-sm font-normal">
                                {`I ${member.firstName} ${member.middleName} ${member.surname} hereby 
                                    request this loan of Kshs ${ccyFormat(amount)} payable in
                                    ${repaymentPeriod} ${repaymentPeriod === 1 ? 'month' : 'months'}
                                    installments to be deducted from my net salary, plus interest of
                                    1% monthly on reducing balance basis.`}
                            </span>
                    </label>

                    <label className="inline-flex items-center col-span-2 p-1 border-2 inset">
                        <input 
                            type="checkbox" 
                            checked={deductionFromSalary}
                            className="w-4 h-4 rounded"
                            onChange={(e) => setDeductionFromSalary(e.target.checked)} />
                            <span className="ml-2 text-sm font-normal">
                                {`I hereby declare that the foregoing particulars are true to the best
                                    of my knowledge and agree to abide by the rules and policy of the
                                    society. I further undertake to face criminal charges and penalties
                                    in the event that I change my pay point in addition to having my
                                    co-op shares liquidated to offset the SACCO loan defaulted.`}
                            </span>
                    </label>



                </div>

                

            </React.Fragment>}
        </>
    );
};

export default Step6;