import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type BranchParams = {
    authProps: AuthProps;
    employer: String;
}

export type Branch = {
    id: string;
    employer: string;
    town: string;
    name: string;
}

interface BranchState {
    status: "loading" | "idle";
    error: string | null;
    list: Branch[];
}

interface FetchErrors {
    message: string;
}

const initialState: BranchState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchBranchesByEmployer = 
    createAsyncThunk<Branch[], BranchParams, { rejectValue: FetchErrors }>(
    "branch/fetchByEmployer",
    async (params: BranchParams, thunkApi) => {
        const response = 
            await fetch(params.authProps.config.PUBLIC_BRANCHES_URL +
                "/" + params.employer, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.defaultHeaders(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch branches",
            });
        }
        return response.json();
    }
)

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchBranchesByEmployer.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchBranchesByEmployer.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchBranchesByEmployer.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectBranchStatus = (state: RootState) => state.branches.status;
export const selectBranches = (state: RootState) => state.branches.list;
export const selectBranchError = (state: RootState) => state.branches.error;
export const { clearError } = branchSlice.actions;
export default branchSlice.reducer;