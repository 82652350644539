import React, { FC, useEffect, useState, useMemo } from 'react';
import { selectNextOfKinStatus, selectNextOfKinError,
    selectNextOfKin, fetchNextOfKinById,
    clearError, NextOfKinParams, NextOfKin,
    deleteNextOfKin} from '../../store/nextOfKinSlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { WrappedTable } from "../common/Table/WrappedTable";
import { Column } from 'react-table';
import AddEditNextOfKin from './AddEditNextOfKin';

const NextOfKinPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectNextOfKinStatus);
    const nextOfKins = useAppSelector(selectNextOfKin);
    const nextOfKinError = useAppSelector(selectNextOfKinError);

    const [nextOfKin, setNextOfKin] = useState<NextOfKin>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchNextOfKinById(authProps))
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setNextOfKin(undefined);
        }
    };

    const inLineEdit = (e: React.MouseEvent<HTMLButtonElement>, editNextOfKin: NextOfKin) => {
    	e.preventDefault();
        dispatch(clearError(null));
    	setNextOfKin(editNextOfKin);
    	setShowAddEdit(true);
  	};

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delNextOfKin: NextOfKin) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setNextOfKin(delNextOfKin);
    	const params: NextOfKinParams = {
        	authProps: authProps,
        	nextOfKin: delNextOfKin,
      	}
    	dispatch(deleteNextOfKin(params))
 	};

    const columns: Column<NextOfKin>[] = useMemo(() => [
        {
            Header: "Names",
            accessor: names => {return `${names.nextOfKin.firstName} ${names.nextOfKin?.middleName} ${names.nextOfKin.surname}`}
        },
        {
            Header: "Gender",
            accessor: gender => {return gender.nextOfKin.gender}
        },
        {
            Header: "DOB",
            accessor: dob => {return dob.nextOfKin.dateOfBirth}
        },
        {
            Header: "Marital Status",
            accessor: ms => {return ms.nextOfKin.maritalStatus}
        },
        {
            Header: "Relationship",
            accessor: "relationship"
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('dejdlq3cki') &&
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                    {authProps.auth.token.features?.map(a => a.id).includes('ko6nl6g41g') &&
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[nextOfKin]);

    const columnsSm: Column<NextOfKin>[] = useMemo(() => [
        {
            Header: "Next-of-Kin",
            accessor: "nextOfKin",
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-wrap">
                        <div className="w-full">
                            <div className="flex mb-0 list-none flex-wrap pt-1 pb-2 flex-row">
                                {`${row.original.nextOfKin.firstName} ${row.original.nextOfKin.middleName} ${row.original.nextOfKin.surname}
                                    ${row.original.nextOfKin.gender} ${row.original.nextOfKin.dateOfBirth} ${row.original.nextOfKin.maritalStatus}`}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
          Header: "",
          accessor: "edit",
          Cell: ({ row }) => {
              return (
                  <div className="items-center margin: auto">
                    {authProps.auth.token.features?.map(a => a.id).includes('dejdlq3cki') &&
                      <button
                          onClick={e => inLineEdit(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg" 
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                      </button>}
                  </div>
              );
          }

      },
      {
          Header: "",
          accessor: "delete",
          Cell: ({ row }) => {
              return (
                  <div style={{ textAlign:"center" }}>
                    {authProps.auth.token.features?.map(a => a.id).includes('ko6nl6g41g') &&
                      <button
                          onClick={e => inLineDelete(e, row.original)}>
                          <svg xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                          </svg>
                      </button>}
                  </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[nextOfKin]);

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {nextOfKinError &&
                    <span
                        className="text-red-500"
                        style={{ display: nextOfKinError ? "block" : "none" }}>
                        {nextOfKinError}
                    </span>}
                    <div>
                        {nextOfKins &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<NextOfKin>
                                        name=""
                                        columns={columns}
                                        data={nextOfKins}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('57vbfoc3gc')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<NextOfKin>
                                        name=""
                                        columns={columnsSm}
                                        data={nextOfKins}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('57vbfoc3gc')}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && <AddEditNextOfKin 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit}
                                nextOfKin={nextOfKin} />}
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default NextOfKinPage;