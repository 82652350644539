import React, { FC, useEffect, useMemo, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import Date from '../../common/Date';
import Amount from '../../common/Amount';
import RepaymentPeriod from '../../common/RepaymentPeriod';
import { grayedOutButtonClass, ccyFormat, enabledButtonClass, getRandomId } from '../../../shared/Utils';
import { Calculator, CalculatorParams, Schedule, calculateSchedule, selectCalculatorError, selectCalculatorStatus } from '../../../store/amortizationCalculatorSlice';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import validator from 'validator';
import useToast from "../../../hooks/useToast";

interface Props {
    authProps: AuthProps;
    setAmount: React.Dispatch<SetStateAction<string>>;
    amount: string;
    setAmountError: React.Dispatch<SetStateAction<string>>;
    amountError: string;
    setRepaymentPeriod: React.Dispatch<SetStateAction<number>>;
    repaymentPeriod: number;
    setRepaymentPeriodError: React.Dispatch<SetStateAction<string>>;
    repaymentPeriodError: string;
    type: string;
    setCalculatorId: React.Dispatch<SetStateAction<string>>;
    calculatorId: string;
    setFirstPaymentDate: React.Dispatch<SetStateAction<string>>;
    firstPaymentDate: string;
    setFirstPaymentDateError: React.Dispatch<SetStateAction<string>>;
    firstPaymentDateError: string;
    amortizationSchedule: Schedule[];
    setAmortizationScheduleError: React.Dispatch<SetStateAction<string>>;
    amortizationScheduleError: string;
};

const Step4: FC<Props> = ({ authProps, setAmount, amount, setAmountError, amountError, setRepaymentPeriod, 
    repaymentPeriod, setRepaymentPeriodError, repaymentPeriodError, type, setCalculatorId, calculatorId,
    setFirstPaymentDate, firstPaymentDate, setFirstPaymentDateError, firstPaymentDateError, amortizationSchedule,
    setAmortizationScheduleError, amortizationScheduleError }) => {

    const dispatch = useAppDispatch();

    const toast = useToast();

    const calculatorError = useAppSelector(selectCalculatorError);
    const status = useAppSelector(selectCalculatorStatus);

    useEffect(() => {
        // Set calculator id as new random alphanumeric if not already set
        setCalculatorId(calculatorId === "" ? getRandomId(10) : calculatorId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buttonHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        setAmortizationScheduleError("");

        const currentCalculator: Calculator = {
            id: calculatorId,
            memberId: authProps.auth.token.userId,
            amount: Number(amount),
            repaymentPeriod: repaymentPeriod,
            firstPaymentDate: firstPaymentDate,
            paymentFrequency: 'MONTHLY',
            accountId: authProps.config.ACCOUNT_ID,
        }
        
        const params: CalculatorParams = {
            authProps: authProps,
            calculator: currentCalculator,
        }
        const result = await dispatch(calculateSchedule(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', 'amortization schedule calculated successfully');
        } else {
            toast('error', 'failed to generate amortization schedule');
        }

    }

    const totalPayment: any = (items: Schedule[]) => {
        return items.map( ({ payment }) => Number(payment))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const totalInterest: any = (items: Schedule[]) => {
        return items.map( ({ interest }) => Number(interest))
            .reduce((sum, i) => sum + i, 0) || 0;
    }

    const columns: Column<Schedule>[] = useMemo(() => [
        {
            Header: "Due",
            accessor: "dueDate",
            Cell: ({ row }) => {
                return (
                    <div className="font-xs">
                        {row.original.dueDate}
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Payment
                </div>
            ),
            accessor: "payment",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-xs">
                            {ccyFormat(Number(row.original.payment))}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    <div className="font-xs">
                        {ccyFormat(totalPayment(columnProps.data))}
                    </div>
                </div>
            ),
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Interest
                </div>
            ),
            accessor: "interest",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-xs">
                            {ccyFormat(Number(row.original.interest))}
                        </div>
                    </div>
                );
            },
            Footer: columnProps => (
                <div style={{ textAlign:"right" }}>
                    <div className="font-xs">
                        {ccyFormat(totalInterest(columnProps.data))}
                    </div>
                </div>
            ),
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Principal
                </div>
            ),
            accessor: "principal",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-xs">
                            {ccyFormat(Number(row.original.principal))}
                        </div>
                    </div>
                );
            },
        },
        {
            Header: () => (
                <div style={{ textAlign:"right" }}>
                    Balance
                </div>
            ),
            accessor: "balance",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"right" }}>
                        <div className="font-xs">
                            {ccyFormat(Number(row.original.balance))}
                        </div>
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[amortizationSchedule]);

    const isInvalid =
        !validator.isNumeric(amount)
        || Number(amount) === 0
        || Number(amount) < 1000
        || Number(amount) > 2000000
        || Number(repaymentPeriod) === 0
        || (type === 'Emergency' && repaymentPeriod > 3)
        || (type === 'Development' && repaymentPeriod > 36)
        || firstPaymentDate === ""
            || !validator.isDate(firstPaymentDate)

    return (
        <div>
            <Amount
                setAmount={setAmount}
                amount={amount}
                setAmountError={setAmountError}
                amountError={amountError}
                placeholderText='>= 1K and <= 2M'
                maxLengthValue={7} />

            <RepaymentPeriod
                setRepaymentPeriod={setRepaymentPeriod}
                repaymentPeriod={repaymentPeriod}
                setRepaymentPeriodError={setRepaymentPeriodError}
                repaymentPeriodError={repaymentPeriodError}
                type={type} />

            <Date
                setDate={setFirstPaymentDate}
                date={firstPaymentDate}
                setDateError={setFirstPaymentDateError}
                dateError={firstPaymentDateError}
                label="First Payment" />
            
            <div className="p-2">
                <button
                    className={isInvalid ? grayedOutButtonClass : enabledButtonClass}
                    onClick={e => buttonHandler(e)}>
                    Calculate Amortization Schedule
                </button>
            </div>

            <div className="flex flex-col w-auto">
                {status === "calculate" ? (<div className="pl-2">Calculating...</div>) : (
                    <React.Fragment>
                        {calculatorError &&
                        <span
                            className="text-red-500"
                            style={{ display: calculatorError ? "block" : "none" }}>
                            {calculatorError}
                        </span>}
                    <div>
                        {amortizationSchedule &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<Schedule>
                                        name=""
                                        columns={columns}
                                        data={amortizationSchedule}
                                        addonHooks={[]}
                                        includeFooter
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<Schedule>
                                        name=""
                                        columns={columns}
                                        data={amortizationSchedule}
                                        addonHooks={[]}
                                        includeFooter
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <span
                        className="text-xs text-red-700"
                        style={{ display: amortizationScheduleError ? "block" : "none" }}>
                        {amortizationScheduleError}
                    </span>
                </React.Fragment>)}
            </div>

        </div>
    );
    
}

export default Step4;