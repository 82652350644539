import React, { FC, useState, useEffect, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { addMemberBankAccount, editMemberBankAccount, MemberBankAccount, 
    MemberBankAccountParams, selectMemberBankAccountError,
    clearError } from '../../../store/memberBankAccountSlice';
import { fetchBankBranchesByBank, selectBankBranches,
    BankBranchParams } from '../../../store/bankBranchSlice';
import useToast from '../../../hooks/useToast';
import { fetchBanks, selectBanks } from '../../../store/bankSlice';
import Select from '../../common/Select';
import { grayedOutButtonClass, enabledButtonClass } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    memberBankAccount?: MemberBankAccount | undefined;
}

const AddEditBankAccount: FC<Props> = ({ authProps, setShowAddEdit,
    memberBankAccount }) => {

    const toast = useToast();
    const dispatch = useAppDispatch();

    const [editMode] = useState<boolean>(!!memberBankAccount);
    const [bankId, setBankId] = useState<string>("");
    const [bankBranchId, setBankBranchId] = useState<string>(editMode ? memberBankAccount.bankBranchId : "");
    const [accountNumber, setAccountNumber] = useState<string>(editMode ? memberBankAccount.accountNumber : "");

    const memberBankAccountError = useAppSelector(selectMemberBankAccountError);

    const banks = useAppSelector(selectBanks);
    const bankBranches = useAppSelector(selectBankBranches);

    useEffect(() => {
        const controller = new AbortController();
        if (authProps.auth.token.features?.map(a => a.id).includes('9lq5qogsaj')) {
            dispatch(fetchBanks(authProps));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const updatedMemberBankAccount: MemberBankAccount = {
            memberId: authProps.auth.token.userId,
            bankBranchId: bankBranchId,
            accountNumber: accountNumber,
        }
        const params: MemberBankAccountParams = {
            authProps: authProps,
            memberBankAccount: updatedMemberBankAccount,
        }

        const result = await dispatch(editMode ? editMemberBankAccount(params) : addMemberBankAccount(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? `Bank account edited successfully` : `Bank account added successfully`);

            setShowAddEdit(false);
        }

        if (editMode && !errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid =
        bankBranchId === ""
        || accountNumber === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: a/c ${memberBankAccount.accountNumber}` : `Create a new account number`}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: memberBankAccountError ? "block" : "none" }}>
                                                {memberBankAccountError}
                                            </span>

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="bankId">
                                                Bank
                                            </label>
                                            {authProps.auth.token.features?.map(a => a.id).includes('9lq5qogsaj') &&
                                                <Select
                                                    id="bankId"
                                                    placeHolder="Select Bank"
                                                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={banks.map(bank => {
                                                        return { id: bank.id, name: bank.name }})}
                                                    required={true}
                                                    onChange={e => {
                                                        dispatch(clearError(null));
                                                        setBankId(e.target.value);
                                                        if (authProps.auth.token.features?.map(a => a.id).includes('iej44egtpu')) {
                                                            const params: BankBranchParams = {
                                                                authProps: authProps,
                                                                bankId: e.target.value,
                                                            }
                                                            dispatch(fetchBankBranchesByBank(params));
                                                        }
                                                }} />}

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="bankBranchId">
                                                Branch
                                            </label>
                                            {authProps.auth.token.features?.map(a => a.id).includes('iej44egtpu') &&
                                                <Select
                                                    disabled={bankId === ""}
                                                    id="bankBranchId"
                                                    placeHolder="Select Branch"
                                                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={bankBranches.map(bankBranch => {
                                                        return { id: bankBranch.id, name: bankBranch.name }})}
                                                    required={true}
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setBankBranchId(e.target.value);
                                                }} />}

                                            <label className="text-gray-700 text-sm font-bold" htmlFor="accountNumber">
                                                Account Number
                                            </label>
                                            <input
                                                id="accountNumber"
                                                name="accountNumber"
                                                type="text"
                                                value={accountNumber}
                                                maxLength={15}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Account number (max 15)"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setAccountNumber(e.target.value);
                                                }}
                                            />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Create"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddEditBankAccount;