import React, { FC, useEffect, SetStateAction } from 'react';
import AuthProps from '../../common/AuthProps';
import { useAppSelector } from '../../common/hooks';
import { selectMemberError } from '../../../store/memberSlice';
import { useAppDispatch } from '../../common/hooks';
import { selectBranches, fetchBranchesByEmployer,
    BranchParams } from '../../../store/branchSlice';
import { NextOfKin } from '../../../store/nextOfKinSlice';
import { Beneficiary } from '../../../store/beneficiarySlice';
import { ccyFormatZero, ccyFormat } from '../../../shared/Utils';

interface Props {
    authProps: AuthProps;
    firstName: string;
    middleName: string;
    surname: string;
    gender: string;
    dateOfBirth: string;
    maritalStatus: string;
    nationalId: string;
    passportNumber: string;
    kraPIN: string;
    employer: string;
    memberType: string;
    branchId: string;
    payrollNumber: string;
    agentNumber: string;
    areaCode: string;
    phoneNumber: string;
    email: string;
    address: string;
    nextOfKins: NextOfKin[];
    beneficiaries: Beneficiary[];
    monthlyDeduction: string;
    trueAndCorrectInfo: boolean;
    setTrueAndCorrectInfo: React.Dispatch<SetStateAction<boolean>>;
    setTrueAndCorrectError: React.Dispatch<SetStateAction<string>>;
    trueAndCorrectError: string;
}

const Step8: FC<Props> = ({ authProps, firstName, middleName, 
    surname, gender, dateOfBirth, maritalStatus, nationalId, 
    passportNumber, kraPIN, employer, memberType, branchId,
    payrollNumber, agentNumber, areaCode, phoneNumber, 
    email, address, nextOfKins, beneficiaries,
    monthlyDeduction, trueAndCorrectInfo,
    setTrueAndCorrectInfo, setTrueAndCorrectError, trueAndCorrectError }) => {

    const dispatch = useAppDispatch();
    const branches = useAppSelector(selectBranches);

    const memberError = useAppSelector(selectMemberError);

    useEffect(() => {
        const controller = new AbortController();
        if (employer !== "") {
            const branchParams: BranchParams = {
                authProps: authProps,
                employer: employer,
            }
            dispatch(fetchBranchesByEmployer(branchParams));
        }
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[employer]);

    return (

        <React.Fragment>

            <span
                className="text-red-500"
                style={{ display: memberError ? "block" : "none" }}>
                {memberError}
            </span>

            <div className="grid grid-cols-2 pt-2 pb-4 w-full">

                <h6 className="block text-xs font-normal p-1">
                    {`Names:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {firstName.concat(" ").concat(middleName)
                        .concat(" ").concat(surname)}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Gender:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {gender}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Date of Birth:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {dateOfBirth}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Marital Status:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {maritalStatus}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`National Id:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {nationalId}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Passport No.:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {passportNumber}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`KRA PIN:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {kraPIN}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Employer:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {employer}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Terms of Service:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {memberType}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Branch:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {branches &&
                        branches.length !== 0 &&
                            branchId &&
                                branches.find( ({ id }) => id === branchId).name}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Payroll No.:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {payrollNumber}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Agent No.:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {agentNumber}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Phone Number:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {phoneNumber.length === 9 ? "0".concat(phoneNumber) : phoneNumber}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Email:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {email}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Address:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {address}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Next-of-Kin:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {nextOfKins.map((nextOfKin, index) => {
                        return (
                            <div key={index}>
                                {`${nextOfKin.nextOfKin.firstName} ${nextOfKin.nextOfKin.surname} ${nextOfKin.relationship}`}
                            </div>
                        );
                    })}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Beneficiaries:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {beneficiaries.map((beneficiary, index) => {
                        return (
                            <div key={index}>
                                <React.Fragment>
                                    {`${beneficiary.beneficiary.firstName} ${beneficiary.beneficiary.surname} 
                                        ${beneficiary.relationship} % ${ccyFormatZero(beneficiary.percentShare)}`}
                                    {beneficiary.guardians &&
                                        beneficiary.guardians.map((guardian, i) => {
                                            return (
                                                <div key={i}
                                                    className="text-xs font-extralight">
                                                    {`${guardian.guardian.firstName} ${guardian.relationship}`}
                                                </div>
                                            );
                                        })}
                                </React.Fragment>
                            </div>
                        );
                    })}
                </h6>

                <h6 className="block text-xs font-normal p-1">
                    {`Monthly Deduction:`}
                </h6>
                <h6 className="block text-xs font-bold p-1">
                    {ccyFormat(Number(monthlyDeduction))}
                </h6>

                <label className="inline-flex items-center col-span-2 p-1 border-2 inset">
                    <input 
                        type="checkbox" 
                        checked={trueAndCorrectInfo}
                        className="w-4 h-4 rounded"
                        onChange={e => {
                            setTrueAndCorrectError("");
                            setTrueAndCorrectInfo(e.target.checked);
                        }} />
                        <span className="ml-2 text-sm font-normal">
                            {`The information given here is true and correct to the best of my knowledge,
                                which I have read and understood. I also agree to read, understand and
                                abide by the By-laws of the SASCO SACCO Society.`}
                        </span>
                </label>
                
            </div>
        </React.Fragment>
    );
};

export default Step8;