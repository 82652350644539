import React, { FC } from 'react';
import { Route, Routes } from "react-router-dom";
import AuthProps from './common/AuthProps';
import LandingPage from './Landing/LandingPage';
import MemberMain from './Member/MemberMain';
import ReportPage from './Report/ReportPage';
import ApplicationSteps from './Loans/Application/ApplicationSteps';
import BankingMain from './Banking/BankingMain';
import LoanMain from './Loans/LoanMain';
import GuarantorshipMain from './Guarantorship/GuarantorshipMain';
import DashboardMain from './Dashboard/DashboardMain';
import SavingsMain from './Savings/SavingsMain';
import DeductionsMain from './Deductions/DeductionsMain';

const Container: FC<AuthProps> = (authProps) => {

    const landingPageComponent = () => <LandingPage auth={authProps.auth} config={authProps.config} />;

    const loanApplication = () => (
        <ApplicationSteps auth={authProps.auth} config={authProps.config} />
    );

    return (
        <main className="md:ml-48">
            <Routes>
                <Route 
                    path="/" 
                    element={landingPageComponent()} />
                <Route
                    path="*"
                    element={landingPageComponent()} />
                <Route 
                    path="/dashboard" 
                    element={<DashboardMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/member" 
                    element={<MemberMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/banking" 
                    element={<BankingMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/loans" 
                    element={<LoanMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/savings" 
                    element={<SavingsMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/deductions" 
                    element={<DeductionsMain auth={authProps.auth} config={authProps.config} />} />
                <Route 
                    path="/guarantorship" 
                    element={<GuarantorshipMain auth={authProps.auth} config={authProps.config} />} />
                <Route
                    path="/member-loan-application"
                    element={loanApplication()} />
                <Route 
                    path="/reports" 
                    element={<ReportPage auth={authProps.auth} config={authProps.config} />} />
            </Routes>
        </main>
    );
}

export default Container;