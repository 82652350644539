import React, { FC, useEffect, useState, SetStateAction } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import Select from '../../common/Select';
import AuthProps from '../../common/AuthProps';
import { fetchBanks, selectBanks } from '../../../store/bankSlice';
import { BankBranchParams, fetchBankBranchesByBank, selectBankBranches } from '../../../store/bankBranchSlice';
import { fetchMobileMoneyProviders, selectMobileMoneyProviders } from '../../../store/mobileMoneyProviderSlice';

interface Props {
    authProps: AuthProps;
    setBankBranchId: React.Dispatch<SetStateAction<string>>;
    bankBranchId: string;
    setBankBranchIdError: React.Dispatch<SetStateAction<string>>;
    bankBranchIdError: string;
    setAccountNumber: React.Dispatch<SetStateAction<string>>;
    accountNumber: string;
    setAccountNumberError: React.Dispatch<SetStateAction<string>>;
    accountNumberError: string; 
    setMobileMoneyProvider: React.Dispatch<SetStateAction<string>>;
    mobileMoneyProvider: string;
    setMobileMoneyProviderError: React.Dispatch<SetStateAction<string>>;
    mobileMoneyProviderError: string;
    setMobileAreaCode: React.Dispatch<SetStateAction<string>>;
    mobileAreaCode: string;
    setMobileAreaCodeError: React.Dispatch<SetStateAction<string>>;
    mobileAreaCodeError: string;
    setMobilePhoneNumber: React.Dispatch<SetStateAction<string>>;
    mobilePhoneNumber: string;
    setMobilePhoneNumberError: React.Dispatch<SetStateAction<string>>;
    mobilePhoneNumberError: string;
}

const Step4: FC<Props> = ({ authProps, setBankBranchId, bankBranchId, 
    setBankBranchIdError, bankBranchIdError, setAccountNumber,
    accountNumber, setAccountNumberError, accountNumberError,
    setMobileMoneyProvider, mobileMoneyProvider, 
    setMobileMoneyProviderError, mobileMoneyProviderError,
    setMobileAreaCode, mobileAreaCode, setMobileAreaCodeError,
    mobileAreaCodeError, setMobilePhoneNumber, mobilePhoneNumber,
    setMobilePhoneNumberError, mobilePhoneNumberError }) => {

    const dispatch = useAppDispatch();

    const banks = useAppSelector(selectBanks);
    const [bankId, setBankId] = useState<string>("");

    const bankBranches = useAppSelector(selectBankBranches);

    const preSelectMobileProvider = [{'id':'select','name':'Select Provider'}];
    const mobileMoneyProviders = useAppSelector(selectMobileMoneyProviders);

    const areaCodes = [{'id':'KE', 'name':'+254'}];

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchBanks(authProps));
        dispatch(fetchMobileMoneyProviders(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <>
            <div className="flex flex-col p-1 inset border mb-1">

                <label className="text-gray-700 text-sm font-bold" htmlFor="bankId">
                    Bank
                </label>
                <Select
                    id="bankId"
                    placeHolder="Select Bank"
                    value={bankId}
                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    selectList={banks.map(bank => {
                        return { id: bank.id, name: `${bank.code} ${bank.name}` }})}
                    required={true}
                    onChange={e => {
                        setBankBranchId("");
                        setBankId(e.target.value);
                        const params: BankBranchParams = {
                            authProps: authProps,
                            bankId: e.target.value,
                        }
                        dispatch(fetchBankBranchesByBank(params));
                }} />

                <label className="text-gray-700 text-sm font-bold" htmlFor="bankBranchId">
                    Branch
                </label>
                <Select
                    disabled={bankId === ""}
                    id="bankBranchId"
                    value={bankBranchId}
                    placeHolder="Select Branch"
                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    selectList={bankBranches.map(bankBranch => {
                        return { id: bankBranch.id, name: `${bankBranch.code} ${bankBranch.name}` }})}
                    required={true}
                    onChange={(e) => {
                        setBankBranchIdError("");
                        setBankBranchId(e.target.value);
                }} />
                <span
                    className="text-xs text-red-700"
                    style={{ display: bankBranchIdError ? "block" : "none" }}>
                    {bankBranchIdError}
                </span>

                <label className="text-gray-700 text-sm font-bold" htmlFor="accountNumber">
                    Account Number
                </label>
                <input
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    value={accountNumber}
                    maxLength={15}
                    required
                    className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Account number (max 15)"
                    onChange={(e) => {
                        setAccountNumberError("");
                        setAccountNumber(e.target.value);
                    }}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: accountNumberError ? "block" : "none" }}>
                    {accountNumberError}
                </span>

                <label className="text-gray-700 text-sm font-bold" htmlFor="mobileMoneyProvider">
                    Mobile Provider
                </label>
                <Select
                    id="mobileMoneyProvider"
                    placeHolder="Select Mobile Provider"
                    className="shadow-md border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    selectList={
                        preSelectMobileProvider.map(select => {
                            return {id: select.id, name: select.name}
                        }).concat(
                            mobileMoneyProviders.map(provider => {
                            return { id: provider.mobileMoneyProvider, name: provider.mobileMoneyProvider }
                        }))}
                    required={true}
                    value={mobileMoneyProvider}
                    onChange={e => {
                        setMobileMoneyProviderError("");
                        setMobileMoneyProvider(e.target.value);
                    }}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: mobileMoneyProviderError ? "block" : "none" }}>
                    {mobileMoneyProviderError}
                </span>

                <div className="flex flex-row relative">
                    <Select
                        id="mobileAreaCode"
                        placeHolder="+254"
                        className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        selectList={areaCodes.map(areaCode => {
                            return { id: areaCode.id, name: areaCode.name }})}
                        required={true}
                        value={mobileAreaCode}
                        onChange={(e) => {
                            setMobileAreaCodeError("");
                            setMobileAreaCode(e.target.value);
                        }}
                    />
                    <span
                    className="text-xs text-red-700"
                    style={{ display: mobileMoneyProviderError ? "block" : "none" }}>
                    {mobileMoneyProviderError}
                </span>

                    <label htmlFor="mobilePhoneNumber" className="sr-only">
                        PhoneNumber
                    </label>
                    <input
                        id="mobilePhoneNumber"
                        name="mobilePhoneNumber"
                        maxLength={10}
                        type="text"
                        required
                        value={mobilePhoneNumber}
                        className="flex-grow appearance-none rounded-none relative block px-3 py-2 border w-0 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Phone Number"
                        onChange={(e) => {
                            setMobilePhoneNumberError("");
                            setMobilePhoneNumber(e.target.value)
                        }}
                    />
                </div>

            </div>
        </>
    );
};

export default Step4;