import React from "react";

export const Table: React.FC<any> = (props) => {
  const { children } = props;
  return (
    <main>
        <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="table-auto">
                            {children}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
  );
};