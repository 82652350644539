import React, { FC, useEffect, useState, useMemo } from 'react';
import { fetchMemberBankAccounts, selectMemberBankAccountError,
    selectMemberBankAccounts, selectMemberBankAccountStatus,
    clearError, 
    MemberBankAccount,
    MemberBankAccountParams,
    deleteMemberBankAccount} from '../../../store/memberBankAccountSlice';
import AuthProps from '../../common/AuthProps';
import { useAppDispatch, useAppSelector } from '../../common/hooks';
import { Column } from 'react-table';
import { WrappedTable } from '../../common/Table/WrappedTable';
import AddEditBankAccount from './AddEditBankAccount';

const BankAccountPage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const memberBankAccounts = useAppSelector(selectMemberBankAccounts);
    const status = useAppSelector(selectMemberBankAccountStatus);
    const memberbankAccountError = useAppSelector(selectMemberBankAccountError);

    const [showAddEdit, setShowAddEdit] = useState<boolean>(false);
    const [memberBankAccount, setMemberBankAccount] = useState<MemberBankAccount>();

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchMemberBankAccounts(authProps));
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setMemberBankAccount(undefined);
        }
    };

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, memberBankAccount: MemberBankAccount) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setMemberBankAccount(memberBankAccount);
    	const params: MemberBankAccountParams = {
        	authProps: authProps,
        	memberBankAccount: memberBankAccount,
      	}
    	dispatch(deleteMemberBankAccount(params))
 	};

    const columns: Column<MemberBankAccount>[] = useMemo(() => [
        {
          Header: "Bank",
          accessor: bank => { return (`${bank.bankBranch.bank.name}: ${bank.bankBranch.name}`)}
        },
        {
            Header: "Account Number",
            accessor: "accountNumber"
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('kj2kpgv9dp') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[memberBankAccounts]);

    return status === "loading" ? (<div className="pl-2">Loading...</div>) : 
        (
            <React.Fragment>
                {memberbankAccountError &&
                <span
                    className="text-red-500"
                    style={{ display: memberbankAccountError ? "block" : "none" }}>
                    {memberbankAccountError}
                </span>}
                <div>
                    {memberBankAccounts &&
                        <React.Fragment>
                            <div className="hidden md:block">
                                <WrappedTable<MemberBankAccount>
                                    name="Bank Account(s)"
                                    columns={columns}
                                    data={memberBankAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('k9r2rh32e5')}
                                /> 
                            </div>
                            <div className="md:hidden">
                                <WrappedTable<MemberBankAccount>
                                    name="Bank Account(s)"
                                    columns={columns}
                                    data={memberBankAccounts}
                                    addonHooks={[]}
                                    showAddDialog={showAddEditDialog}
                                    allowAdd={authProps.auth.token.features?.map(a => a.id).includes('k9r2rh32e5')}
                                />
                            </div>
                        </React.Fragment>}
                </div>
                <div>
                    {showAddEdit &&
                        <AddEditBankAccount 
                            authProps={authProps} 
                            setShowAddEdit={setShowAddEdit}
                            memberBankAccount={memberBankAccount}
                        />}
                </div>
            </React.Fragment>
    );

}

export default BankAccountPage;