import Config from "./Config";

interface Session {
	memberId: string;
	app: string
}

interface Credentials {
  	authToken: any;
  	areaCode: string;
  	phoneNumber: string;
  	app: string;
  	password: any;
  	date: any;
}

interface Member {
  	authToken: any;
  	firstName: string;
  	middleName: string;
	surname: string;
	gender: string;
	dateOfBirth: string;
	memberType: string;
	maritalStatus: string;
  	phoneNumber: string;
	areaCode: string;
  	role: string;
  	password: any;
  	date: any;
}

class Auth {
	public sessionToken: any;
	public setSessionToken: any;
  	public token: any;
  	private setToken: any;
  	private config: Config;

  	constructor(argSessionToken: any, argSetSessionToken: any,
		argToken: any, argSetToken: any) {
    	this.token = argToken;
    	this.setToken = argSetToken;
    	this.config = new Config();
  }

  async createSession(session: Session) {
	return fetch(this.config.PUBLIC_SESSION_URL, {
    	method: "POST",
      	mode: "cors",
      	headers: {
        	...this.config.defaultHeaders(),
      	},
      	body: JSON.stringify(session),
    	})
    	.then((response) => Promise.all([response, response.json()]))
      	.then(([response, json]) => {
        	if (!response.ok) {
          		return { success: false, error: json };
        	}
        	this.storeSessionToken(json);
        	return { success: true, data: json };
      	})
      .catch((e) => {
        return this.handleError(e);
      });
  }

  async loginMember(credentials: Credentials) {
	return fetch(this.config.LOGIN_URL, {
    	method: "POST",
      	mode: "cors",
      	headers: {
        	...this.config.defaultHeaders(),
      	},
      	body: JSON.stringify(credentials),
    	})
    	.then((response) => Promise.all([response, response.json()]))
      	.then(([response, json]) => {
        	if (!response.ok) {
          		return { success: false, error: json };
        	}
        	this.storeTokens(json);
        	return { success: true, data: json };
      	})
      .catch((e) => {
        return this.handleError(e);
      });
  	}

	async forgotPassword(member: Member) {
    	return fetch(this.config.FORGOTPASSWORD_URL, {
    		method: "POST",
      		mode: "cors",
      		headers: {
        		...this.config.defaultHeaders(),
      		},
      		body: JSON.stringify(member),
    	})
      	.then((response) => Promise.all([response, response.json()]))
      	.then(([response, json]) => {
        	if (!response.ok) {
          		return { success: false, error: json };
        	}
        return { success: true, data: json };
      	})
      	.catch((e) => {
        	return this.handleError(e);
      	});
  	};

	async validateAuthToken(member: Member) {
    	return fetch(this.config.VALIDATE_AUTHTOKEN_URL, {
    		method: "POST",
      		mode: "cors",
      		headers: {
        		...this.config.defaultHeaders(),
      		},
      		body: JSON.stringify(member),
    	})
      	.then((response) => Promise.all([response, response.json()]))
      	.then(([response, json]) => {
        	if (!response.ok) {
          		return { success: false, error: json };
        	}
        return { success: true, data: json };
      	})
      	.catch((e) => {
        	return this.handleError(e);
      	});
  	};

	  async resetPassword(member: Member) {
    	return fetch(this.config.RESETPASSWORD_URL, {
    		method: "POST",
      		mode: "cors",
      		headers: {
        		...this.config.defaultHeaders(),
      		},
      		body: JSON.stringify(member),
    	})
      	.then((response) => Promise.all([response, response.json()]))
      	.then(([response, json]) => {
        	if (!response.ok) {
          		return { success: false, error: json };
        	}
        return { success: true, data: json };
      	})
      	.catch((e) => {
        	return this.handleError(e);
      	});
  	};

  	async refreshToken() {
		return fetch(this.config.LOGIN_URL + "/refresh", {
			method: "POST",
			mode: "cors",
			headers: {
				...this.config.defaultHeaders(),
			},
			body: JSON.stringify({ refreshToken: this.token.refreshToken }),
    	})
      	.then((response) => Promise.all([response, response.json()]))
      	.then(([response, json]) => {
        	if (!response.ok) {
          		this.clearTokens();
          		return { success: false, error: json };
        	}
        	this.storeTokens(json);
        	return { success: true, data: json };
      	})
      	.catch((e) => {
        	return this.handleError(e);
      	});
  	}

	async logoutMember(refreshToken: any) {
		return fetch(this.config.LOGIN_URL, {
			method: "DELETE",
			mode: "cors",
			headers: {
				...this.config.headersWithAuthorization(),
			},
			body: JSON.stringify(refreshToken),
		})
		.then(this.handleResponseError.bind(this))
		.catch((e) => {
			this.handleError(e);
		});
  	}

  	handleResponseError(response: { ok: any; json: () => any; }) {
    	this.clearTokens();
    	if (!response.ok) {
     		const error = response.json();
      		console.log(error);
      		throw Error(error);
    	}
    	return response;
  	}

  	handleError(error: { message: any; constructor: ErrorConstructor; }) {
    	this.clearTokens();
    	const err = new Map([
      		[TypeError, "Can't connect to server."],
      		[SyntaxError, "There was a problem parsing the response."],
      		[Error, error.message],
    	]).get(error.constructor);
    	console.log(err);
    	return err;
  	}

  	storeTokens(json: { accessToken: any; }) {
    	this.setToken(json);
    	this.config.storeAccessToken(json.accessToken);
  	}

  	clearTokens() {
    	this.config.storeAccessToken("");
    	this.setToken(null);
  	}

	storeSessionToken(json: { sessionToken: any; }) {
    	this.setSessionToken(json);
    	this.config.storeSessionToken(json.sessionToken);
  	}

	  clearSessionToken() {
    	this.config.storeSessionToken("");
    	this.setSessionToken(null);
  	}
}

export default Auth;
