import React, { FC, useEffect, useState, useMemo } from 'react';
import { selectPersonalPhoneStatus, selectPersonalPhoneError,
    selectPersonalPhones, fetchPersonalPhonesById,
    clearError, PersonalPhoneParams, PersonalPhone,
    deletePersonalPhone} from '../../store/personalPhoneSlice';
import AuthProps from '../common/AuthProps';
import { useAppSelector, useAppDispatch } from "../common/hooks";
import { WrappedTable } from "../common/Table/WrappedTable";
import { Column } from 'react-table';
import AddEditPersonalPhone from './AddPersonalPhone';

const PersonalPhonePage: FC<AuthProps> = (authProps) => {

    const dispatch = useAppDispatch();

    const status = useAppSelector(selectPersonalPhoneStatus);
    const personalPhones = useAppSelector(selectPersonalPhones);
    const personalPhoneError = useAppSelector(selectPersonalPhoneError);

    const [personalPhone, setPersonalPhone] = useState<PersonalPhone>();
    const [showAddEdit, setShowAddEdit] = useState(false);

    useEffect(() => {
        const controller = new AbortController();
        dispatch(fetchPersonalPhonesById(authProps))
        return () => controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showAddEditDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<Element>) => {
        e.preventDefault();
        dispatch(clearError(null));
        setShowAddEdit(true);
        if (!showAddEdit) {
            setPersonalPhone(undefined);
        }
    };

  	const inLineDelete = (e: React.MouseEvent<HTMLButtonElement>, delPersonalPhone: PersonalPhone) => {
		e.preventDefault();
        dispatch(clearError(null));
    	setPersonalPhone(personalPhone);
    	const params: PersonalPhoneParams = {
        	authProps: authProps,
        	personalPhone: delPersonalPhone,
      	}
    	dispatch(deletePersonalPhone(params))
 	};

     const columns: Column<PersonalPhone>[] = useMemo(() => [
        {
            Header: "Cell",
            accessor: cell => { return (`${cell.areaCode} ${cell.phoneNumber}`) }
        },
        {
            Header: "",
            accessor: "delete",
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign:"center" }}>
                        {authProps.auth.token.features?.map(a => a.id).includes('gh9ejvivj5') &&
                            <button
                                onClick={e => inLineDelete(e, row.original)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </button>}
                    </div>
                );
            },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[personalPhones])

    return (
        <div className="flex flex-col w-auto">
            {status === "loading" ? (<div className="pl-2">Loading...</div>) : (
                <React.Fragment>
                    {personalPhoneError &&
                    <span
                        className="text-red-500"
                        style={{ display: personalPhoneError ? "block" : "none" }}>
                        {personalPhoneError}
                    </span>}
                    <div>
                        {personalPhones &&
                            <React.Fragment>
                                <div className="hidden md:block">
                                    <WrappedTable<PersonalPhone>
                                        name="Personal Cell Phone(s)"
                                        columns={columns}
                                        data={personalPhones}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('3ibodeqi2l')}
                                    /> 
                                </div>
                                <div className="md:hidden">
                                    <WrappedTable<PersonalPhone>
                                        name="Cell Phone(s)"
                                        columns={columns}
                                        data={personalPhones}
                                        addonHooks={[]}
                                        showAddDialog={showAddEditDialog}
                                        allowAdd={authProps.auth.token.features?.map(a => a.id).includes('3ibodeqi2l')}
                                    /> 
                                </div>
                            </React.Fragment>}
                    </div>
                    <div>
                        {showAddEdit
                            && <AddEditPersonalPhone 
                                authProps={authProps} 
                                setShowAddEdit={setShowAddEdit}
                                personalPhone={personalPhone} />}
                    </div>
                </React.Fragment>)}
        </div>
    );
};

export default PersonalPhonePage;