import React, { FC, SetStateAction } from 'react';
import Select from './Select';
import validator from 'validator';

interface Props {
    setAreaCode: React.Dispatch<SetStateAction<string>>;
    areaCode: string;
    setPhoneNumber: React.Dispatch<SetStateAction<string>>;
    phoneNumber: string;
    setPhoneNumberError: React.Dispatch<SetStateAction<string>>;
    phoneNumberError: string;
}

const PhoneNumber: FC<Props> = ({ setAreaCode, areaCode,
    setPhoneNumber, phoneNumber, setPhoneNumberError, phoneNumberError }) => {

    const areaCodes = [{'id':'+254', 'name':'+254 Kenya'}];

    return (
        <div className="grid grid-cols-3 w-full">

            <label className=" text-gray-700 p-2 text-sm font-bold" htmlFor="phoneNumber">
                Phone Number:
            </label>

            <div className="col-span-2">

                <Select
                    id="areaCode"
                    placeHolder="+254 Kenya"
                    className="flex-grow appearance-none rounded-none relative block px-1 py-2 border text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    selectList={areaCodes.map(areaCode => {
                        return { id: areaCode.id, name: areaCode.name }})}
                    required={true}
                    value={areaCode}
                    onChange={(e) => 
                        setAreaCode(e.target.value)} />
            
                <input
                    id="phoneNumber"
                    name="phoneNumber"
                    maxLength={10}
                    type="text"
                    required
                    value={phoneNumber}
                    className="flex-grow appearance-none rounded-none relative block px-2 py-2 border w-full border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Phone Number"
                    onChange={(e) => {
                        setPhoneNumberError("");
                        if (validator.isInt(e.target.value)) {
                            setPhoneNumber(e.target.value)
                        } else {
                            setPhoneNumber("");
                        }
                    }} />
                    <span
                        className="text-xs text-red-700"
                        style={{ display: phoneNumberError ? "block" : "none" }}>
                        {phoneNumberError}
                    </span>
            </div>
        </div>
    );

};

export default PhoneNumber;