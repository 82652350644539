import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';
import { Bank } from './bankSlice';

export type BankBranchParams = {
    authProps: AuthProps;
    bankId?: string;
}

export type BankBranch = {
    id: string;
    bankId: string;
    bank?: Bank;
    name: string;
    code: string;
}

interface BankBranchState {
    status: "loading" | "idle" ;
    error: any;
    list: BankBranch[];
}

interface FetchErrors {
    message: string;
}

const initialState: BankBranchState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchBankBranchesByBank = 
    createAsyncThunk<BankBranch[], BankBranchParams, { rejectValue: FetchErrors }>(
    "bankBranches/fetchByBank",
    async (params: BankBranchParams, thunkApi) => {
        const response = 
            await fetch(params.authProps.config.PUBLIC_BANK_URL +
                "/branches/" + params.bankId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.authProps.config.defaultHeaders(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch bank branches",
            });
        }
        return response.json();
    }
);

export const bankBranchSlice = createSlice({
    name: 'bankBranch',
    initialState,
    reducers: {
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {

        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchBankBranchesByBank.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchBankBranchesByBank.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchBankBranchesByBank.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectBankBranchStatus = (state: RootState) => state.bankBranches.status;
export const selectBankBranches = (state: RootState) => state.bankBranches.list;
export const selectBankBranchError = (state: RootState) => state.bankBranches.error;
export const { clearList } = bankBranchSlice.actions;
export default bankBranchSlice.reducer;