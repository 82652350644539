import React, { FC, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks";
import { addVirtualBeneficiary, editVirtualBeneficiary, 
    Beneficiary, selectBeneficiaryCount, selectBeneficiaries } from "../../../store/beneficiarySlice";
import AuthProps from "../../common/AuthProps";
import { grayedOutButtonClass, enabledButtonClass, getRandomId } from "../../../shared/Utils";
import useToast from "../../../hooks/useToast";
import { Member } from "../../../store/memberSlice";
import validator from 'validator';
import Names from "../../common/Names";
import Relationship from "../../common/Relationship";
import Select from '../../common/Select';
import { selectNextOfKin } from "../../../store/nextOfKinSlice";

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    beneficiary?: Beneficiary | undefined;
}

const AddEditVirtualBeneficiary: FC<Props> = ({ authProps, 
    setShowAddEdit, beneficiary }) => {

    const toast = useToast();

    const [editMode] = useState<boolean>(!!beneficiary);

    const [memberId, setMemberId] = useState(beneficiary ? beneficiary.memberId : getRandomId(10));
    const [beneficiaryId, setBeneficiaryId] = useState(beneficiary ? beneficiary.beneficiaryId : getRandomId(10));

    const [firstName, setFirstName] = useState<string>(beneficiary ? beneficiary.beneficiary.firstName : "");
    const [firstNameError, setFirstNameError] = useState<string>("");

    const [middleName, setMiddleName] = useState<string>(beneficiary ? beneficiary.beneficiary.middleName : "");
    const [middleNameError, setMiddleNameError] = useState<string>("");

    const [surname, setSurname] = useState<string>(beneficiary ? beneficiary.beneficiary.surname: "");
    const [surnameError, setSurnameError] = useState<string>("");

    const [gender, setGender] = useState<string>(beneficiary ? beneficiary.beneficiary.gender : "");
    const [genderError, setGenderError] = useState<string>("");

    const [dateOfBirth, setDateOfBirth] = useState<string>(beneficiary ? beneficiary.beneficiary.dateOfBirth : "");
    const [dateOfBirthError, setDateOfBirthError] = useState<string>("");

    const [maritalStatus, setMaritalStatus] = useState<string>(beneficiary ? beneficiary.beneficiary.maritalStatus : "");
    const [maritalStatusError, setMaritalStatusError] = useState<string>("");

    const [relationship, setRelationship] = useState<string>(beneficiary ? beneficiary.relationship : "SPOUSE");

    const [percentShare, setPercentShare] = useState<string>(beneficiary ? beneficiary.percentShare.toString() : "");

    const beneficiaries = useAppSelector(selectBeneficiaries);
    const nextOfKin = useAppSelector(selectNextOfKin);

    const dispatch = useAppDispatch();

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const beneficiaryMember: Member = {
            id: memberId,
            saccoId: beneficiary ? beneficiary.beneficiary.saccoId : "000000",
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            dateOfBirth: dateOfBirth,
            memberType: "BENEFICIARY",
            gender: gender,
            maritalStatus: maritalStatus,
            role: "beneficiary",
            authenticated: false,
            accountId: authProps.config.ACCOUNT_ID,
        }

        const beneficiaryUpdate: Beneficiary = {
            memberId: memberId,
            beneficiaryId: beneficiaryId,
            beneficiary: beneficiaryMember,
            relationship: relationship,
            percentShare: Number(percentShare),
            guardians: [],
        }
 
        dispatch(editMode ? editVirtualBeneficiary(beneficiaryUpdate) : addVirtualBeneficiary(beneficiaryUpdate));
  
        toast('success', editMode ? 'Beneficiary edited' : 'Beneficiary added');
        setShowAddEdit(false);
        
    }

    const isInvalid = firstName === ""
            || firstName.includes(" ")
        || (Number(middleName.length) !== 0 ? middleName.includes(" ") : false)
        || surname === ""
            || surname.includes(" ")
        || gender === ""
        || dateOfBirth === ""
            || !validator.isDate(dateOfBirth)
        || maritalStatus === ""
        || relationship === ""
        || percentShare === ""
            || !validator.isInt(percentShare)
            || Number(percentShare) === 0
            || Number(percentShare) < 0
            || Number(percentShare) > 100;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-2 px-2 pb-5 text-center sm:block sm:p-0">
                
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${beneficiary?.beneficiary.firstName} ${beneficiary?.beneficiary.surname}` 
                                            : "Create new beneficiary"}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            {nextOfKin.length !== 0 &&
                                                <Select
                                                    id="selectFromNextOfKin"
                                                    placeHolder="Select from Next-of-Kin"
                                                    required={false}
                                                    className="flex-grow appearance-none rounded-none mb-1 relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    selectList={nextOfKin
                                                        .filter( ({ memberId }) => !beneficiaries?.map(a => a.memberId).includes(memberId))
                                                        .map(kin => {
                                                        return { id: kin.memberId, 
                                                            name: `${kin.nextOfKin.firstName} ${kin.nextOfKin.surname}` }})}
                                                    onChange={e => {
                                                        const selectedKin = nextOfKin.find( ({ memberId }) => memberId === e.target.value);
                                                        setMemberId(selectedKin.memberId);
                                                        setFirstName(selectedKin.nextOfKin.firstName);
                                                        setMiddleName(selectedKin.nextOfKin.middleName);
                                                        setSurname(selectedKin.nextOfKin.surname);
                                                        setDateOfBirth(selectedKin.nextOfKin.dateOfBirth);
                                                        setGender(selectedKin.nextOfKin.gender)
                                                        setMaritalStatus(selectedKin.nextOfKin.maritalStatus);
                                                        setRelationship(selectedKin.relationship);
                                                    }} />}

                                            <Names 
                                                setFirstName={setFirstName}
                                                firstName={firstName}
                                                setFirstNameError={setFirstNameError}
                                                firstNameError={firstNameError}
                                                setMiddleName={setMiddleName}
                                                middleName={middleName}
                                                setMiddleNameError={setMiddleNameError}
                                                middleNameError={middleNameError}
                                                setSurname={setSurname}
                                                surname={surname}
                                                setSurnameError={setSurnameError}
                                                surnameError={surnameError}
                                                setDateOfBirth={setDateOfBirth}
                                                dateOfBirth={dateOfBirth}
                                                setDateOfBirthError={setDateOfBirthError}
                                                dateOfBirthError={dateOfBirthError}
                                                setGender={setGender}
                                                setGenderError={setGenderError}
                                                genderError={genderError}
                                                gender={gender}
                                                setMaritalStatus={setMaritalStatus}
                                                maritalStatus={maritalStatus}
                                                setMaritalStatusError={setMaritalStatusError}
                                                maritalStatusError={maritalStatusError} />

                                            <Relationship
                                                setRelationship={setRelationship}
                                                relationship={relationship} />

                                            <div className="flex flex-row relative">
                                                <label className="block text-gray-700 text-sm font-bold w-48 mt-3"
                                                    htmlFor="percentShare">
                                                    Percent % Shares (1 - 100)
                                                </label>
                                                <input
                                                    id="percentShare"
                                                    name="percentShare"
                                                    value={percentShare}
                                                    type="text"
                                                    maxLength={3}
                                                    required
                                                    className="appearance-none rounded-none relative block w-auto px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Percent of Shares"
                                                    onChange={e => {
                                                        if (validator.isInt(e.target.value)) {
                                                            setPercentShare(e.target.value);
                                                        } else {
                                                            setPercentShare("");
                                                        }
                                                    }}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Add"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddEditVirtualBeneficiary;
