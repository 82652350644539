import spreadLogo from "../assets/logo192.png";

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
};

export const ccyFormat = (num: number) => {
    return num >= 0 ? `${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      : `( ${Math.abs(num).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} )`;
};

export const ccyFormatZero = (num: number) => {
    return num >= 0 ? `${num.toFixed(0).replace(/\d(?=(\d{3}))/g, '$&,')}`
      : `( ${Math.abs(num).toFixed(0).replace(/\d(?=(\d{3}))/g, '$&,')} )`;
};

export function reverse(s: string){
  return s.split("").reverse().join("");
}

export const notifyUser = (message: string) => {
    const notifTitle = "Suppa Notification";
    const notifImage = spreadLogo;
    const options = {
      body: message,
      icon: notifImage,
    }
    new Notification(notifTitle, options);
}

export const getRandomId = (length: number) => {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result.toLowerCase();
}

export const grayedOutButtonClass = "bg-gray-300 text-gray-500 m-1 focus:outline-none group relative w-full flex justify-center rounded-md font-medium text-sm px-4 py-2";
export const enabledButtonClass = "hover:bg-green-200 hover:text-green-700 bg-green-500 m-1 text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 group relative w-full flex justify-center rounded-md font-medium text-sm px-4 py-2";

export const grayedOutContentClass = grayedOutButtonClass + " md:w-1/3 sm:w-full";
export const enabledContentClass = enabledButtonClass + " md:w-1/3 sm:w-full";

export const grayedOutWizardClass = "bg-gray-300 px-4 py-1 mt-1 text-sm font-medium leading-5 text-center text-white";
export const enabledWizardClass = "px-4 py-1 mt-1 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700  hover:underline focus:outline-none";