import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';
import AuthProps from '../components/common/AuthProps';

export type MemberGuarantor = {
    id: string;
    firstName: string;
    middleName?: string;
    surname: string;
    gender: string;
    memberType: string;
}

interface MemberGuarantorState {
    status: "loading" | "idle";
    error: any;
    list: MemberGuarantor[];
}

interface FetchErrors {
    message: string;
}

const initialState: MemberGuarantorState = {
    list: [],
    error: null,
    status: "idle",
}

export const fetchMemberGuarantorsExcludingUser =
    createAsyncThunk<MemberGuarantor[], AuthProps, { rejectValue: FetchErrors }>(
    "memberGuarantors/fetchExcludingUser",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBER_LOAN_GUARANTORS_URL +
                "/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member guarantors",
            });
        }
        return response.json();
    }
)

export const fetchMemberGuarantors =
    createAsyncThunk<MemberGuarantor[], AuthProps, { rejectValue: FetchErrors }>(
    "memberGuarantors/fetch",
    async (params: AuthProps, thunkApi) => {
        if (params.config.tokenExpired()) {
            await params.auth.refreshToken();
        }
        const response = 
            await fetch(params.config.MEMBER_LOAN_GUARANTORS_URL +
                "/members/" + params.auth.token.userId, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        ...params.config.headersWithAuthorization(),
                    },
                });
        if (response.status !== 200) {
            return thunkApi.rejectWithValue({
                message: "failed to fetch member guarantors",
            });
        }
        return response.json();
    }
)

export const memberGuarantorSlice = createSlice({
    name: 'memberGuarantor',
    initialState,
    reducers: {
        clearError: (state, { payload }) => {
            state.error = null;
        },
        clearList: (state, { payload }) => {
            state.list = [];
            state.status = "idle";
        },
     },
    extraReducers: (builder) => {
        // ----------------------------------------------------------------------------------
        // When we send request
        builder.addCase(fetchMemberGuarantorsExcludingUser.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        builder.addCase(fetchMemberGuarantors.pending, (state) => {
            state.list = [];
            state.status = "loading";
            state.error = null;
        });
        // ----------------------------------------------------------------------------------
        // When server responds with data
        builder.addCase(fetchMemberGuarantorsExcludingUser.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        builder.addCase(fetchMemberGuarantors.fulfilled, (state, { payload }) => {
            state.list = [];
            state.list = state.list.concat(payload);
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
        // When server responds with error
        builder.addCase(fetchMemberGuarantorsExcludingUser.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        builder.addCase(fetchMemberGuarantors.rejected, (state, { payload }) => {
            if (payload) state.error = payload.message;
            state.status = "idle";
        });
        // ----------------------------------------------------------------------------------
    },
});

export const selectMemberGuarantorStatus = (state: RootState) => state.guaranteeship.status;
export const selectMemberGuarantors = (state: RootState) => state.guaranteeship.list;
export const selectMemberGuarantorError = (state: RootState) => state.guaranteeship.error;
export const { clearError, clearList } = memberGuarantorSlice.actions;
export default memberGuarantorSlice.reducer;