import React, { FC, SetStateAction, useState } from "react";
import { useAppDispatch, useAppSelector } from "../common/hooks";
import { addNextOfKin, editNextOfKin, NextOfKin, NextOfKinParams, 
    selectNextOfKinError, clearError } from "../../store/nextOfKinSlice";
import AuthProps from "../common/AuthProps";
import Select from "../common/Select";
import { grayedOutButtonClass, enabledButtonClass } from "../../shared/Utils";
import useToast from "../../hooks/useToast";
import { Member } from "../../store/memberSlice";

interface Props {
    authProps: AuthProps;
    setShowAddEdit: React.Dispatch<SetStateAction<boolean>>;
    nextOfKin?: NextOfKin | undefined;
}

const AddEditNextOfKin: FC<Props> = ({ authProps, 
    setShowAddEdit, nextOfKin }) => {

    const toast = useToast();

    const [editMode] = useState<boolean>(!!nextOfKin);
    const [firstName, setFirstName] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.firstName : "");
    const [middleName, setMiddleName] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.middleName : "");
    const [surname, setSurname] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.surname: "");
    const [gender, setGender] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.gender : "");
    const [dateOfBirth, setDateOfBirth] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.dateOfBirth : "");
    const [maritalStatus, setMaritalStatus] = useState<string>(nextOfKin ? nextOfKin.nextOfKin.maritalStatus : "");
    const [relationship, setRelationship] = useState<string>(nextOfKin ? nextOfKin.relationship : "SPOUSE");

    const [areaCode, setAreaCode] = useState<string>(nextOfKin ? nextOfKin.areaCode : "+254");
    const [phoneNumber, setPhoneNumber] = useState<string>(nextOfKin ? nextOfKin.phoneNumber : "");
    const [nationalId, setNationalId] = useState<string>(nextOfKin ? nextOfKin.nationalId : "");
    const [address, setAddress] = useState<string>(nextOfKin ? nextOfKin.address : "");

    const areaCodes = [{'id':'KE', 'name':'+254'}];

    const relationships = [
        {'id':'SPOUSE','name':'SPOUSE'},
        {'id':'CHILD','name':'CHILD'},
        {'id':'PARENT','name':'PARENT'},
        {'id':'GRANDPARENT','name':'GRANDPARENT'},
        {'id':'SIBLING','name':'SIBLING'},
        {'id':'OTHER','name':'OTHER'},
    ];

    const dispatch = useAppDispatch();
    const nextOfKinError = useAppSelector(selectNextOfKinError);

    const cancel =  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setShowAddEdit(false);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const nextOfKinMember: Member = {
            id: nextOfKin ? nextOfKin.nextOfKinId : "",
            saccoId: nextOfKin ? nextOfKin.nextOfKin.saccoId : "000000",
            firstName: firstName,
            middleName: middleName,
            surname: surname,
            dateOfBirth: dateOfBirth,
            memberType: "NEXT_OF_KIN",
            gender: gender,
            maritalStatus: maritalStatus,
            role: "ROLE_NEXTOFKIN",
            authenticated: false,
            accountId: authProps.config.ACCOUNT_ID,
        }

        const currentNextOfKin: NextOfKin = {
            memberId: authProps.auth.token.userId,
            nextOfKinId: nextOfKin ? nextOfKin.nextOfKin.id : "",
            nextOfKin: nextOfKinMember,
            relationship: relationship,
            areaCode: areaCode,
            phoneNumber: phoneNumber,
            nationalId: nationalId,
            address: address,
        }
        const params: NextOfKinParams = {
            authProps: authProps,
            nextOfKin: currentNextOfKin,
        }
 
        const result = await dispatch(editMode ? editNextOfKin(params) : addNextOfKin(params));
        const errorCondition = !!JSON.stringify(result).includes("message");

        if (!errorCondition) {
            toast('success', editMode ? 'Member next-of-kin edited successfully' : 'Member next-of-kin created successfully');
            // clear fields
            setFirstName("");
            setMiddleName("");
            setSurname("");
            setGender("");
            setMaritalStatus("");
            setDateOfBirth("");
            setRelationship("");
        }

        if (editMode && !errorCondition) {
            setShowAddEdit(false);
        }
    }

    const isInvalid = firstName === ""
        || surname === ""
        || gender === ""
        || dateOfBirth === ""
        || maritalStatus === ""
        || relationship === ""
        || areaCode === ""
        || phoneNumber === ""
        || nationalId === ""
        || address === "";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                 {/* To trick the browser into centering the modal */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline">
                    <div className="bg-gray-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center py-4 px-10 sm:px-14 lg:px-16">
                                <div className="max-w-md w-full space-y-4">
                                    <h2 className="text-center text-2xl font-normal text-gray-900">
                                        {editMode ? `Edit: ${nextOfKin?.nextOfKin.firstName} ${nextOfKin?.nextOfKin.surname}` 
                                            : "Create new next-of-kin"}
                                    </h2>

                                    <form className="mt-2 space-y-2" onSubmit={e => handleSubmit(e)}>
                                        <div className="rounded-md shadow-sm -space-y-px">

                                            <span
                                                className="text-red-500"
                                                style={{ display: nextOfKinError ? "block" : "none" }}>
                                                {nextOfKinError}
                                            </span>

                                            <label htmlFor="firstName" className="sr-only">
                                                First Name
                                            </label>
                                            <input
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                value={firstName}
                                                maxLength={20}
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="First Name"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setFirstName(e.target.value)
                                                }}
                                                autoFocus
                                            />

                                            <label htmlFor="firstName" className="sr-only">
                                                Middle Name (optional)
                                            </label>
                                            <input
                                                id="middleName"
                                                name="middleName"
                                                type="text"
                                                value={middleName}
                                                maxLength={20}
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Middle Name (optional)"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setMiddleName(e.target.value)
                                                }}
                                            />

                                            <label htmlFor="surname" className="sr-only">
                                                Surname
                                            </label>
                                            <input
                                                id="surname"
                                                name="surname"
                                                type="text"
                                                value={surname}
                                                maxLength={20}
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Surname"
                                                onChange={(e) => {
                                                    dispatch(clearError(null));
                                                    setSurname(e.target.value)
                                                }}
                                            />

                                            <div className="flex flex-row relative">
                                                <label className="block text-gray-700 text-sm font-bold w-36 mt-3"
                                                    htmlFor="dateOfBirth">
                                                    Date of Birth
                                                </label>
                                                <input
                                                    id="dateOfBirth"
                                                    name="dateOfBirth"
                                                    type="date"
                                                    value={dateOfBirth}
                                                    maxLength={10}
                                                    required
                                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Date of Birth dd-mm-yyyy"
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setDateOfBirth(e.target.value)
                                                    }}
                                                />
                                            </div>

                                            <fieldset 
                                                id="gender"
                                                className="flex items-center justify-center p-2 border">
                                                <div className="inline-flex text-sm font-normal">

                                                    <div className="mx-4">
                                                        <label
                                                            htmlFor="female">
                                                            Female
                                                        </label>
                                                        <input
                                                            className="mx-3" 
                                                            type="radio"
                                                            id="female"
                                                            value="FEMALE"
                                                            name="gender"
                                                            checked={gender === 'FEMALE'}
                                                            onChange={(e) => {
                                                                dispatch(clearError(null));
                                                                setGender('FEMALE')
                                                            }} />
                                                    </div>
                                                    
                                                    <div className="mx-6">
                                                        <label
                                                            htmlFor="male">
                                                            Male
                                                        </label>
                                                        <input 
                                                            className="mx-3" 
                                                            type="radio"
                                                            id="MALE" 
                                                            value="MALE"
                                                            name="gender"
                                                            checked={gender === 'MALE'}
                                                            onChange={(e) => {
                                                                dispatch(clearError(null));
                                                                setGender('MALE')
                                                            }}/>
                                                    </div>
                                                    
                                                </div>
                                            </fieldset>

                                            <fieldset 
                                                id="maritalStatus"
                                                className="flex items-center justify-center p-2 border">
                                                <div className="inline-flex text-sm font-normal">

                                                    <div className="mx-3">

                                                        <label
                                                            htmlFor="single">
                                                            Single
                                                        </label>
                                                        <input 
                                                            className="mx-1"
                                                            type="radio"
                                                            id="single"
                                                            value="SINGLE"
                                                            name="maritalStatus"
                                                            checked={maritalStatus === 'SINGLE'}
                                                            onChange={(e) => {
                                                                dispatch(clearError(null));
                                                                setMaritalStatus('SINGLE')
                                                            }} />
                                                    </div>

                                                    <div className="mx-4">
                                                        
                                                        <label
                                                            htmlFor="married">
                                                            Married
                                                        </label>
                                                        <input 
                                                            className="mx-1" 
                                                            type="radio"
                                                            id="married" 
                                                            value="MARRIED"
                                                            name="maritalStatus"
                                                            checked={maritalStatus === 'MARRIED'}
                                                            onChange={(e) => {
                                                                dispatch(clearError(null));
                                                                setMaritalStatus('MARRIED')
                                                            }}/>
                                                    </div>

                                                    <div className="mx-4">

                                                        <label
                                                            htmlFor="divorced">
                                                            Divorced
                                                        </label>
                                                        <input 
                                                            className="mx-1" 
                                                            type="radio"
                                                            id="divorced" 
                                                            value="DIVORCED"
                                                            name="maritalStatus"
                                                            checked={maritalStatus === 'DIVORCED'}
                                                            onChange={(e) => {
                                                                dispatch(clearError(null));
                                                                setMaritalStatus('DIVORCED')
                                                            }}/>
                                                    </div>
                                                    
                                                </div>
                                            </fieldset>

                                            <div className="flex flex-row relative">
                                                <label className="block text-gray-700 text-sm font-bold w-32 mt-3"
                                                    htmlFor="relationship">
                                                    Relationship
                                                </label>
                                                <Select
                                                    id="relationship"
                                                    value={relationship}
                                                    placeHolder="Select relationship"
                                                    className="flex-grow appearance-none rounded-none w-full relative px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    selectList={relationships.map(relationship => {
                                                        return { id: relationship.id, name: relationship.name }})}
                                                    required={true}
                                                    onChange={(e) => {
                                                        dispatch(clearError(null));
                                                        setRelationship(e.target.value)
                                                    }} />
                                            </div>

                                            <div className="flex flex-row relative">
                                                <Select
                                                    id="areaCode"
                                                    placeHolder="+254"
                                                    className="flex-grow appearance-none rounded-none border relative block py-2 px-3 w-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    selectList={areaCodes.map(areaCode => {
                                                        return { id: areaCode.id, name: areaCode.name }})}
                                                    required={true}
                                                    onChange={(e) => 
                                                        setAreaCode(e.target.value)} />

                                                <label htmlFor="phoneNumber" className="sr-only">
                                                    PhoneNumber
                                                </label>
                                                <input
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    maxLength={10}
                                                    type="text"
                                                    required
                                                    className="flex-grow appearance-none rounded-none relative block px-3 py-2 border w-0 border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                    placeholder="Phone Number"
                                                    onChange={(e) => {
                                                        setPhoneNumber(e.target.value)
                                                    }} />
                                            </div>

                                            <label htmlFor="nationalId" className="sr-only">
                                                National Id
                                            </label>
                                            <input
                                                id="nationalId"
                                                name="nationalId"
                                                type="text"
                                                maxLength={8}
                                                required
                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="National Id"
                                                onChange={(e) => setNationalId(e.target.value)}
                                            />

                                            <label htmlFor="address" className="sr-only">
                                                Address
                                            </label>
                                            <textarea
                                                id="address"
                                                name="address"
                                                maxLength={100}
                                                required
                                                className="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                                placeholder="Mailing Address"
                                                onChange={(e) => {
                                                    setAddress(e.target.value)
                                            }} />
                                            
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>
                                            <button
                                                type="submit"
                                                disabled={isInvalid}
                                                className={isInvalid ? grayedOutButtonClass : enabledButtonClass}>
                                                <span className="flex items-center">
                                                    {editMode ? "Edit" : "Create"}
                                                </span>
                                            </button>
                                            </div>
                                            <div className="text-sm">
                                                <button
                                                    type="submit"
                                                    onClick={cancel}
                                                    className="transition-colors hover:text-gray-900 font-medium duration-200">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default AddEditNextOfKin
