import React, { FC, SetStateAction } from 'react';
import validator from 'validator';

interface Props {
    setAmount: React.Dispatch<SetStateAction<string>>;
    amount: string;
    setAmountError: React.Dispatch<SetStateAction<string>>;
    amountError: string;
    placeholderText: string;
    maxLengthValue: number;
}

const Amount: FC<Props> = ({ setAmount, amount, setAmountError, amountError,
    placeholderText, maxLengthValue }) => {

    return (

        <div className="grid grid-cols-3 w-full">
            <label className="text-gray-700 p-2 text-sm font-bold w-full" htmlFor="amount">
                Amount:
            </label>
            
            <div className="col-span-2">
                <input
                    id="amount"
                    name="amount"
                    type="text"
                    value={amount}
                    maxLength={maxLengthValue}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-xs focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder={placeholderText}
                    onChange={e => {
                        setAmountError("");
                        if (validator.isNumeric(e.target.value)) {
                            setAmount(e.target.value);
                        } else {
                            setAmount("");
                        }
                    }}
                />
                <span
                    className="text-xs text-red-700"
                    style={{ display: amountError ? "block" : "none" }}>
                    {amountError}
                </span>
            </div>
        </div>
    );

};

export default Amount;